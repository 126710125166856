import { Checkbox, Form, Input, Tooltip } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { CopyText, Modal } from "redcircle-ui";
import { showSuccess } from "src/actions/app";
import { PromoCodeTypeNone } from "src/constants/campaigns";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { generateDefaultCTAWithCampaign, getPromoCode } from "src/lib/campaigns";
import { ICampaign } from "src/reducers/campaigns/types";
import { IShow } from "src/reducers/shows";
import ScriptDetails from "./script_details";

interface IProps {
  campaignItemUUID: string;
  campaign: ICampaign;
  show: IShow;
  onSubmit: (feedCTA: string) => void;
  onClose: () => void;
}

export default function ReviewShowNotesPage({
  campaignItemUUID,
  campaign,
  show,
  onSubmit,
  onClose,
}: IProps) {
  const dispatch = useDispatchTS();

  const [form] = Form.useForm();
  const feedCTA = Form.useWatch("feedCTA", form);
  const campaignItem = useSelectorTS(
    (state) => state.campaignItems.campaignItems[campaignItemUUID]
  );

  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const { scriptsByUUID } = useSelectorTS((state) => state.scripts);
  const script = scriptsByUUID[campaignItem?.scriptUUID ?? ""] ?? {};

  const promoCode = getPromoCode({ campaignItem, show, script }) ?? "";
  const promoCodeString = script?.promoCodeType === PromoCodeTypeNone ? "" : promoCode;

  const defaultCTA = generateDefaultCTAWithCampaign({
    campaignItem,
    promoCode: promoCodeString,
    brandName: campaign?.brand?.name ?? campaign?.brandName,
    website: campaign?.brand?.domain ?? campaign?.website,
  });

  useEffect(() => {
    if (form.getFieldValue("feedCTA") !== defaultCTA) {
      form.setFieldsValue({ feedCTA: defaultCTA });
    }
  }, [defaultCTA]);

  const handleSubmit = async () => {
    setSubmitLoading(true);
    const feedCTA = form.getFieldValue("feedCTA");
    await onSubmit(feedCTA);
    setSubmitLoading(false);
  };

  return (
    <>
      <Form form={form}>
        <div className="p-xxl p-bs">
          {!show.isRemote && (
            <>
              <ScriptDetails campaignItem={campaignItem} show={show} />
              <p className={classNames("redcircle-form-label", !show.isRemote && "m-tm")}>
                <strong>Your Show Notes*</strong>
              </p>
              <Form.Item
                name="feedCTA"
                rules={[{ required: true, message: "Please enter a message" }]}>
                <Input.TextArea
                  placeholder={defaultCTA}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  size="large"
                />
              </Form.Item>

              <Checkbox checked={confirmed} onChange={(e) => setConfirmed(e.target.checked)}>
                <strong>
                  I am confirming that the show notes follow the instructions in the script
                </strong>
              </Checkbox>
            </>
          )}

          {show.isRemote && (
            <CopyText
              text={defaultCTA}
              afterCopy={() => dispatch(showSuccess("Copied to clipboard!"))}
            />
          )}
        </div>

        <Modal.Footer>
          <Modal.CloseButton onClick={onClose} />
          <Tooltip
            title={
              confirmed || show.isRemote
                ? ""
                : "Please confirm that the show notes follow the script"
            }>
            <div>
              <Modal.SubmitButton
                isLoading={isSubmitLoading}
                disabled={!show.isRemote && (!feedCTA || !confirmed)}
                onClick={handleSubmit}>
                Continue
              </Modal.SubmitButton>
            </div>
          </Tooltip>
        </Modal.Footer>
      </Form>
    </>
  );
}
