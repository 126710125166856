import get from "lodash/get";
import { Tier, User, UserAttributes, UserRole } from "redcircle-types";
import { store } from "src";
import UserRoles from "src/constants/roles";
import { AppReduxState } from "src/reducers/app";
import { showState } from "src/reducers/shows/showReducer";
import { isShowRemote } from "./show";
import UserStore from "./user_store";

export function userHasRole(
  user: User,
  role: UserRole | UserRole[] /* role or array of possible roles */
) {
  if (!user) return false;

  // check role against user roles
  const userRoles = get(user, "roles", {});
  if (userRoles) {
    // role: array & userRoles: obj
    if (Array.isArray(role)) {
      let hasMatch = false;
      role.forEach((r) => {
        if (r in userRoles) hasMatch = true;
      });
      if (hasMatch) return true;
    } else {
      // role: string & userRoles: obj
      if (role in userRoles) return true;
    }
  }

  // otherwise, check user.role (old field, should not be used)
  const oldUserRole = get(user, "role");
  if (oldUserRole) {
    if (Array.isArray(role)) return role.includes(oldUserRole);
    return oldUserRole === role;
  }

  return false;
}

export function userRoleExists(user: User) {
  const userRole = get(user, "role", null);
  const userRoles = get(user, "roles", null);
  return Boolean(userRole || userRoles);
}

/**
 *  Helper function that helps check if the user has a specific user attribute
 * @param {keyof UserAttributes} attribute - Attribute to check
 * @returns {boolean}
 */
export const userHasAttribute = (attribute: keyof UserAttributes) => {
  const userAttributes = store.getState()?.user?.user?.userAttributes ?? {};

  return userAttributes[attribute] === "true";
};

/**
 * Check if the user is wait-listed (i.e. FE restricted to /waitlist route)
 *
 */
export const isUserWaitListRestricted = ({
  user,
  tier,
  app,
  showState,
}: {
  user?: User;
  tier?: Tier;
  app: AppReduxState;
  showState: showState;
}) => {
  /**
   * Default to false if required entities are not available
   */
  if (!user || !showState?.initialFetched) return false;

  // Override via BE flag
  if (user?.openRAPWaitlistInfo?.promoted) return false;

  // Temp flag override from going through signup flow a second time via wait list route/modal link
  if (app.bypassWaitListRestriction) return false;

  /**
   * No Shows currently being wait listed
   */
  const waitListedShowUUIDs = user?.openRAPWaitlistInfo?.waitlistedShowUUIDs;
  if (waitListedShowUUIDs === null || Object.keys(waitListedShowUUIDs ?? {}).length === 0) {
    return false;
  }

  /**
   * User has non-remote shows in account
   */
  const hasNonRemoteShows = Object.values(showState.shows).some((show) => !isShowRemote(show));
  if (hasNonRemoteShows) {
    return false;
  }

  const allShowsAreWaitListed = Object.entries(
    user?.openRAPWaitlistInfo?.waitlistedShowUUIDs ?? {}
  ).every(([_, flag]) => flag);

  /**
   * Normal Logic
   * - User only has remote shows and all current remote shows are wait listed
   * - User must be core, if you are paid then no wait list app restriction for you!!
   */
  return allShowsAreWaitListed && tier?.level === "core";
};

export const isInternalRCUser = (user?: User) => {
  return UserStore.isAdminLogin() || (user && userHasRole(user, UserRoles.Admin));
};
