import { Row, Tabs } from "antd";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "src/components/lib/breadcrumbs";
import RCButton from "src/components/lib/button";
import ExpandableText from "src/components/lib/expandable_text";
import Loading from "src/components/lib/loading";
import Page from "src/components/lib/page";
import ScrollToTop from "src/components/lib/route_scroll_to_top";
import ShowPageWrapper from "src/components/pages/shows/show_page_wrapper";
import UserRoles from "src/constants/roles";
import { itunesCategoriesMap } from "src/data/itunes_categories";
import { useSelectorTS } from "src/hooks/redux-ts";
import { usePublicShow } from "src/hooks/shows";
import { getMegapodURL } from "src/lib/config";
import { userHasRole } from "src/lib/user";
import { IEpisode } from "src/reducers/episodes";
import { IShow } from "src/reducers/shows";
import AddToCampaignModal from "../add_to_campaign_modal";
import AdvertiserBrowseShowAbout from "./advertiser_browse_show_about";
import AdvertiserBrowseShowEpisodes from "./advertiser_browse_show_episodes";
import ListenerAge from "./listener_age";
import ListenerGender from "./listener_gender";

export default function AdvertiserBrowseShowPage() {
  const { showUUID } = useParams<{ showUUID: string }>();
  const { user } = useSelectorTS((state) => state.user);
  const { show, isLoading } = usePublicShow(showUUID);
  const history = useHistory();
  const prevPath = history.location.state?.prevPath;
  const episodes = (show as IShow & { episodes: IEpisode[] })?.episodes;
  const isAdvertiser = userHasRole(user, [UserRoles.Advertiser, UserRoles.Admin]);
  const [showAddToCampaignModal, setAddToCampaignModal] = useState(false);

  // if show has no advertisement settings, redirect out
  if (show && !show.advertisementSettings) history.push("/browse");

  return (
    <ShowPageWrapper className="browse-show-page" show={show} isLoading={isLoading}>
      <ScrollToTop />
      {!isAdvertiser && isLoading && <Loading />}
      {isAdvertiser && (
        <Breadcrumbs
          crumbs={[
            { path: prevPath || "/browse", name: "Search Podcasts" },
            { path: `/browse/${showUUID}`, name: show?.title, active: true },
          ]}
        />
      )}

      {show && (
        <Row>
          <ShowPageWrapper.Sidebar>
            {isAdvertiser && (
              <RCButton
                className="width-100 m-ts text-wrap"
                type="primary"
                size="large"
                flex
                onClick={() => setAddToCampaignModal(true)}>
                Add To Campaign
              </RCButton>
            )}

            <div className="d-none d-block-md m-tm">
              <ListenerGender show={show} style={{ overflow: "hidden" }} />
              <ListenerAge show={show} style={{ overflow: "hidden" }} />
            </div>
          </ShowPageWrapper.Sidebar>

          <ShowPageWrapper.Body>
            <ShowPageWrapper.Header>
              <div className="flex-column-container m-rxs">
                <Page.Title className="line-clamp-2">{show.title}</Page.Title>
                <small>
                  <strong>
                    Categories:{" "}
                    {show.categoryUUIDs.map((uuid) => itunesCategoriesMap[uuid].name).join(", ")}
                  </strong>
                </small>
              </div>
              {!show.isRemote && (
                <a
                  href={`${getMegapodURL()}shows/${show.uuid}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <RCButton type="secondary" size="small">
                    Public Page
                  </RCButton>
                </a>
              )}
            </ShowPageWrapper.Header>

            <Page.Section>
              <ExpandableText dangerousText={show.description} heightToWidth={4} />
              <Tabs
                className="m-tm"
                defaultActiveKey="about"
                items={[
                  {
                    label: "About",
                    key: "about",
                    children: <AdvertiserBrowseShowAbout show={show} />,
                  },
                  {
                    label: "Episodes",
                    key: "episodes",
                    children: (
                      <AdvertiserBrowseShowEpisodes episodes={episodes} isLoading={isLoading} />
                    ),
                  },
                ]}
              />
            </Page.Section>

            <div className="d-block d-none-md">
              <ListenerGender show={show} style={{ overflow: "hidden" }} />
              <ListenerAge show={show} style={{ overflow: "hidden" }} />
            </div>
          </ShowPageWrapper.Body>
        </Row>
      )}

      <AddToCampaignModal
        visible={showAddToCampaignModal}
        onClose={() => setAddToCampaignModal(false)}
        show={show}
      />
    </ShowPageWrapper>
  );
}
