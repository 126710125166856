import { Tooltip } from "antd";
import React from "react";
import FormDropdown from "src/components/lib/form_dropdown";
import { svgIcon as Icon } from "src/components/lib/icon";
import {
  SummaryPageInfoSecondTier,
  SummaryPageSectionWrapper,
} from "src/components/lib/summary-page-sections/summary-page-sections";
import { getAverageCPM, getImpressionsFromBudget } from "src/lib/campaigns";
import { formatMoney } from "src/lib/format-money";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { PublicShow } from "src/reducers/public_show";
import { IShow } from "src/reducers/shows";
import { useSelectorTS } from "src/hooks/redux-ts";
import RCTag from "redcircle-ui/components/Tag/Tag";
import { COLORS } from "redcircle-ui";
import { calculateCampaignItemCuts } from "src/lib/promotions";

export default function ShowCampaignPayment({
  campaign,
  campaignItem,
  show,
}: {
  campaign: ICampaign;
  campaignItem?: ICampaignItem;
  show: IShow | PublicShow;
}) {
  if (!campaign || !campaignItem) return null;

  const user = useSelectorTS((state) => state.user.user);
  const isRateRenegotiated = Boolean(campaignItem?.offerRates?.enabled);

  const originalCPM = getAverageCPM({ show, campaign, campaignItem, when: "original" });
  const currentCPM = getAverageCPM({ show, campaign, campaignItem, when: "final" });
  const impressions = Math.round(
    getImpressionsFromBudget({
      cpm: currentCPM,
      budget: campaignItem.totalBudget,
    })
  );

  const {
    creatorTotalInCents,
    newRedCircleCutInPercent,
    originalRedCircleCutInPercent,
    promotionsMaxDeductInCents,
    isPromotionActive: isPromotionApplicable,
  } = calculateCampaignItemCuts({
    campaignItem,
  });

  const promotionTooltip = `Up to ${formatMoney(promotionsMaxDeductInCents)} deducted`;

  const redCircleCommissionText = isPromotionApplicable ? (
    <div className="flex-row-container align-center">
      {`${newRedCircleCutInPercent}%`}{" "}
      <span className="m-lxxxs" style={{ textDecoration: "line-through" }}>{`${
        originalRedCircleCutInPercent
      }%`}</span>
    </div>
  ) : (
    `${originalRedCircleCutInPercent}%`
  );

  return (
    <SummaryPageSectionWrapper title={"Payment"}>
      <div>
        <FormDropdown
          label={
            <div className="width-100 flex-row-container justify-space-between">
              <div>Total Payment:</div>
              <div className="campaign-total-payment" style={{ fontSize: 15 }}>
                {formatMoney(creatorTotalInCents)}
                {isRateRenegotiated && (
                  <Tooltip title="This amount has been updated by the brand">
                    <Icon
                      name="money-tag"
                      style={{
                        bottom: ".125em",
                        position: "relative",
                        fill: "#577d9e",
                        height: "16px",
                        width: "16px",
                        marginLeft: "5px",
                      }}
                      data-tip="This amount has been updated by the brand"
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          }>
          <div style={{ marginRight: 34 }}>
            <SummaryPageInfoSecondTier
              title={"Avg. CPM Rate"}
              text={
                <>
                  {formatMoney(currentCPM)}
                  {isRateRenegotiated && (
                    <span className="croosed-out-cpm">{formatMoney(originalCPM)}</span>
                  )}
                </>
              }
            />
            <SummaryPageInfoSecondTier
              title={"Total Downloads Purchased"}
              text={impressions.toLocaleString()}
            />
            <SummaryPageInfoSecondTier
              title={
                <div className="flex-row-container align-center">
                  RedCircle Commission{" "}
                  {isPromotionApplicable && (
                    <Tooltip title={promotionTooltip}>
                      <span>
                        <RCTag className="m-lxxs bold" color={COLORS.PRIMARY_COLOR}>
                          Promotion
                        </RCTag>
                      </span>
                    </Tooltip>
                  )}
                </div>
              }
              text={redCircleCommissionText}
            />
          </div>
        </FormDropdown>
        <div style={{ fontSize: 13 }}>
          Your net earnings are based on the CPM, show size, and RedCircle's commission. The payment
          schedule will be based on the advertiser's invoicing requirements, which may vary. Payout
          occurs once the advertiser completes payment to RedCircle.
        </div>
      </div>
    </SummaryPageSectionWrapper>
  );
}
