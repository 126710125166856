import {
  CampaignDetailsSummaryPageInfo,
  SummaryPageSectionWrapper,
} from "src/components/lib/summary-page-sections/summary-page-sections";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { IShow } from "src/reducers/shows";
import ShowCampaignAdDetails from "./show_campaign_ad_details";
import ShowCampaignPayment from "./show_campaign_payment";
import ShowCampaignProductSamples from "./show_campaign_product_samples";
import ShowCampaignTimeline from "./show_campaign_timeline";

interface IProps {
  campaign: ICampaign;
  campaignItem: ICampaignItem;
  show: IShow;
}

export default function ShowCampaignPageDetails({ campaign, campaignItem, show }: IProps) {
  return (
    <div>
      <ShowCampaignTimeline campaign={campaign} campaignItem={campaignItem} show={show} />
      <SummaryPageSectionWrapper title={"The Brand"}>
        <CampaignDetailsSummaryPageInfo campaign={campaign} />
      </SummaryPageSectionWrapper>
      <ShowCampaignProductSamples campaign={campaign} />
      <ShowCampaignAdDetails campaign={campaign} campaignItem={campaignItem} />
      <ShowCampaignPayment campaign={campaign} campaignItem={campaignItem} show={show} />
    </div>
  );
}
