import { Form, InputNumber, Popover, Tooltip } from "antd";
import { ForwardedRef, forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AiFillDollarCircle, AiFillEdit, AiFillInfoCircle } from "react-icons/ai";
import { formatMoney } from "redcircle-lib";
import { Button, COLORS, Divider, Modal, Tag } from "redcircle-ui";
import { showError, showSuccess } from "src/actions/app";
import { updateCampaignItems } from "src/action_managers/campaigns";
import { permissionTypes } from "src/constants/permission_roles";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { getAverageCPM, getBudget, getImpressionsFromBudget } from "src/lib/campaigns";
import { canAdvertiserAccess } from "src/lib/permissions";
import { ICampaignItem } from "src/reducers/campaign_items";
import { IShow } from "src/reducers/shows";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";

interface IProps {
  campaignItem?: ICampaignItem;
  show?: IShow;
}

export default function SchedulerNegotiateRateCell({ campaignItem, show }: IProps) {
  const [showPopover, setShowPopover] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // focus on input
  useEffect(() => {
    if (showPopover) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [showPopover]);

  const dispatch = useDispatchTS();
  const { campaign, budgets, timeline, setBudgets } = useContext(CampaignSchedulerContext);
  const { user } = useSelectorTS((state) => state.user);
  const canUserNegotiate =
    user?.userAttributes?.canNegotiate === "true" &&
    canAdvertiserAccess(permissionTypes.negotiateRates, campaign);

  const isNegotiatedRateAvailable = !!campaignItem?.offerRates?.enabled;
  const negotiatedRate = getAverageCPM({ campaign, campaignItem, show, when: "final" });
  const originalRate = getAverageCPM({ campaign, campaignItem, show, when: "original" });
  const finalRate = isNegotiatedRateAvailable ? negotiatedRate : originalRate;

  const handleSubmit = async (value: number) => {
    if (campaignItem && campaign && show) {
      const startDate = timeline[0].unix();

      const res = await dispatch(
        updateCampaignItems({
          campaign,
          items: {
            [campaignItem.uuid]: {
              ...(startDate && { startAt: startDate }),
              offerRates: {
                enabled: true,
                originalPreRollCPM: show?.advertisementSettings?.hostReadPreRollCPM,
                originalMidRollCPM: show?.advertisementSettings?.hostReadMidRollCPM,
                originalPostRollCPM: show?.advertisementSettings?.hostReadPostRollCPM,
                finalAdjustedPreRollCPM: value,
                finalAdjustedMidRollCPM: value,
                finalAdjustedPostRollCPM: value,
              },
            },
          },
        })
      );
      if (res.status === 200) {
        dispatch(
          showSuccess(`You've updated the rate on ${show.title} to ${formatMoney(value / 100)}`)
        );
        const newCampaignItem = res.json?.[0] as ICampaignItem;
        const budget = budgets[campaignItem.uuid];
        const cpm = getAverageCPM({ campaign, campaignItem: newCampaignItem, show, when: "final" });
        const currentImpressions = getImpressionsFromBudget({ cpm, budget });
        const newBudget = getBudget({ cpm: value, impressions: currentImpressions });
        setBudgets({ ...budgets, [campaignItem.uuid]: newBudget });
      } else {
        const message = (res.json as any)?.validationErrors?.[0].errorMessage;
        dispatch(showError(`There was an error updating the rate${message ? `: ${message}` : ""}`));
      }
    }
  };

  const handleRemove = async () => {
    if (campaignItem && campaign && show) {
      const startDate = timeline[0].unix();

      const res = await dispatch(
        updateCampaignItems({
          campaign,
          items: {
            [campaignItem.uuid]: {
              ...(startDate && { startAt: startDate }),
              offerRates: { enabled: false },
            },
          },
        })
      );
      if (res.status === 200) {
        dispatch(
          showSuccess(
            `The rate on ${show.title} has been reset to ${formatMoney(originalRate / 100)}.`
          )
        );
        const newCampaignItem = res.json?.[0] as ICampaignItem;
        const budget = budgets[campaignItem.uuid];
        const originalCPM = getAverageCPM({
          campaign,
          campaignItem: newCampaignItem,
          show,
          when: "original",
        });
        const currentImpressions = getImpressionsFromBudget({ cpm: originalCPM, budget });
        const newBudget = getBudget({ cpm: originalCPM, impressions: currentImpressions });
        setBudgets({ ...budgets, [campaignItem.uuid]: newBudget });
      } else {
        dispatch(showSuccess("There was an error removing the rate."));
      }
    }
  };

  if (!canUserNegotiate) {
    return <>{formatMoney(finalRate / 100)}</>;
  }
  return (
    <Popover
      open={showPopover}
      content={
        <PopoverContent
          ref={inputRef}
          open={showPopover}
          isNegotiatedRateAvailable={isNegotiatedRateAvailable}
          originalRate={originalRate}
          negotiatedRate={negotiatedRate}
          onClose={() => setShowPopover(false)}
          onSubmit={handleSubmit}
          onRemove={handleRemove}
        />
      }
      trigger="click"
      onOpenChange={(open) => setShowPopover(open)}
      overlayInnerStyle={{ padding: 0 }}>
      <Button
        type="secondary"
        size="small"
        style={
          isNegotiatedRateAvailable
            ? { background: COLORS.GREEN_DARK, color: "white", border: `1px solid transparent` }
            : {}
        }>
        {formatMoney(finalRate / 100)}
        {isNegotiatedRateAvailable ? (
          <AiFillDollarCircle className="m-lxxxs" color="white" />
        ) : (
          <AiFillEdit className="m-lxxxs" />
        )}
      </Button>
    </Popover>
  );
}

const PopoverContent = forwardRef(function PopoverContent(
  {
    open,
    isNegotiatedRateAvailable,
    originalRate,
    negotiatedRate,
    onClose,
    onRemove,
    onSubmit,
  }: {
    open: boolean;
    isNegotiatedRateAvailable: boolean;
    originalRate: number;
    negotiatedRate: number;
    onClose: () => void;
    onRemove: () => void;
    onSubmit: (value: number) => void;
  },
  ref: ForwardedRef<HTMLInputElement>
) {
  const [value, setValue] = useState<number | null>(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const percentageOff =
    value !== null && originalRate !== 0
      ? Math.round(((value - originalRate / 100) / (originalRate / 100)) * 100)
      : 0;

  const feedback = useMemo(() => {
    if (value !== null && percentageOff !== undefined) {
      if (percentageOff < -50) {
        return {
          status: "warning",
          message: "Offer amount too low",
          tagColor: COLORS.COLOR_WARNING,
        };
      } else if (percentageOff >= -50 && percentageOff < -30) {
        return {
          status: "warning",
          message: "Offer likely to be declined. We recommend increasing this amount.",
          tagColor: COLORS.COLOR_WARNING,
        };
      } else if (percentageOff >= -30 && percentageOff <= 0 && value !== originalRate / 100) {
        return { status: "success", message: null, tagColor: COLORS.COLOR_SUCCESS };
      } else if (percentageOff === 0) {
        return {
          status: "warning",
          message: "Your offer amount is the same as the podcaster's rate.",
          tagColor: COLORS.COLOR_WARNING,
        };
      } else if (percentageOff > 0 && value > originalRate / 100) {
        return {
          status: "warning",
          message: "Your offer amount is higher than the podcaster's rate.",
          tagColor: COLORS.COLOR_WARNING,
        };
      } else {
        return {};
      }
    }
  }, [percentageOff, value]);

  useEffect(() => {
    if (isNegotiatedRateAvailable && negotiatedRate) {
      setValue(negotiatedRate / 100);
    } else {
      setValue(null);
    }
  }, [negotiatedRate, isNegotiatedRateAvailable, open]);

  const handleSubmit = async () => {
    if (value !== null) {
      setIsSubmitLoading(true);
      await onSubmit(Math.floor(value * 100));
      setIsSubmitLoading(false);
      onClose();
    }
  };

  const handleRemove = async () => {
    setIsSubmitLoading(true);
    await onRemove();
    setIsSubmitLoading(false);
    onClose();
  };

  return (
    <Form>
      <div className="p-axs">
        <div className="flex-row-container align-center">
          <div>
            <h5 className="m-bxxxs">Rate Negotiation</h5>
            <span> The podcaster will have one opportunity to accept or decline your offer.</span>
          </div>
          <div
            style={{ background: COLORS.GRAY_LIGHTER, borderRadius: 4 }}
            className="p-axs flex-column-container align-center m-ls">
            <span className="redcircle-form-label">
              {isNegotiatedRateAvailable ? "ORIGINAL CPM" : "CPM RATE"}
            </span>
            <h4>{formatMoney(originalRate / 100)}</h4>
          </div>
        </div>
        <Divider className="m-txxs" />

        <div>
          <div className="redcircle-form-label m-bxxxs">
            Rate you want to offer this show{" "}
            <Tooltip title="This is the rate that will be sent to the podcaster.">
              <AiFillInfoCircle />
            </Tooltip>
          </div>
          <InputNumber
            className="width-100"
            size="large"
            prefix="$"
            min={0.01}
            precision={2}
            value={value}
            onChange={setValue}
            ref={ref}
            suffix={
              percentageOff && (
                <Tag color={feedback?.tagColor}>
                  {percentageOff}% {percentageOff > 1 ? "higher" : "off"}
                </Tag>
              )
            }
          />
          {feedback?.message && <small className="color-warning m-txxs">{feedback.message}</small>}
        </div>
      </div>

      <Modal.Footer>
        <Button type="link" className="p-a0" onClick={onClose}>
          Cancel
        </Button>
        <div className="flex-row-container align-center">
          <Button
            type="link"
            disabled={!isNegotiatedRateAvailable}
            onClick={handleRemove}
            loading={isSubmitLoading}>
            Remove
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={isSubmitLoading}
            className="m-lxxs"
            onClick={handleSubmit}
            disabled={value === null || value === 0}>
            Submit
          </Button>
        </div>
      </Modal.Footer>
    </Form>
  );
});
