import { useContext, useMemo, useState } from "react";
import { Button } from "redcircle-ui";
import { showWarning } from "src/actions/app";
import { useDispatchTS } from "src/hooks/redux-ts";
import { isScriptRequired } from "src/lib/campaigns";
import {
  AddScript,
  addScriptValidate,
  createOrUpdateScript,
} from "../add_script_modal/add_script_modal";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";

export default function SchedulerAddScriptPage() {
  const { campaign, scriptState, setScriptState } = useContext(CampaignSchedulerContext);
  if (!campaign) return null;
  return (
    <AddScript
      formState={scriptState}
      setFormState={setScriptState}
      title="Script"
      enableDelete
      campaignUUID={campaign?.uuid}
    />
  );
}

export const SchedulerAddScriptPageFooter = ({
  onBack,
  onSkip,
  onSubmitSuccess,
}: {
  onBack: () => void;
  onSkip: () => void;
  onSubmitSuccess: () => void;
}) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const dispatch = useDispatchTS();
  const { campaign, scriptState } = useContext(CampaignSchedulerContext);
  const [isValid, errorValidation] = addScriptValidate(scriptState);
  const scriptRequired = useMemo(() => {
    return campaign ? isScriptRequired(campaign) : false;
  }, [campaign?.startsAt]);

  const handleSubmit = async () => {
    if (campaign) {
      setIsSubmitLoading(true);
      try {
        await createOrUpdateScript({
          campaignUUID: campaign.uuid,
          scripts: scriptState,
          enableSuccessMessage: false,
        });
        onSubmitSuccess();
      } catch (err) {
        dispatch(showWarning("There was an issue updating the script(s) for this campaign"));
      }
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <Button type="link" className="p-a0" size="large" onClick={onBack}>
        Back
      </Button>
      <div className="flex-row-container align-center">
        {!scriptRequired && (
          <Button type="link" size="large" className="m-rxs" onClick={onSkip}>
            Add Scripts Later
          </Button>
        )}
        <Button
          type="primary"
          size="large"
          onClick={handleSubmit}
          disabled={!isValid}
          loading={isSubmitLoading}>
          Continue
        </Button>
      </div>
    </>
  );
};
