import { Divider, DividerProps } from "antd";
import { COLORS } from "../../theme/colors";

export default function RCDivider({
  light,
  style = {},
  ...props
}: DividerProps & { light?: boolean }) {
  if (light) style.borderTop = `1px solid ${COLORS.DIVIDER_COLOR}`;
  return <Divider style={style} {...props} />;
}
