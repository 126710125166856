import dayjs from "dayjs";
import { capitalize, get, isEqual } from "lodash";
import { store } from "src";
import { CampaignTargetingOptionToFriendly } from "src/constants/campaigns";
import { ICampaign, ICampaignTargetingOptions } from "src/reducers/campaigns/types";
import { CampaignItemOverrideValue, ICampaignItem } from "src/reducers/campaign_items/types";
import { isInternalRCUser } from "./user";

export const ADVERTISING_CUT_BASIS_POINTS_DEFAULT = 3000;

export const CAMPAIGN_ITEM_OVERRIDE_FIELDS: Record<string, string> = {
  frequencyConfigs: "Frequency Capping",
  hardEndDate: "Hard End Date",
  pacing: "Pacing",
  recentEpisodesOnly: "Recent Episodes Only",
  requiresEndorsement: "Requires Endorsement",
  targetingOptions: "Episode Audio Placement",
  "lifecycleSettings.pixelRequired": "Pixel Required",
  "lifecycleSettings.startAt": "Start Date",
  "lifecycleSettings.endAt": "End Date",
  "lifecycleSettings.assignAudioTask.deadline": "Assign Audio Deadline",
  "lifecycleSettings.responseTask.deadline": "Response Deadline",
  advertisingCutBasisPoints: "RedCircle Cut",
};

export const CAMPAIGN_OVERRIDE_FIELDS: Record<string, string> = {
  frequencyConfigs: "Frequency Capping",
  hardEndDate: "Hard End Date",
  pacing: "Pacing",
  recentEpisodesOnly: "Recent Episodes Only",
  requiresEndorsement: "Requires Endorsement",
  targetingOptions: "Episode Audio Placement",
  pixelRequired: "Pixel Required",
  startAt: "Start Date",
  endAt: "End Date",
  assignAudioDeadline: "Assign Audio Deadline",
  responseDeadline: "Response Deadline",
};

export const getCampaignItemOverrides = (
  campaignItem: ICampaignItem,
  { showInternal } = { showInternal: true }
) => {
  if (!campaignItem.isV2) return;
  const { user } = store.getState()?.user;

  const overrides = Object.keys(CAMPAIGN_ITEM_OVERRIDE_FIELDS).reduce((acc, field) => {
    const fieldRef = get(campaignItem, field);

    if (
      fieldRef?.overridden &&
      fieldRef.value !== undefined &&
      fieldRef.default !== undefined &&
      !isEqual(fieldRef.value, fieldRef.default)
    )
      acc[field] = fieldRef;
    return acc;
  }, {} as any);

  if (
    user &&
    showInternal &&
    isInternalRCUser(user) &&
    campaignItem.advertisingCutBasisPoints !== ADVERTISING_CUT_BASIS_POINTS_DEFAULT
  ) {
    overrides.advertisingCutBasisPoints = {
      default: ADVERTISING_CUT_BASIS_POINTS_DEFAULT,
      value: campaignItem.advertisingCutBasisPoints,
      overridden: true,
    };
  }

  if (Object.keys(overrides).length === 0) return;
  return overrides as Record<string, CampaignItemOverrideValue<any>>;
};

export const REQUEST_FIELD_TO_CAMPAIGN_ITEM_FIELD: Record<string, string> = {
  startAt: "lifecycleSettings.startAt",
  endAt: "lifecycleSettings.endAt",
  pixelRequired: "lifecycleSettings.pixelRequired",
  frequencyConfigs: "frequencyConfigs",
  hardEndDate: "hardEndDate",
  pacing: "pacing",
  recentEpisodesOnly: "recentEpisodesOnly",
  requiresEndorsement: "requiresEndorsement",
  targetingOptions: "targetingOptions",
  assignAudioDeadline: "lifecycleSettings.assignAudioTask.deadline",
  responseDeadline: "lifecycleSettings.responseTask.deadline",
};

// transform fields to request body
export const transformFormFieldsToOverrideRequest = (fields: Record<string, any>) => {
  if (!fields) return {};
  return Object.entries(fields).reduce(
    (acc, [key, value]) => {
      if (key === "timeline" && value) {
        if (value[0]) acc.startAt = value[0].unix();
        if (value[1]) acc.endAt = value[1].unix();
      } else if (key === "deadlines" && value) {
        if (value.assignAudioDeadline) acc.assignAudioDeadline = value.assignAudioDeadline.unix();
        if (value.responseDeadline) acc.responseDeadline = value.responseDeadline.unix();
      } else if (key === "targetingOptionsArray" && value) {
        const targetingKeys = Object.keys(CampaignTargetingOptionToFriendly);
        acc.targetingOptions = targetingKeys.reduce((acc: Record<string, boolean>, k: string) => {
          acc[k as keyof ICampaignTargetingOptions] = value.includes(k);
          return acc;
        }, {});
      } else if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, any>
  );
};

// this function is used to compare the request body with the campaign item
// and nullify fields accordingly if we are unsetting the value
export const diffRequestFromCampaignItem = (
  request: Record<string, any>,
  campaignItem: ICampaignItem
) => {
  const cleanedRequest = Object.entries(request).reduce(
    (acc, [requestKey, requestValue]) => {
      const campaignItemKey = REQUEST_FIELD_TO_CAMPAIGN_ITEM_FIELD[requestKey];
      const campaignItemValue: CampaignItemOverrideValue<any> = campaignItemKey
        ? get(campaignItem, campaignItemKey)
        : undefined;

      if (campaignItemValue) {
        if (
          isFieldsEqual(campaignItemValue.default, requestValue) &&
          campaignItemValue.overridden
        ) {
          acc[requestKey] = null;
        } else if (isFieldsEqual(campaignItemValue.value, requestValue)) {
          return acc;
        } else {
          acc[requestKey] = requestValue;
        }
      }

      return acc;
    },
    {} as Record<string, any>
  );

  return cleanedRequest;
};

const isFieldsEqual = (field1: any, field2: any) => {
  if (typeof field1 !== typeof field2) return false;
  if (Array.isArray(field1) && Array.isArray(field2)) {
    return field1.length === field2.length && field1.every((v, i) => v === field2[i]);
  }
  // null is considered an object, so we need to check for that
  if (
    typeof field1 === "object" &&
    typeof field2 === "object" &&
    field1 !== null &&
    field2 !== null
  ) {
    return (
      Object.keys(field1).length === Object.keys(field2).length &&
      Object.keys(field1).every((key) => field1[key] === field2[key])
    );
  }
  return field1 === field2;
};

export const diffFieldsFromCampaign = (fields: Record<string, any>, campaign?: ICampaign) => {
  if (!campaign) return {};
  const transformedFields = transformFormFieldsToOverrideRequest(fields);
  return Object.entries(transformedFields).reduce(
    (acc, [key, value]) => {
      let campaignValue = get(campaign, key);
      if (key === "startAt") campaignValue = get(campaign, "startsAtV2");
      if (key === "endAt") campaignValue = get(campaign, "endsAt");
      if (!isFieldsEqual(campaignValue, value)) acc[key] = value;
      return acc;
    },
    {} as Record<string, any>
  );
};

export const overrideValueToFriendly = (key: string, value: any) => {
  if (key === "frequencyConfigs" && Array.isArray(value)) {
    if (value.length === 0) {
      return "None";
    } else {
      return value.map((v) => `${v.maxCount} per ${v.interval}`).join(", ");
    }
  }

  if (key === "targetingOptions" && typeof value === "object") {
    return Object.entries(value)
      .map(([k, v]) => {
        return v ? capitalize(k.replace("Roll", "")) : false;
      })
      .filter((v) => v)
      .join(", ");
  }

  if (key === "advertisingCutBasisPoints") {
    return `${value / 100}%`;
  }

  // probably a date
  if (typeof value === "number") {
    if (value < 0) return "None";
    return dayjs.unix(value).format("M/D/YYYY");
  }
  if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  }
  return JSON.stringify(value);
};

// reverse map these keys to their requests and nullify all fields
export const createOverrideResetRequest = (campaignItem: ICampaignItem) => {
  const overrides = getCampaignItemOverrides(campaignItem);
  const keys = overrides && Object.keys(overrides);
  return keys?.reduce(
    (acc, key) => {
      const requestKey = Object.entries(REQUEST_FIELD_TO_CAMPAIGN_ITEM_FIELD).find(
        ([_, value]) => value === key
      )?.[0];
      if (requestKey) acc[requestKey] = null;
      return acc;
    },
    {} as Record<string, any>
  );
};
