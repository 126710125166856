import { Dispatch } from "redux";
import { UnixTimeStamp } from "src/lib/date";
import { isUUID } from "src/lib/uuid";
import {
  CampaignPromoCodeType,
  FrequencyCapConfig,
  ICampaign,
  ICampaignTag,
  ICampaignTargetingOptions,
} from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { CampaignItemState, IOfferRates } from "src/reducers/campaign_items/types";
import { CreditCardPaymentMethod } from "../constants/campaigns";
import DefaultActionManager, { ARGS, defaultActionManagerRunner } from "./default_action_manager";
export const RESET_CAMPAIGN_ITEMS = "RESET_CAMPAIGN_ITEMS";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const EDIT_CAMPAIGN = "EDIT_CAMPAIGN";
export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const COPY_CAMPAIGN = "COPY_CAMPAIGN";
export const GET_CAMPAIGNS_FOR_USER = "GET_CAMPAIGNS_FOR_USER";
export const CREATE_CAMPAIGN_ITEM = "CREATE_CAMPAIGN_ITEM";
export const UPDATE_CAMPAIGN_ITEMS = "UPDATE_CAMPAIGN_ITEMS";
export const UPDATE_CAMPAIGN_ITEMS_V2 = "UPDATE_CAMPAIGN_ITEMS_V2";
export const UPDATE_CAMPAIGN_ITEM_V2 = "UPDATE_CAMPAIGN_ITEM_V2";
export const REMOVE_CAMPAIGN_ITEMS = "REMOVE_CAMPAIGN_ITEMS";
export const UPDATE_CAMPAIGN_ITEM = "UPDATE_CAMPAIGN_ITEM";
export const SEND_CAMPAIGN = "SEND_CAMPAIGN";
export const CAMPAIGN_ONE_WEEK = "CAMPAIGN_ONE_WEEK";
export const GET_CAMPAIGN_STATS = "GET_CAMPAIGN_STATS";
export const GET_CAMPAIGN_WEEKLY_STATS = "GET_CAMPAIGN_WEEKLY_STATS";
export const GET_CAMPAIGN_ITEM_REFORECAST = "GET_CAMPAIGN_ITEM_REFORECAST";
export const PAUSE_CAMPAIGN_ITEM = "PAUSE_CAMPAIGN_ITEM";
export const UNPAUSE_CAMPAIGN_ITEM = "UNPAUSE_CAMPAIGN_ITEM";
export const UPDATE_CAMPAIGN_META = "UPDATE_CAMPAIGN_META";
export const GET_SAMPLE_AD_READ = "GET_SAMPLE_AD_READ";
export const INITATE_AUDIO_SWAP = "INITATE_AUDIO_SWAP";
export const CANCEL_AUDIO_SWAP = "CANCEL_AUDIO_SWAP";
export const GET_CAMPAIGN_TAGS = "GET_CAMPAIGN_TAGS";
export const CREATE_CAMPAIGN_TAG = "CREATE_CAMPAIGN_TAG";
export const UPDATE_CAMPAIGN_TAG = "UPDATE_CAMPAIGN_TAG";
export const DELETE_CAMPAIGN_TAG = "DELETE_CAMPAIGN_TAG";
export const ASSIGN_ITEM_TO_TAG = "ASSIGN_ITEM_TO_TAG";
export const ASSIGN_SCRIPT_TO_ITEM = "ASSIGN_SCRIPT_TO_ITEM";
export const CAMPAIGN_ITEM_PAUSE = "CAMPAIGN_ITEM_PAUSE";
export const CAMPAIGN_ITEM_OVERRIDE = "CAMPAIGN_ITEM_OVERRIDE";
export const CAMPAIGN_ITEMS_OVERRIDE = "CAMPAIGN_ITEMS_OVERRIDE";

export const createCampaign = (campaign: Partial<ICampaign>) =>
  defaultActionManagerRunner<ICampaign>({
    route: "campaigns",
    method: "post",
    actionName: CREATE_CAMPAIGN,
    body: campaign,
    auth: true,
  });

interface UpdateCampaignRequest
  extends Omit<
    Partial<ICampaign>,
    "isV2" | "pixelRequired" | "startsAtV2" | "assignAudioDeadline" | "responseDeadline" | "endsAt"
  > {}

interface UpdateCampaignRequestV2
  extends Omit<
    Partial<ICampaign>,
    "targetingOptions" | "startsAt" | "pacing" | "recentEpisodesOnly" | "hardEndDate"
  > {
  pixelRequired?: boolean;
  targetingOptions?: ICampaignTargetingOptions;
  requiresEndorsement?: boolean;
  pacing?: boolean;
  recentEpisodesOnly?: boolean;
  hardEndDate?: boolean;
  FrequencyConfigs?: FrequencyCapConfig;
  startsAt?: UnixTimeStamp;
  assignAudioDeadline?: UnixTimeStamp | null;
  responseDeadline?: UnixTimeStamp | null;
  endsAt?: UnixTimeStamp;
}
// TODO remove V1 request type and conditional post editable rap project
export const editCampaign = (request: UpdateCampaignRequest | UpdateCampaignRequestV2) => {
  if ("isV2" in request && request.isV2) {
    return defaultActionManagerRunner({
      route: `campaigns/v2/${request.uuid}`,
      method: "post",
      actionName: EDIT_CAMPAIGN,
      body: request,
      auth: true,
    });
  }

  return defaultActionManagerRunner({
    route: `campaigns/${request.uuid}`,
    method: "post",
    actionName: EDIT_CAMPAIGN,
    body: request,
    auth: true,
  });
};

export const getCampaign = (uuid: string) => {
  return defaultActionManagerRunner<ICampaign>({
    route: `campaigns/${uuid}`,
    actionName: GET_CAMPAIGN,
    auth: true,
  });
};

export const copyCampaign = (campaignUUID: string, body: any) => {
  return defaultActionManagerRunner({
    route: `campaigns/${campaignUUID}/copy`,
    method: "post",
    actionName: COPY_CAMPAIGN,
    body,
    auth: true,
  });
};

export const updateCampaignMeta = (
  campaignUUID: string,
  meta: Partial<{
    csvLastExportedAt: UnixTimeStamp;
    archived: boolean;
    startsAt: UnixTimeStamp;
    estimatedEndsAt: UnixTimeStamp;
  }>,
  extraArgs?: Partial<ARGS>
) => {
  return defaultActionManagerRunner<ICampaign>({
    route: `campaigns/${campaignUUID}/metadata`,
    actionName: UPDATE_CAMPAIGN_META,
    auth: true,
    body: meta,
    method: "put",
    ...extraArgs,
  });
};

export const getCampaignsForUser = (uuid: string) =>
  defaultActionManagerRunner({
    route: `users/${uuid}/campaigns`,
    actionName: GET_CAMPAIGNS_FOR_USER,
    auth: true,
  });

export const createCampaignItems = (campaignUUID: string, body: any) =>
  defaultActionManagerRunner({
    route: `campaigns/${campaignUUID}/create-items`,
    body,
    auth: true,
    method: "post",
    actionName: CREATE_CAMPAIGN_ITEM,
  });

type CampaignItemUpdateRequestV2 = {
  offerRates?: Partial<ICampaignItem["offerRates"]>;
  totalBudget?: ICampaignItem["totalBudget"];
  // AdvertisingCutBasisPoints is the cut that RedCircle takes on the ad.
  advertisingCutBasisPoints?: ICampaignItem["advertisingCutBasisPoints"];
  webhookURL?: ICampaignItem["webhookURL"];
  promoCode?: ICampaignItem["promoCode"];
  feedCTA?: ICampaignItem["feedCTA"];
};

export const v1ToV2CampaignItems = (items: {
  [campaignItemUUID: string]: CampaignItemUpdateRequestV2;
}) => {
  return Object.entries(items).reduce(
    (accu, [campaignItemUUID, item]) => {
      accu[campaignItemUUID] = {
        offerRates: item.offerRates,
        totalBudget: item.totalBudget,
        promoCode: item.promoCode,
        advertisingCutBasisPoints: item.advertisingCutBasisPoints,
        webhookURL: item.webhookURL,
        feedCTA: item.feedCTA,
      };
      return accu;
    },
    {} as { [campaignItemUUID: string]: CampaignItemUpdateRequestV2 }
  );
};

/**
 * This is Strictly a V2 campaignItem end point, apparently singular V1 campaignItem update does not exists, it is only
 * the webhook update url that counts as V1 campaignItem update
 */
export const updateCampaignItemV2 = (
  campaignItemUUID: string,
  campaignItemUpdates: CampaignItemUpdateRequestV2,
  actionManagerSettings?: Partial<Pick<ARGS, "successMessage">>
) => {
  return defaultActionManagerRunner<ICampaignItem, CampaignItemUpdateRequestV2>({
    ...actionManagerSettings,
    route: `campaign-items/${campaignItemUUID}/update-v2`,
    body: campaignItemUpdates,
    auth: true,
    method: "post",
    actionName: UPDATE_CAMPAIGN_ITEM_V2,
  });
};

export const updateCampaignItems = ({
  campaignUUID,
  items,
  message = "",
  messageSize = undefined,
  campaign,
}: {
  campaignUUID?: string;
  items: {
    [campaignItemUUID: string]: {
      showUUID?: string;
      mediaFileUUID?: string;
      totalBudget?: number;
      startAt?: UnixTimeStamp;
      pacingEstimatedEndAt?: UnixTimeStamp;
      promoCode?: string;
      /**
       * @deprecated The property is deprecated, no longer using discreet campaigns properties (i.e. spot rate, flight configs)
       */
      flightConfigs?: Record<string, any>;
      state?: CampaignItemState;
      delete?: boolean;
      offerRates?: Partial<IOfferRates>;
      webhookURL?: ICampaignItem["webhookURL"];
      feedCTA?: ICampaignItem["feedCTA"];
    };
  };
  message?: string;
  messageSize?: ARGS["messageSize"];
  campaign?: ICampaign;
}) => {
  const uuid = campaignUUID || campaign?.uuid;
  const isV2 = campaign?.isV2;

  if (isV2) {
    // items need to be remapped for new endpoints
    return defaultActionManagerRunner({
      route: `campaigns/${uuid}/batch-update-v2`,
      body: { requestsByCampaignItemUUID: v1ToV2CampaignItems(items) },
      auth: true,
      method: "post",
      actionName: UPDATE_CAMPAIGN_ITEMS_V2,
      ...(message && { successMessage: message }),
      messageSize,
    });
  }

  // TODO: deprecate when all active campaigns are in v2
  return defaultActionManagerRunner({
    route: `campaigns/${uuid}/update-items`,
    body: { updateRequestByCampaignItemUUID: items },
    auth: true,
    method: "post",
    actionName: UPDATE_CAMPAIGN_ITEMS,
    ...(message && { successMessage: message }),
    messageSize,
  });
};

type OverrideCampaignItemRequest = {
  pixelRequired?: null | boolean;
  targetingOptions?: null | ICampaignTargetingOptions;
  requiresEndorsement?: null | boolean;
  pacing?: null | boolean;
  recentEpisodesOnly?: null | boolean;
  hardEndDate?: null | boolean;
  FrequencyConfigs?: null | FrequencyCapConfig;
  startsAt?: null | UnixTimeStamp;
  assignAudioDeadline?: null | UnixTimeStamp;
  responseDeadline?: null | UnixTimeStamp;
  endsAt?: null | UnixTimeStamp;
};

export const overrideCampaignItem = ({
  campaignItemUUID,
  request,
}: {
  campaignItemUUID: string;
  request: OverrideCampaignItemRequest;
}) => {
  return defaultActionManagerRunner<Partial<ICampaignItem>>({
    route: `campaign-items/${campaignItemUUID}/overrides`,
    body: request,
    auth: true,
    method: "post",
    actionName: CAMPAIGN_ITEM_OVERRIDE,
  });
};

export const overrideCampaignItems = ({
  campaignUUID,
  request,
}: {
  campaignUUID: string;
  request: { [campaignItemUUID: string]: OverrideCampaignItemRequest };
}) => {
  return defaultActionManagerRunner<Partial<ICampaignItem>[]>({
    route: `campaign/${campaignUUID}/overrides`,
    body: { requestsByCampaignItemUUID: request },
    auth: true,
    method: "post",
    actionName: CAMPAIGN_ITEMS_OVERRIDE,
  });
};

/**
 * Specific action manager for removing campaign items from cart (Deleting campaign Items).
 * This is needed since the previous way is to update campaignItems with delete === true prop
 * but this does not automatically remove the deleted item from redux state. This
 * action manager takes care of that.
 */
export const removeCampaignItems = ({
  campaignUUID,
  campaignItemUUIDs = [],
  campaign,
  successMessage = "",
}: {
  campaignUUID?: string;
  campaignItemUUIDs: string[];
  campaign?: ICampaign;
  successMessage?: string;
}) => {
  const uuid = campaignUUID || campaign?.uuid;
  const isV2 = campaign?.isV2;

  if (isV2) {
    return defaultActionManagerRunner({
      route: `campaigns/${uuid}/batch-delete-v2`,
      body: { campaignItemUUIDs },
      auth: true,
      method: "post",
      actionName: REMOVE_CAMPAIGN_ITEMS,
      actionData: campaignItemUUIDs, // passing data to reducer in order to remove campaign Items
      successMessage,
    });
  }

  const body = campaignItemUUIDs.reduce(
    (accu, uuid) => {
      if (isUUID(uuid) && !accu[uuid]) {
        accu[uuid] = { delete: true };
      }
      return accu;
    },
    {} as Record<string, { delete: true }>
  );

  return defaultActionManagerRunner({
    route: `campaigns/${uuid}/update-items`,
    body: {
      updateRequestByCampaignItemUUID: body,
    },
    auth: true,
    method: "post",
    actionName: REMOVE_CAMPAIGN_ITEMS,
    actionData: campaignItemUUIDs, // passing data to reducer in order to remove campaign Items
    successMessage,
  });
};

export const updateCampaignItemWebhook = (campaignItem: ICampaignItem, webhookURL: string) => {
  if ("isV2" in campaignItem && campaignItem?.isV2) {
    return updateCampaignItemV2(
      campaignItem.uuid,
      {
        webhookURL,
      },
      { successMessage: "Pixel URL updated" }
    );
  }

  return defaultActionManagerRunner({
    route: `campaign-items/${campaignItem.uuid}/update-webhook`,
    body: {
      webhookURL,
    },
    auth: true,
    method: "post",
    successMessage: "Pixel URL updated",
    actionName: UPDATE_CAMPAIGN_ITEM,
  });
};

export const sendCampaign = (
  campaignUUID: string,
  cardToken?: string,
  paymentType = CreditCardPaymentMethod
) =>
  defaultActionManagerRunner({
    route: `campaigns/${campaignUUID}/send`,
    body: {
      cardToken,
      paymentType,
    },
    auth: true,
    method: "post",
    actionName: SEND_CAMPAIGN,
  });

// This returns the estimate of budget of a campaign for a given campaign item for one week
export const campaignOneWeek = (campaignUUID: string) =>
  defaultActionManagerRunner({
    route: `campaigns/${campaignUUID}/one-week`,
    auth: true,
    actionName: CAMPAIGN_ONE_WEEK,
  });

export const getCampaignStats = (campaignUUID: string) =>
  defaultActionManagerRunner({
    route: `advertiser_stats/campaigns/${campaignUUID}`,
    auth: true,
    actionName: GET_CAMPAIGN_STATS,
    actionData: {
      campaignUUID,
    },
  });

export const reforecastCampaignItem = (campaignItemUUID: string) =>
  defaultActionManagerRunner({
    route: `campaign-items/${campaignItemUUID}/reforecast`,
    auth: true,
    actionName: GET_CAMPAIGN_ITEM_REFORECAST,
    silent: true,
  });

/**
 * Only for V2 Campaigns/CampaignItems,
 */
export const pauseCampaignItem = ({
  campaignItemUUID,
  reason,
}: {
  campaignItemUUID: string;
  reason: string;
}) =>
  defaultActionManagerRunner<ICampaignItem, { reason: string }>({
    route: `campaign-items/${campaignItemUUID}/pause`,
    auth: true,
    method: "post",
    actionName: PAUSE_CAMPAIGN_ITEM,
    body: { reason },
  });

/**
 *  Only for V2 Campaigns/Campaign items
 */
export const unpauseCampaignItem = ({ campaignItemUUID }: { campaignItemUUID: string }) => {
  return defaultActionManagerRunner<ICampaignItem>({
    route: `campaign-items/${campaignItemUUID}/unpause`,
    auth: true,
    method: "post",
    actionName: UNPAUSE_CAMPAIGN_ITEM,
  });
};

export const getSampleAdReads = (showUUID: string) =>
  defaultActionManagerRunner({
    route: `shows/${showUUID}/ad-reads`,
    auth: true,
    actionData: {
      showUUID,
    },
    actionName: GET_SAMPLE_AD_READ,
    silent: true,
  });

export const getCampaignWeeklyStats = (campaignUUID: string) =>
  defaultActionManagerRunner({
    route: `advertiser_stats/campaigns/${campaignUUID}/weekly`,
    auth: true,
    actionName: GET_CAMPAIGN_WEEKLY_STATS,
    actionData: {
      campaignUUID,
    },
  });

// Audio Swap Request

export const initiateAudioSwap = ({
  isPodcaster,
  campaignItemUUID,
  reason,
  newMediaFileUUID,
  feedCTA,
}: {
  isPodcaster: boolean;
  campaignItemUUID: string;
  reason?: string;
  newMediaFileUUID: string;
  feedCTA: string;
}) => {
  const reqBody: {
    newMediaFileUUID?: string;
    feedCTA?: string;
    reason?: string;
  } = {
    reason,
  };

  if (isPodcaster) {
    reqBody["newMediaFileUUID"] = newMediaFileUUID;
    reqBody["feedCTA"] = feedCTA;
  }

  return defaultActionManagerRunner({
    route: `campaign-items/${campaignItemUUID}/${
      isPodcaster ? "swap-audio" : "request-audio-swap"
    }`,
    auth: true,
    method: "POST",
    actionName: INITATE_AUDIO_SWAP,
    body: reqBody,
  });
};

export const cancelAudioSwap = (campaignItemUUID: string) =>
  defaultActionManagerRunner({
    route: `campaign-items/${campaignItemUUID}/cancel-swap-audio`,
    auth: true,
    method: "POST",
    actionName: CANCEL_AUDIO_SWAP,
  });

// Tags/Folders
class CampaignTagActionManager extends DefaultActionManager {
  responseError(dispatch: Dispatch, status: number, json: unknown) {
    // If the user hasn't created a campaign tag yet, the back end 404's
    if (status !== 404) {
      return super.responseError(dispatch, status, json);
    }
    return dispatch(this.defaultErrorActionCreator()());
  }
}

export const getCampaignTags = (userUUID: string) => {
  return new CampaignTagActionManager({
    actionName: GET_CAMPAIGN_TAGS,
    route: `users/${userUUID}/campaign-tags`,
    auth: true,
  }).run();
};

export const createCampaignTag = (userUUID: string, title: string) => {
  return defaultActionManagerRunner({
    actionName: CREATE_CAMPAIGN_TAG,
    route: `campaign-tags`,
    auth: true,
    method: "POST",
    body: { title, userUUID },
  });
};

export const updateCampaignTag = (newTag: ICampaignTag) => {
  return defaultActionManagerRunner({
    actionName: UPDATE_CAMPAIGN_TAG,
    route: `campaign-tags/${newTag.uuid}`,
    auth: true,
    method: "PUT",
    body: newTag,
  });
};

export const deleteCampaignTag = (tagUUID: string) => {
  return defaultActionManagerRunner({
    actionName: DELETE_CAMPAIGN_TAG,
    route: `campaign-tags/${tagUUID}`,
    auth: true,
    method: "DELETE",
  });
};

// handles moving a tag to a new parent
// if currentParentUUID is null then adds to a new folder
export const assignItemToTag = ({
  childUUID,
  existingParentUUID,
  newParentUUID,
  childType,
}: {
  childUUID: string;
  existingParentUUID?: string;
  newParentUUID?: string;
  childType: string;
}) => {
  return defaultActionManagerRunner({
    actionName: ASSIGN_ITEM_TO_TAG,
    route: `campaign-tags/assign`,
    auth: true,
    method: "POST",
    body: { childUUID, childType, existingParentUUID, newParentUUID },
  });
};

/**
 * Assign scripts
 */
export const assignScripts = (
  campaignUUID: string,
  newScriptAssignments: {
    [campaignItemUUID: string]: {
      scriptUUID: string | null;
      promoCode?: string | null;
      promoCodeType: CampaignPromoCodeType;
    };
  }
) => {
  return defaultActionManagerRunner({
    route: `campaigns/${campaignUUID}/assign-scripts`,
    auth: true,
    method: "POST",
    actionName: ASSIGN_SCRIPT_TO_ITEM,
    body: newScriptAssignments,
  });
};
