import { merge, mergeWith } from "lodash";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import uniq from "lodash/uniq";
import { AnyAction, Reducer } from "redux";
import {
  FETCH_SHOW_CAMPAIGNS_ACTION,
  FETCH_SHOW_CAMPAIGN_ACTION,
  RESET_CAMPAIGNS,
} from "../../actions/campaigns";
import {
  CREATE_CAMPAIGN,
  EDIT_CAMPAIGN,
  GET_CAMPAIGN,
  GET_CAMPAIGNS_FOR_USER,
  GET_CAMPAIGN_STATS,
  GET_CAMPAIGN_TAGS,
  SEND_CAMPAIGN,
  UPDATE_CAMPAIGN_META,
} from "../../action_managers/campaigns";
import { httpMultipleActionsReducer, httpReducer, reduceReducers } from "../../lib/create-action";
import { ICampaign, ICampaignStatsByUUID, ICampaignTag } from "./types";
import { campaignIsDiscrete } from "src/lib/campaigns";

export type CampaignState = {
  campaignStatsByUUID: ICampaignStatsByUUID;
  campaigns: { [key: string]: ICampaign };
  campaignsForUser: string[];
  isLoading: boolean;
  statsIsLoading: boolean;
  campaignsForUserIsLoading: boolean;
  campaignsForUserInitialFetched: boolean;
  campaignTags: ICampaignTag[] | undefined; // semantically implying prop always exists, but its value is set to "undefined"
  campaignTagsIsLoading: boolean;
  campaignTagsInitialFetched: boolean;
};

export const initialState: CampaignState = {
  // campaignUUID --> stats for that campaign
  campaignStatsByUUID: {} as ICampaignStatsByUUID,
  campaigns: {} as { [key: string]: ICampaign },
  campaignsForUser: [] as string[],
  isLoading: false,
  statsIsLoading: false,
  campaignsForUserIsLoading: false,
  campaignsForUserInitialFetched: false,
  campaignTags: undefined,
  campaignTagsIsLoading: false,
  campaignTagsInitialFetched: false,
};

/**
 * Removes discreet campaigns
 * @param {ICampaign[]}
 * @returns {ICampaign[]}
 */
const removeDiscreetCampaigns = (campaigns?: ICampaign[]) => {
  if (!Array.isArray(campaigns)) return [];

  return campaigns.filter((campaign) => !campaignIsDiscrete(campaign));
};

const resetCampaigns = (state = initialState, action: AnyAction) => {
  if (action.type === RESET_CAMPAIGNS) {
    return {
      ...initialState,
      isLoading: state.isLoading,
      campaignsForUserIsLoading: state.campaignsForUserIsLoading,
      statsIsLoading: state.statsIsLoading,
    };
  }

  return state;
};

const getShowCampaign = httpReducer(FETCH_SHOW_CAMPAIGN_ACTION, initialState, {
  success: (state = initialState, action) => {
    const response = action.payload.resp;
    const campaign = get(response, "campaign");

    return {
      ...state,
      campaigns: { ...state.campaigns, [campaign.uuid]: campaign },
    };
  },
});

const getShowCampaigns = httpReducer(FETCH_SHOW_CAMPAIGNS_ACTION, initialState, {
  success: (state = initialState, action) => {
    const responseCampaigns = keyBy(
      removeDiscreetCampaigns(action.payload.resp?.campaigns),
      "uuid"
    );

    // Ensuring Individual campaigns object props from get campaign are not overwritten by get get Campaign Calls later calls
    const result = mergeWith(state.campaigns, responseCampaigns, (objVal, srcVal) =>
      merge(objVal, srcVal)
    );

    return {
      ...state,
      campaigns: {
        ...result,
      },
    };
  },
});

// these keys we are merging from the campaign object instead of replacing
// this is due to inconsistencies in the API response
const campaignMergeKeys = ["authorizedPermissionTypes" as keyof ICampaign];

const setCampaign: Reducer<CampaignState> = (
  state: CampaignState = initialState,
  action: AnyAction
) => {
  const campaign = action.payload.resp;
  const mergedCampaign = { ...campaign };
  campaignMergeKeys.forEach((key) => {
    mergedCampaign[key] = campaign[key] || mergedCampaign[key];
  });

  return {
    ...state,
    campaigns: { ...state.campaigns, [campaign.uuid]: mergedCampaign },
    campaignsForUser: uniq([...state.campaignsForUser, campaign.uuid]),
  };
};

const editCampaign = httpMultipleActionsReducer<CampaignState>(
  [EDIT_CAMPAIGN, CREATE_CAMPAIGN, GET_CAMPAIGN],
  initialState,
  {
    success: setCampaign,
  }
);

const getCampaignsForUser = httpReducer(
  GET_CAMPAIGNS_FOR_USER,
  initialState,
  {
    success: (state = initialState, action) => {
      const newCampaigns = removeDiscreetCampaigns(action.payload.resp);

      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          ...keyBy(newCampaigns, "uuid"),
        },
        campaignsForUser: newCampaigns.map((campaign: ICampaign) => campaign.uuid),
        campaignsForUserInitialFetched: true,
      };
    },
  },
  { loadingKeyName: "campaignsForUserIsLoading" }
);

const updateCampaignMeta = httpReducer(UPDATE_CAMPAIGN_META, initialState, {
  success: (state = initialState, action) => ({
    ...state,
    campaigns: {
      ...state.campaigns,
      [action.payload.resp.uuid]: {
        ...state.campaigns[action.payload.resp.uuid],
        ...action.payload.resp,
      },
    },
  }),
});

const getCampaignStats = httpReducer(
  GET_CAMPAIGN_STATS,
  initialState,
  {
    success: (state = initialState, action) => {
      const campaignUUID = action.data.campaignUUID;
      const response = action.payload.resp;

      return {
        ...state,
        campaignStatsByUUID: {
          ...state.campaignStatsByUUID,
          [campaignUUID]: response,
        },
      };
    },
  },
  { loadingKeyName: "statsIsLoading" }
);

const sendCampaign = httpReducer(
  SEND_CAMPAIGN,
  initialState,
  {},
  { loadingKeyName: "sendIsLoading" }
);

const getCampaignTags = httpReducer(GET_CAMPAIGN_TAGS, initialState, {
  started: (state = initialState, action) => {
    return { ...state, campaignTagsIsLoading: true };
  },
  success: (state = initialState, action) => {
    const tags = action.payload.resp;
    return {
      ...state,
      campaignTags: tags,
      campaignTagsIsLoading: false,
      campaignTagsInitialFetched: true,
    };
  },
  failure: (state = initialState) => {
    const tags: ICampaignTag[] = [];
    return {
      ...state,
      campaignTags: tags,
      campaignTagsIsLoading: false,
      campaignTagsInitialFetched: true,
    };
  },
});

export default reduceReducers<CampaignState>(
  resetCampaigns,
  editCampaign,
  updateCampaignMeta,
  getCampaignsForUser,
  getShowCampaign,
  sendCampaign,
  getCampaignStats,
  getShowCampaigns,
  getCampaignTags
);
