import colors from "./colors.module.scss";

type ColorMap = {
  PRIMARY_COLOR: "#ed2924";
  PRIMARY_COLOR_HOVER: "#d62520";
  PRIMARY_COLOR_ACTIVE: "#f94e47";
  PRIMARY_COLOR_DARK: "#d62520";
  DIVIDER_COLOR: "#f5f5f5";
  COLOR_ERROR: "#ed2924";
  COLOR_SUCCESS: "#a0d911";
  COLOR_WARNING: "#faad14";
  RED_LIGHTER: "#fcebed";
  BLUE_MEDIUM: "#577d9e";
  BLUE_LIGHT: "#9fb6c8";
  BLUE_LIGHTER: "#eff3f6";
  GREEN_DARK: "#5b8c00";
  WHITE_ANTI_FLASH: "#ecf1f4";
  GRAY_LIGHT: "#d9d9d9";
  GRAY_LIGHTER: "#f5f5f5";
  GRAY_LIGHTEST: "#e5e5e5";
  GRAY_DARK: "#6a6a6a";
};

export const COLORS: ColorMap = colors as ColorMap;
