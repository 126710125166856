import { IBrand } from "./brand";
import { ICampaignItem } from "./campaignItem";
import { MediaFile } from "./mediaFile";
import { AdvertiserPermissionTypes } from "./permissions";

export type ICampaignStatsByUUID = {
  [campaignUUID: string]: {
    totalDownloads: number;
    downloadsByShowUUID: { [ShowUUID: string]: number };
    totalPaidByAdvertiser: number;
  };
};

export interface ICampaign {
  uuid: string;
  userUUID: string;
  brandName: string;
  name: string;
  goal: string;
  productName: string;
  productDescription: string;
  website?: string;
  totalBudget: number;
  referenceNumber?: string;
  startsAt: number;
  estimatedEndsAt: number;

  // // ContentMediaFileUUID is the audio file for the brand-read ad
  contentMediaFileUUID: string;
  scriptMediaFileUUID?: string;
  script?: string;
  scriptTip?: string;
  style: CampaignStyle;
  state: CampaignState;
  paymentType: CampaignPaymentType;
  csvLastExportedAt: number;
  createdAt: number;
  updatedAt: number;
  targetingOptions: ICampaignTargetingOptions;
  cardID: string;
  productExchangeType: CampaignProductExchangeType;
  productExchangeInstructions?: string;
  redemptionCodes: { [key: string]: string };
  promoCodeType: CampaignPromoCodeType;
  distributionType: CampaignDistributionType;
  needsInvoicing: { [key: string]: boolean };
  targetAges: string[];
  targetGender: Gender;
  requiresEndorsement?: boolean;

  items?: { item: ICampaignItem; mediaFile?: MediaFile }[];
  pacing: boolean;
  recentEpisodesOnly: boolean;

  parentCampaignTagUUIDs?: string[];
  hardEndDate: boolean;

  authorizedPermissionTypes: AdvertiserPermissionTypes[];

  frequencyConfigs: FrequencyCapConfig[];

  archived?: false;

  brandInstanceUUID?: string;
  brand?: IBrand;
  isV2?: boolean;
}

export type FrequencyCapConfig = {
  interval: "day" | "week" | "month";
  maxCount: number;
};

export enum CampaignStyle {
  CampaignStyleCampaignAudio = "campaignAudio", // No longer being used, kept for backward compatibility
  CampaignStyleHostReadAudio = "hostRead",
}

export enum CampaignState {
  CampaignStateCreated = "created",
  CampaignStateInProgress = "inprogress",
  CampaignStateCompleted = "completed",
}
export enum CampaignPaymentType {
  CampaignPaymentTypeCreditCard = "credit_card",
  CampaignPaymentTypeInvoicing = "invoicing",
}
export interface ICampaignTargetingOptions {
  preRoll: boolean;
  midRoll: boolean;
  postRoll: boolean;
}

export enum CampaignProductExchangeType {
  ProductExchangeTypeNone = "none",
  ProductExchangeTypeInstructions = "instructions",
  ProductExchangeTypeMailing = "mailing",
}

export enum CampaignPromoCodeType {
  PromoCodeTypePodcasterUnique = "podcaster_unique",
  PromoCodeTypeStandard = "standard",
  PromoCodeTypeNone = "none",
}

export enum CampaignDistributionType {
  DistributionTypeContinuous = "continuous",
  DistributionTypeNonContinuous = "non_continuous",
  DistributionTypeDiscrete = "discrete",
}
enum Gender {
  AllGender = "all",
  FemaleGender = "female",
  MaleGender = "male",
  Unknown = "unknown",
  Nonbinary = "nonBinary",
}

/**
 * Type guard to check if an unknown value is a valid campaign object,
 * re enforces the Campaign type post check
 *
 * @param value
 * @returns Boolean
 */
export function isCampaign(value?: unknown): value is ICampaign {
  if (
    typeof value === "object" &&
    value !== null &&
    "uuid" in value &&
    "userUUID" in value &&
    "state" in value &&
    "style" in value &&
    "productExchangeType" in value &&
    "targetingOptions" in value
  ) {
    return (
      !!value?.targetingOptions &&
      (value.style === CampaignStyle.CampaignStyleHostReadAudio ||
        value.style === CampaignStyle.CampaignStyleCampaignAudio)
    );
  }

  return false;
}

export interface ICampaignTag {
  createdAt: number;
  updatedAt: number;
  title: string;
  userUUID: string;
  uuid: string;
  parentCampaignTagUUIDs?: string[];
  numChildren?: number; // Only used in the UI
}

/**
 * Type guard to check if an array of unknown values is a valid array of campaign objects,
 * re enforces the Campaign type post check
 *
 * @param value
 * @returns Boolean
 */
export function isArrayOfCampaigns(value?: Array<unknown>): value is ICampaign[] {
  if (
    Array.isArray(value) &&
    value.length > 0 &&
    value !== null &&
    value.every((val) => isCampaign(val))
  ) {
    return true;
  }

  return false;
}

/**
 * Type guard to check if an unknown value is a valid campaignTag object,
 * re enforces the Campaign type post check
 *
 * @param value
 * @returns Boolean
 */
export function isCampaignTag(value?: unknown): value is ICampaignTag {
  if (
    typeof value === "object" &&
    value !== null &&
    "uuid" in value &&
    "userUUID" in value &&
    "title" in value &&
    "createdAt" in value &&
    "updatedAt" in value
  ) {
    return true;
  }

  return false;
}

export type ICampaignOrCampaignTag = ICampaignTag | ICampaign;

/**
 * Type guard to check if an array of unknown values is a valid array of campaign/campaignTag objects,
 * re enforces the Campaign type post check
 *
 * @param value
 * @returns Boolean
 */
export function isArrayOfCampaignsOrCampaignTags(
  value?: Array<unknown>
): value is ICampaignOrCampaignTag[] {
  if (
    Array.isArray(value) &&
    value.length > 0 &&
    value !== null &&
    value.every((val) => isCampaign(val) || isCampaignTag(val))
  ) {
    return true;
  }

  return false;
}
