import { CampaignPromoCodeType } from "src/reducers/campaigns/types";
import { defaultActionManagerRunner } from "./default_action_manager";
import { Script } from "src/reducers/scripts";
import { ICampaignItem } from "src/reducers/campaign_items";

export const CREATE_SCRIPT = "CREATE_SCRIPT";
export const GET_SCRIPT = "GET_SCRIPT";
export const UPDATE_SCRIPT = "UPDATE_SCRIPT";
export const DELETE_SCRIPT = "DELETE_SCRIPT";

export const CREATE_MULTIPLE_SCRIPTS = "CREATE_MULTIPLE_SCRIPTS";
export const UPDATE_MULTIPLE_SCRIPTS = "UPDATE_MULTIPLE_SCRIPTS";

export const GET_SCRIPTS_FOR_CAMPAIGN = "GET_SCRIPTS_FOR_CAMPAIGN";

type ScriptCreateRequest = {
  content?: string;
  readerGuidance?: string;
  promoCodeType?: CampaignPromoCodeType;
  promoCode?: string;
  scriptName?: string;
};

type ScriptUpdateRequest = {
  scriptUUID: string;
} & ScriptCreateRequest;

export const fetchScript = (scriptUUID: string) =>
  defaultActionManagerRunner({
    route: `scripts/${scriptUUID}`,
    auth: true,
    actionName: GET_SCRIPT,
    method: "GET",
  });

export const createScript = (props: ScriptCreateRequest) =>
  defaultActionManagerRunner({
    route: `scripts`,
    auth: true,
    actionName: CREATE_SCRIPT,
    method: "POST",
    body: {
      ...props,
    },
  });

export const updateScript = ({ scriptUUID, ...rest }: ScriptUpdateRequest) =>
  defaultActionManagerRunner({
    route: `scripts/${scriptUUID}`,
    auth: true,
    actionName: UPDATE_SCRIPT,
    method: "PUT",
    body: {
      ...rest,
    },
  });

export const deleteScript = (scriptUUID: string) =>
  defaultActionManagerRunner({
    route: `scripts/${scriptUUID}`,
    auth: true,
    actionName: DELETE_SCRIPT,
    method: "DELETE",
  });

export const getScriptsForCampaign = (campaignUUID: string) =>
  defaultActionManagerRunner({
    route: `campaigns/${campaignUUID}/scripts`,
    auth: true,
    method: "GET",
    actionName: GET_SCRIPTS_FOR_CAMPAIGN,
  });

export type CreateMultipleScriptsRequest = ScriptCreateRequest[];

export type CreateMultipleScriptsResponse = {
  successes: Script[];
  failures: { error: string; request: ScriptCreateRequest }[] | null;
};

export const createMultipleScripts = (
  campaignUUID: string,
  newScripts: CreateMultipleScriptsRequest
) => {
  return defaultActionManagerRunner<CreateMultipleScriptsResponse, CreateMultipleScriptsRequest>({
    route: `campaigns/${campaignUUID}/create-scripts`,
    auth: true,
    method: "POST",
    actionName: CREATE_MULTIPLE_SCRIPTS,
    body: newScripts,
  });
};

export type UpdateMultipleScriptsRequest = {
  [scriptUUID: string]: ScriptUpdateRequest;
};

export type UpdateMultipleScriptsResponse = {
  successes: Script[];
  failures: { error: string; uuid: string }[] | null;
};

export const updateMultipleScripts = (
  campaignUUID: string,
  newScriptUpdates: UpdateMultipleScriptsRequest
) => {
  return defaultActionManagerRunner<UpdateMultipleScriptsResponse, UpdateMultipleScriptsRequest>({
    route: `campaigns/${campaignUUID}/update-scripts`,
    auth: true,
    method: "POST",
    actionName: UPDATE_MULTIPLE_SCRIPTS,
    body: newScriptUpdates,
  });
};

export type AssignScriptRequest = {
  [campaignItemUUID: string]: {
    scriptUUID: string;
    promoCode: string;
  };
};

export type AssignScriptResponse = ICampaignItem[];
