import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { Component } from "react";
import Dimensions from "react-dimensions";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { colors, fonts } from "../../../lib/design";
import KnownAgentFriendlyNames from "../../../lib/known_agent_friendly";
import { humanizeNumber } from "../../../lib/numbers";
import { statsToVictoryFormat } from "./utils";

class OSGraph extends Component {
  componentDidMount() {
    this.props.fetchStats(
      {
        isUnique: true,
        [this.props.uuidType]: this.props.uuid,
        timeRange: "allTime",
        bucketTerms: "download.metadata.os",
      },
      this.props.user,
      this.statsRequestID()
    );
  }

  statsRequestID = () => {
    return this.props.uuid + "os-graph";
  };

  getData = () => {
    var stats = statsToVictoryFormat(this.props.stats, this.statsRequestID());
    return stats.map((item) => {
      return {
        ...item,
        label: humanizeNumber(item.y),
        rightTick: Math.round((item.y / item.total) * 100) + "%",
      };
    });
  };

  render() {
    var data = this.getData();
    if (isEmpty(data) || isNil(data)) {
      return null;
    }
    return (
      <VictoryChart
        containerComponent={<VictoryVoronoiContainer />}
        height={this.props.containerHeight}
        width={this.props.containerWidth}
        padding={{
          top: 30,
          bottom: 30,
          left: 100,
          right: 50,
        }}>
        <VictoryBar
          data={(function (data) {
            var max = Math.max(...data.map((item) => item.y));
            return data.map((item) => {
              return { x: item.x, y: max };
            });
          })(data)}
          horizontal
          style={{
            data: {
              fill: colors.black,
              opacity: "0.05",
              width: 4,
            },
          }}
        />
        <VictoryBar
          data={data}
          horizontal
          style={{
            data: {
              fill: colors.mediumBlue,
              width: 4,
            },
          }}
          barWidth={4}
          labelComponent={
            <VictoryTooltip
              orientation="top"
              dy={10}
              dx={-20}
              cornerRadius={4}
              style={{
                fontFamily: fonts.bold,
                fill: colors.black,
              }}
              flyoutStyle={{
                fill: colors.white,
              }}
              pointerLength={5}
            />
          }
        />
        <VictoryAxis
          style={{
            axis: { stroke: "none", fontFamily: fonts.bold },
            grid: { stroke: "none" },
            tickLabels: { stroke: "none", fille: "none" },
          }}
          tickFormat={() => ""}
        />
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: "none", fontFamily: fonts.bold },
            grid: { stroke: "none" },
            tickLabels: {
              fontSize: 15,
              padding: 5,
              fontFamily: fonts.bold,
            },
          }}
          tickLabelComponent={<VictoryLabel textAnchor="start" x={0} />}
          tickFormat={(tick) => KnownAgentFriendlyNames.get("os", tick)}
        />
        <VictoryAxis
          dependentAxis
          orientation="right"
          style={{
            axis: { stroke: "none", fontFamily: fonts.bold },
            grid: { stroke: "none" },
            tickLabels: {
              fontSize: 15,
              padding: 5,
              fontFamily: fonts.bold,
            },
          }}
          tickFormat={(label, index, allLabels) => {
            return get(this.getData(), [index, "rightTick"]);
          }}
          tickLabelComponent={<VictoryLabel textAnchor="middle" dx={70} />}
        />
      </VictoryChart>
    );
  }
}

export default Dimensions()(OSGraph);
