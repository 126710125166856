import Tooltip from "antd/es/tooltip";
import React from "react";
import { classNames } from "react-extras";
import { AiFillInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import Button from "src/components/lib/button";
import RightChevron from "src/icons/forward-chevron.svg";
import RedDot from "../red_dot/red_dot";
import styles from "./show_page_nav.module.scss";

interface ShowPageNavProps {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  linkTo?: string;
  linkState?: any;
  isAlerted?: boolean;
  tooltip?: string;
  disabledTooltip?: string;
  className?: string;
}

export default function ShowPageNav({
  linkTo,
  linkState,
  disabled,
  disabledTooltip,
  onClick,
  ...rest
}: ShowPageNavProps) {
  /**
   * All <Link> tags are meant to be used for internal navigation only,
   * starting in React Router v6 will expand to both internal and external nav.
   *
   * Mimicking rule, of internal navigation for urls starting with '/'
   */
  const isInternalNavigation = linkTo?.trim()?.startsWith("/");

  if (disabled) {
    return (
      <Tooltip title={disabledTooltip}>
        <div>
          <ShowPageNavItem disabled={true} {...rest} />
        </div>
      </Tooltip>
    );
  }

  if (linkTo) {
    if (isInternalNavigation) {
      return (
        <Link to={{ pathname: linkTo, state: linkState }} onClick={onClick} className={styles.link}>
          <ShowPageNavItem {...rest} />
        </Link>
      );
    }

    return (
      <a href={linkTo} onClick={onClick} rel="noopener" className={styles.link}>
        <ShowPageNavItem {...rest} />
      </a>
    );
  }

  if (onClick) {
    return (
      <Button type="link" onClick={onClick} className="p-a0 width-100" style={{ height: "auto" }}>
        <ShowPageNavItem className="width-100" {...rest} />
      </Button>
    );
  }

  return <ShowPageNavItem {...rest} />;
}

const ShowPageNavItem = ({
  title,
  subtitle,
  disabled,
  tooltip,
  isAlerted,
  className,
}: ShowPageNavProps) => {
  return (
    <div
      className={classNames(
        styles.nav,
        className,
        disabled && styles.disabled,
        "show-page-nav-item m-bxs",
        !subtitle && styles["no-min-height"]
      )}>
      <div className="flex-row-container align-center">
        <h3 className="m-b0">{title}</h3>
        {isAlerted && <RedDot size={8} className="m-bxs m-lxxxs" />}
        {tooltip && (
          <Tooltip title={tooltip}>
            <AiFillInfoCircle className={classNames("m-lxxs", disabled && styles.disabled)} />
          </Tooltip>
        )}
      </div>

      {subtitle && <p>{subtitle}</p>}
      {!disabled && <img src={RightChevron} alt="right-chevron" />}
    </div>
  );
};
