import { Col, Row } from "antd";
import sum from "lodash/sum";
import React from "react";
import HighlightBox from "src/components/lib/highlight_box";
import { useSelectorTS } from "src/hooks/redux-ts";
import { formatMoney } from "src/lib/format-money";
import { humanizeNumber } from "src/lib/numbers";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";

interface IProps {
  className?: string;
  campaign?: ICampaign;
  campaignStats?: any;
  allAcceptedItems?: ICampaignItem[];
  renderDownloadLink?: () => JSX.Element | null;
}

export default function CampaignMetaData({
  campaign,
  campaignStats,
  allAcceptedItems = [],
  renderDownloadLink,
  className,
}: IProps) {
  const isLoading = useSelectorTS((state) => state.campaigns.statsIsLoading);
  const totalDownloads = campaignStats?.totalDownloads ?? 0;

  const totalBudget = sum(allAcceptedItems.map((item: ICampaignItem) => item.totalBudget));

  return (
    <Row gutter={[16, 16]} className={className}>
      <Col xs={12} md={6}>
        <HighlightBox
          className="bordered"
          title="total budget"
          value={formatMoney(totalBudget)}
          isLoading={isLoading}
        />
      </Col>
      <Col xs={12} md={6}>
        <HighlightBox
          className="bordered"
          title="total paid"
          isLoading={isLoading || !campaignStats}
          value={formatMoney(campaignStats?.totalPaidByAdvertiser)}
        />
      </Col>
      <Col xs={12} md={6}>
        <HighlightBox
          className="bordered"
          title="total podcasts"
          value={allAcceptedItems.length}
          isLoading={isLoading}
        />
      </Col>
      <Col xs={12} md={6}>
        <HighlightBox
          className="bordered"
          title={
            <span className="flex-row-container align-center justify-center">
              Total Downloads
              {renderDownloadLink && renderDownloadLink()}
            </span>
          }
          value={humanizeNumber(totalDownloads)}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
}
