import { InputNumber, Switch, Tooltip } from "antd";
import { AiFillQuestionCircle } from "react-icons/ai";
import { FrequencyCapConfig, ICampaign } from "src/reducers/campaigns/types";

const intervalOrderValue: Record<FrequencyCapConfig["interval"], number> = {
  day: 0,
  week: 1,
  month: 2,
};

const allowedIntervals: FrequencyCapConfig["interval"][] = ["week", "month"];

interface IFrequencyCapOptions {
  frequencyConfigs?: ICampaign["frequencyConfigs"];
  onFrequencyCapChange: (newConfigs: ICampaign["frequencyConfigs"]) => void;
}

/**
 * Helper func to check new config values, removes all incorrect config values
 * also enforces order of array interval values
 * @param param0
 * @returns
 */
const cleanConfigs = (newConfigs: ICampaign["frequencyConfigs"]) => {
  return newConfigs
    .reduce(
      (checkedConfigs, curr) => {
        // Checked for correct time interval
        if (!allowedIntervals.includes(curr.interval)) return checkedConfigs;

        // checks for maxCount greater than zero
        if (curr.maxCount <= 0) return checkedConfigs;

        checkedConfigs.push({
          ...curr,
        });

        return checkedConfigs;
      },
      [] as ICampaign["frequencyConfigs"]
    )
    .sort(({ interval: aInterval }, { interval: bInterval }) => {
      return intervalOrderValue[aInterval] - intervalOrderValue[bInterval];
    });
};

export const FrequencyCapOptions = (props: IFrequencyCapOptions) => {
  const { frequencyConfigs = [], onFrequencyCapChange } = props;
  const values = frequencyConfigs?.reduce(
    (accu, curr) => {
      if (curr?.interval) {
        accu[curr.interval] = curr.maxCount ?? 0;
      }

      return accu;
    },
    {} as Partial<Record<FrequencyCapConfig["interval"], number>>
  );

  /**
   * Handles Frequency cap changes for individual inputs
   * @param interval
   * @param newMaxCount
   */
  const handleFreqConfigChange = (
    interval: FrequencyCapConfig["interval"],
    newMaxCount: number | null
  ) => {
    const newConfigs = Array.isArray(frequencyConfigs) ? [...frequencyConfigs] : [];

    const index = newConfigs.findIndex((item) => item.interval === interval);

    // Adds new value
    if (index >= 0) {
      newConfigs[index] = {
        interval,
        maxCount: newMaxCount ?? 0,
      };
    } else {
      newConfigs.push({
        interval,
        maxCount: newMaxCount ?? 0,
      });
    }

    //checks final config values
    const finalNewConfigs = cleanConfigs(newConfigs);

    onFrequencyCapChange(finalNewConfigs);
  };

  /**
   * Handles enabling specific interval frequency configs
   */
  const handleToggleChange = (interval: FrequencyCapConfig["interval"]) => (flag: boolean) => {
    // Add interval config , default to 1, in order to enable toggle
    if (flag) {
      if (!frequencyConfigs?.some((config) => config.interval === interval)) {
        const newConfigs = [...frequencyConfigs];
        newConfigs.push({
          interval,
          maxCount: 1,
        });
        onFrequencyCapChange(cleanConfigs(newConfigs));
      }
    }
    // remove interval config , in order to disable toggle
    else {
      if (frequencyConfigs?.some((config) => config.interval === interval)) {
        const newConfigs = [...frequencyConfigs];
        const index = newConfigs.findIndex((config) => config.interval === interval);
        newConfigs.splice(index, 1);
        onFrequencyCapChange(cleanConfigs(newConfigs));
      }
    }
  };

  // toggle state will be controlled by existence of corresponding frequency config in form state
  const enableWeek = frequencyConfigs?.some((config) => config.interval === "week");
  const enableMonth = frequencyConfigs?.some((config) => config.interval === "month");

  return (
    <>
      <div className="flex-row-container align-center m-bxxs">
        <span className="redcircle-form-label m-b0">Frequency Capping</span>
        <Tooltip title="Limit the amount of times a listener can hear your campaign">
          <AiFillQuestionCircle className="m-lxxs" />
        </Tooltip>
      </div>

      <div className="flex-row-container flex-wrap">
        <div className="flex-column-container align-start m-rxs">
          <Switch onChange={handleToggleChange("week")} checked={enableWeek} className="m-bxxxs" />
          <InputNumber
            value={values?.week}
            min={1}
            onChange={(val) => handleFreqConfigChange("week", val)}
            disabled={!enableWeek}
            className="m-bxxxs"
          />
          <p className="redcircle-form-label m-b0"> per week </p>
        </div>
        <div className="flex-column-container align-start">
          <Switch
            onChange={handleToggleChange("month")}
            checked={enableMonth}
            className="m-bxxxs"
          />
          <InputNumber
            min={1}
            value={values?.month}
            onChange={(val) => handleFreqConfigChange("month", val)}
            disabled={!enableMonth}
            className="m-bxxxs"
          />
          <p className="redcircle-form-label m-b0"> per month </p>
        </div>
      </div>
    </>
  );
};
