import { Col, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { Modal } from "redcircle-ui";
import { hideModal } from "src/actions/modal";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { CAMPAIGN_ITEM_UNPAUSE_MODAL } from "../modal_root";
import { usePublicShow } from "src/hooks/shows";
import { truncate } from "lodash";
import { unpauseCampaignItem } from "src/action_managers/campaigns";
import { showError, showSuccess } from "src/actions/app";

interface ICampaignItemUnPauseModal {
  showUUID: string;
  campaignItemUUID: string;
}

/**
 * Displays Campaign Item Unpause Modal
 */
const CampaignItemUnPauseModal = ({ showUUID, campaignItemUUID }: ICampaignItemUnPauseModal) => {
  const dispatch = useDispatchTS();
  const [open, setOpen] = useState(true); // Used for better onClose modal animation control
  const modal = useSelectorTS((state) => state.modal);

  const openModalFlag = modal.modalType === CAMPAIGN_ITEM_UNPAUSE_MODAL;

  const { show } = usePublicShow(showUUID);

  /**
   * Sync Modal open flag with app redux modal state, only on open
   */
  useEffect(() => {
    if (openModalFlag) {
      setOpen(openModalFlag);
    }
  }, [openModalFlag]);

  /**
   * Manually close the modal
   */
  const onClose = () => {
    setOpen(false);
  };

  /**
   * On close animation complete, remove redux modal state
   */
  const afterClose = () => {
    dispatch(hideModal());
  };

  const handleSubmit = () => {
    dispatch(unpauseCampaignItem({ campaignItemUUID }))
      .then((resp) => {
        switch (resp.status) {
          case 200:
            dispatch(showSuccess("Podcast Ad unpaused"));
            break;
          case 400:
            dispatch(showError(resp?.json?.message ?? ""));
            break;
          default:
            dispatch(
              showError(
                "Could not unpause the Ad. Please try again, if the problem persists contact us at RedCircle support."
              )
            );
        }

        onClose();
      })
      .catch((err) => {
        dispatch(
          showError(
            "Could not unpause the Ad. Please try again, if the problem persists contact us at RedCircle support."
          )
        );
      });
  };

  return (
    <Modal size="sm" open={open} onClose={onClose} onSubmit={handleSubmit} afterClose={afterClose}>
      <Modal.Title>Unpausing Ad on {truncate(show?.title ?? "", { length: 40 })}</Modal.Title>
      <Modal.Body>
        <Row>
          <Col>
            <p>Are you sure you want to unpause this Ad? The Ad will begin running again.</p>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer noBorder>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        <Modal.SubmitButton>Unpause</Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CampaignItemUnPauseModal;
