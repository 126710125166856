import dayjs from "dayjs";
import moment from "moment";
import {
  CampaignItemStateAwaitingAudio,
  CampaignItemStateDeclined,
  CampaignItemStateDraft,
  CampaignItemStateExpired,
  CampaignItemStateNeedsScript,
  CampaignItemStateSent,
  CampaignStateCreated,
} from "src/constants/campaigns";
import { getEstimatedEndDateForCampaignItem } from "src/lib/campaigns";
import { getCampaignItemField } from "src/lib/campaign_item";
import { BudgetsReduxState } from "src/reducers/budgets";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { PublicShow, PublicShowsReduxState } from "src/reducers/public_show";
import { IShow } from "src/reducers/shows";
import { IShowMap } from "src/reducers/shows/showReducer";

export const getDaysLeftAndClockTimeString = (unixTimeStamp: number) => {
  const respondByAt = moment.unix(unixTimeStamp).utc();
  const currentAt = moment();
  const daysLeft = Math.max(Math.round(respondByAt.diff(currentAt, "days", true)), 0);
  const clockTimeLocal = respondByAt.local().format("hh:mm A");
  return { daysLeft, clockTimeLocal };
};

const generateDaysLeftText = (daysLeft: number) => {
  if (daysLeft > 1) return `${daysLeft} days`;
  if (daysLeft === 1) return "1 day";
  return "less than a day";
};

export const getCampaignItemContextText = (campaignItem: ICampaignItem) => {
  if (campaignItem.state === CampaignItemStateSent && campaignItem.respondByAt) {
    const { daysLeft } = getDaysLeftAndClockTimeString(campaignItem.respondByAt);
    return `Has ${generateDaysLeftText(daysLeft)} to respond`;
  }

  if (campaignItem.state === CampaignItemStateDeclined) {
    const date = campaignItem.respondedAt
      ? moment.unix(campaignItem.respondedAt).local().format("l LT")
      : "";
    return `${campaignItem.state} ${date}`;
  }

  if (campaignItem.state === CampaignItemStateExpired) {
    const date = campaignItem.isV2
      ? campaignItem?.lifecycleSettings?.responseTask?.deadline?.value
      : campaignItem.expiredAt;
    const formattedDate = date ? moment.unix(date).local().format("l") : "";
    return `${campaignItem.state} ${formattedDate}`;
  }

  if (campaignItem.state === CampaignItemStateAwaitingAudio && campaignItem.uploadAudioBy) {
    const { daysLeft } = getDaysLeftAndClockTimeString(campaignItem.uploadAudioBy);
    return `Accepted, has ${generateDaysLeftText(daysLeft)} to upload`;
  }

  // PODCASTER ONLY
  if (campaignItem.state === CampaignItemStateNeedsScript && campaignItem.scriptDueBy) {
    return `Brand will provide script by ${moment.unix(campaignItem.scriptDueBy).format("M/D/YY")}`;
  }
};

/**
 * Formats start and end dates for campaign item, takes into account Pacing campaigns.
 * For Pacing campaign items, the end date is equal to the pacingEstimatedEndDate of the campaign
 * @param campaignItem
 * @param campaign
 * @param show
 * @param formatString
 * @returns formatted date strings
 */
export const formatStartAndEndDates = (
  campaignItem: ICampaignItem,
  campaign: ICampaign | undefined,
  show: IShow | PublicShow | undefined,
  formatString = "ll"
) => {
  if (!campaign || !campaignItem || !show) return "";

  const estimatedEndDate = getEstimatedEndDateForCampaignItem({
    campaign,
    campaignItem,
    show,
    budget: campaignItem.budget,
  });

  const startAt = getCampaignItemField("startAt", { campaignItem, campaign });
  const formattedStartDate = dayjs.unix(startAt).format(formatString);

  const isPacing = getCampaignItemField("pacing", { campaignItem, campaign });

  const unpacedEndAt = estimatedEndDate;
  const formattedUnpacedEndDate = unpacedEndAt && dayjs.unix(unpacedEndAt).format(formatString);

  const pacingEndAt = campaignItem.isV2
    ? campaignItem?.lifecycleSettings?.endAt?.value
    : campaignItem.pacingEstimatedEndAt;
  const formattedPacingEndDate = pacingEndAt && dayjs.unix(pacingEndAt).format(formatString);

  const formattedDates =
    isPacing && typeof pacingEndAt === "number"
      ? `${formattedStartDate} - ${formattedPacingEndDate}`
      : `${formattedStartDate} - ${formattedUnpacedEndDate}`;

  return formattedDates;
};

// TODO: move to backend
export const getCampaignEstimatedDates = (
  shows?: PublicShowsReduxState | IShowMap,
  campaign?: ICampaign,
  campaignItems?: ICampaignItem[],
  budgets?: BudgetsReduxState
) => {
  if (!campaign || !campaignItems) return null;
  if (campaign.state === CampaignStateCreated) return null;

  let earliestStartDate = Infinity;
  let latestEndDate = 0;
  campaignItems.forEach((campaignItem) => {
    if (
      [CampaignItemStateExpired, CampaignItemStateDeclined, CampaignItemStateDraft].includes(
        campaignItem.state
      )
    )
      return;

    const show = shows?.[campaignItem.showUUID];
    const budget = campaignItem?.budgetUUID ? budgets?.[campaignItem?.budgetUUID] : undefined;
    const s = campaignItem.startAt;
    const e = getEstimatedEndDateForCampaignItem({
      campaign,
      campaignItem,
      show,
      budget,
    });
    if (s < earliestStartDate) earliestStartDate = s;
    if (e && e > latestEndDate) latestEndDate = e;
  });

  if (earliestStartDate >= latestEndDate) return null; // something wild happened here
  return { start: earliestStartDate, end: latestEndDate };
};
