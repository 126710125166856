import { Table, TableProps } from "antd";
import classNames from "classnames";
import styles from "./Table.module.scss";

// latest version of our table, using Antd as base and applying styling on it
const RCTable = (props: TableProps) => {
  const { className, ...rest } = props;
  return <Table className={classNames(styles.table, className)} {...rest} />;
};

const SelectedBar = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return <div className={classNames(styles.bar, className)}>{children}</div>;
};

RCTable.SelectedBar = SelectedBar;
export default RCTable;
