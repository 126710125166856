import map from "lodash/map";
import size from "lodash/size";
import sum from "lodash/sum";
import React from "react";
import ProgressCircle from "src/components/lib/progress_circle";
import {
  CampaignItemStateAccepted,
  CampaignItemStateAudioSwapRequested,
  CampaignItemStateAwaitingAudio,
  CampaignItemStateCompleted,
  CampaignItemStateNeedsScript,
  CampaignItemStatePaused,
  CampaignItemStateRunning,
} from "src/constants/campaigns";
import { humanizeNumber } from "src/lib/numbers";
import { ICampaignItem } from "src/reducers/campaign_items";

interface IProps {
  campaignItem: ICampaignItem;
  campaignStats?: any;
}

export default function CampaignProgress({ campaignItem, campaignStats }: IProps) {
  if (!showCampaignProgress(campaignItem)) return null;
  const { text, percent } = getCampaignProgress(campaignItem, campaignStats);
  return <ProgressCircle percent={Math.floor(percent)} text={text} />;
}

export const getCampaignProgress = (campaignItem: ICampaignItem, campaignStats?: any) => {
  const { text, percent } = getContinuousStats(campaignItem, campaignStats);
  return { text, percent };
};

const getContinuousStats = (campaignItem: any, campaignStats?: any) => {
  const currentBudget = campaignItem.budget?.current ?? 0;
  const totalBudget = campaignItem.budget?.total ?? 1;

  // campaignStats are only used on the advertiser side for now
  // currently not passing on podcaster side because we don't want to give podcaster access
  // to performance of other campaign items on a campaign
  const downloadsByShowUUID = campaignStats?.downloadsByShowUUID;
  const downloads = downloadsByShowUUID?.[campaignItem?.show?.uuid];

  let percent = 0;
  if (campaignItem.budget) {
    percent = Math.min((currentBudget / totalBudget) * 100, 100);
  }

  const downloadsText = downloads ? ` | ${humanizeNumber(downloads)} Downloads` : "";

  return {
    text: `${percent.toFixed(2)}% Complete${downloadsText}`,
    percent,
  };
};

export const calculateProgressPercentage = (campaignItem: any) => {
  let percent = 0;
  if (!showCampaignProgress(campaignItem)) return percent;

  const currentBudget = campaignItem.budget?.current ?? 0;
  const totalBudget = campaignItem.budget?.total ?? 1;
  if (campaignItem.budget) {
    percent = Math.min((currentBudget / totalBudget) * 100, 100);
  }

  return percent;
};

export const showCampaignProgress = (campaignItem: ICampaignItem) => {
  // don't show progress on the first audio upload
  return (
    (campaignItem.state === CampaignItemStateAwaitingAudio && !!campaignItem.mediaFileUUID) ||
    [
      CampaignItemStateNeedsScript,
      CampaignItemStateAccepted,
      CampaignItemStateRunning,
      CampaignItemStatePaused,
      CampaignItemStateCompleted,
      CampaignItemStateAudioSwapRequested,
    ].includes(campaignItem.state)
  );
};
