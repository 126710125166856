import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import moment from "moment-timezone";
import type { Moment } from "moment-timezone";

export type UnixTimeStamp = number;

/**
 * Typescript way to deprecate a specific type signature of an overloaded function
 * Will slowly remove moment package usage and replace with native helper functions
 */
interface IConvertDateTimeToString {
  // New Signature
  (input: UnixTimeStamp): string;

  /**
   * @deprecated
   * Use unix time stamp number instead
   */
  (moment: Moment): string;
}

export const localLongDate: (input: UnixTimeStamp) => string = (input) => {
  const date = dayjs.unix(input);

  return date.format("MMMM D, YYYY");
};

export const localDate: IConvertDateTimeToString = (input) => {
  if (moment.isMoment(input)) {
    return input.local().format("M/D/YYYY");
  }

  const date = dayjs.unix(input);

  return date.format("M/D/YYYY");
};

export const localTime: IConvertDateTimeToString = (input) => {
  if (moment.isMoment(input)) {
    return input.local().format("hh:mm A");
  }

  const date = dayjs.unix(input);

  return date.format("hh:mm A");
};

export const localDateAndTime: IConvertDateTimeToString = (input) => {
  if (moment.isMoment(input)) {
    return input.local().format("MM/DD/YY hh:mm A");
  }

  const date = dayjs.unix(input);

  return date.format("MM/DD/YY hh:mm A");
};

export const startOfDay: (unixTimeStamp: UnixTimeStamp) => UnixTimeStamp = (unixTimeStamp) => {
  const date = dayjs.unix(unixTimeStamp);

  return date.startOf("d").unix();
};

export const endOfDay: (unixTimeStamp: UnixTimeStamp) => UnixTimeStamp = (unixTimeStamp) => {
  const date = dayjs.unix(unixTimeStamp);

  return date.endOf("d").unix();
};

export const endOfWeek: (unixTimeStamp: UnixTimeStamp) => UnixTimeStamp = (unixTimeStamp) => {
  const date = dayjs.unix(unixTimeStamp);

  return date.endOf("week").endOf("day").unix();
};

export const endOfMonth: (unixTimeStamp: UnixTimeStamp) => UnixTimeStamp = (unixTimeStamp) => {
  const date = dayjs.unix(unixTimeStamp);

  return date.endOf("month").endOf("day").unix();
};

export const endOfYear: (unixTimeStamp: UnixTimeStamp) => UnixTimeStamp = (unixTimeStamp) => {
  const date = dayjs.unix(unixTimeStamp);

  return date.endOf("year").endOf("month").endOf("day").unix();
};

export const differenceBetween2Dates = ({
  startDate,
  endDate,
  interval = "day",
}: {
  startDate: UnixTimeStamp;
  endDate: UnixTimeStamp;
  interval?: Parameters<Dayjs["diff"]>[1];
}) => {
  const start = dayjs.unix(startDate);
  const end = dayjs.unix(endDate);

  return end.diff(start, interval, true);
};

/**
 * Grabs all the dates (including the start and end date) between start date -end date,
 * in the provided interval. Returns amn array of unix time stamps
 * @param {{startDate: UnixTimeStamp, endDate: UnixTimeStamp,interval: "day" | "week" | "month"}} props
 * @param props.startDate - Start date as unix time stamp
 * @param props.endDate - End date as unix time stamp
 * @param props.interval - Interval between dates, can be one of the following: "day" | "week" | "month"
 * @returns UnixTimeStamp[] - Array of unix time stamps
 */
export const getDatesInBetween = ({
  startDate,
  endDate,
  interval = "day",
}: {
  startDate: UnixTimeStamp;
  endDate: UnixTimeStamp;
  interval?: Extract<Parameters<Dayjs["diff"]>[1], "day" | "week" | "month">;
}) => {
  const result = [];

  if (typeof startDate === "number" && typeof endDate === "number") {
    const start = dayjs.unix(startDate).startOf(interval);
    let current = start.clone();
    const end = dayjs.unix(endDate).startOf(interval);

    while (current.unix() <= end.unix()) {
      result.push(current.unix());
      current = current.add(1, interval);
    }
  }

  return result;
};

/**
 * Helper functions that transforms selected date to EST and then sets it to end of day. checks for daylight savings time.
 * @param {UnixTimeStamp} timeStamp - Unix time stamp of target date
 * @returns {UnixTimeStamp} Returns new unix time stamp as 11:50pm EST of the same date provided
 */
export const cloneDateAs12amEST = (timeStamp: UnixTimeStamp) => {
  const date = dayjs.unix(timeStamp).endOf("day").tz("America/New_York", true);
  return date.unix();
};
