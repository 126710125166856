import { classNames } from "react-extras";
import { FaEllipsisH } from "react-icons/fa";
import { Button } from "redcircle-ui";
import { Dropdown } from "src/components/lib/dropdown";
import styles from "./context_menu.module.scss";

type onSelect = () => void;

type TMenuItem = {
  onSelect: onSelect;
  title?: string;
  disabled?: boolean;
  hide?: boolean;
  toolTipText?: string;
};

export type MenuItems = Record<string, TMenuItem | onSelect>;

interface IProps {
  className?: string;
  noCircle?: boolean;
  text?: string;
  menuItems: MenuItems;
}

export default function ContextMenu({ className, noCircle, text, menuItems }: IProps) {
  return (
    <span className={classNames(className)}>
      <Dropdown menuItems={menuItems}>
        <Button
          className={classNames(styles.button, noCircle && styles["no-circle"])}
          type="link"
          data-testid="context-menu-button">
          {text || <FaEllipsisH />}
        </Button>
      </Dropdown>
    </span>
  );
}
