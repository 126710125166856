export interface IAgeBucket {
  minAge: number;
  maxAge: number;
  label: string;
  percentage: number;
}
export interface IGenderBreakdown {
  female: number;
  male: number;
  unknown: number;
  nonBinary: number;
}
export interface IListenerDemographics {
  genderBreakdown: IGenderBreakdown;
  ageBuckets: IAgeBucket[];
}
export interface IAddress {
  Country: string;
  Name: string;
  Organization: string;
  StreetAddress: string[];
  DependentLocality: string;
  Locality: string;
  AdministrativeArea: string;
  PostCode: string;
  SortingCode: string;
}
export interface IHost {
  fullName: IFullName;
  postalAddress?: IAddress;
}
export interface IFullName {
  firstName: string;
  lastName: string;
}
export interface IAdvertisementSettings {
  isHostReadEnabled: boolean;
  hostReadPreRollCPM: number;
  hostReadMidRollCPM: number;
  hostReadPostRollCPM: number;
  hosts?: IHost[];
  twitterLink: string;
  facebookLink: string;
  instagramLink: string;
  youtubeLink: string;
  tikTokLink: string;

  excludedCategories: string[];
  excludedBrandInstanceUUIDs: string[];
  excludedAdvertiserUUIDs: string[];

  // pre-recorded is deprecated for now, keep for data integrity
  isPreRecordedEnabled: boolean;
  preRecordedPreRollCPM: number;
  preRecordedMidRollCPM: number;
  preRecordedPostRollCPM: number;

  // spot rates are deprecated for now, keep for data integrity
  automaticallyCalculateSpotRates: boolean;
  /**
   * @deprecated The property is deprecated, no longer using discreet campaigns/spot rate
   */
  preRollSpotRate?: number;
  /**
   * @deprecated The property is deprecated, no longer using discreet campaigns/spot rate
   */
  midRollSpotRate?: number;
  /**
   * @deprecated The property is deprecated, no longer using discreet campaigns/spot rate
   */
  postRollSpotRate?: number;
}

export interface IProgrammaticAdSettings {
  enabled: boolean;
  preRollEnabled: boolean;
  midRollEnabled: boolean;
  postRollEnabled: boolean;
  insertionProbability: number;
  excludedCategories: string[]; // deprecated, moved to advertisementSettings
}

export interface IYouTubeAuthToken {
  accessToken: string;
  tokenType: string;
  refreshToken: string;
  expiry: number;
}
export interface ISubscriptionDetails {
  subscriptionsEnabled?: boolean;
  recurringDonationsEnabled?: boolean;
  exclusiveContentEnabled?: boolean;
  shouldPlayAdsForSubscribers?: boolean;
  amountWeekly: number;
  amount: number;
  amountAnnual: number;
  description: string;
}

export interface IRedirectAwayFeedback {
  destination: string;
  reason: string;
}

export interface IVastURL {
  createdAt: number;
  friendlyError: string;
  position: string;
  positionIndex: number;
  url: string;
  verified: boolean;
}

export enum IBucketAudienceSize {
  UnknownAudienceSize = "",
  CalculatingAudienceSize = "calculating",
  EmergingAudienceSize = "0-100",
  TinyAudienceSize = "100-1000",
  SmallAudienceSize = "1000-5000",
  MediumAudienceSize = "5000-20000",
  LargeAudienceSize = "20000+",
}

export interface IShow {
  uuid: string;
  userUUID?: string;
  title?: string;
  link?: string;
  useLinkAsRSSLink: boolean;
  alias?: string;
  description?: string;
  summary?: string;
  subtitle?: string;
  imageMediaFileUUID?: string;
  imageURL: string;
  language?: string;
  copyright?: string;
  isComplete: boolean;
  isVisible: boolean;
  isExplicit: boolean;
  itunesType?: string;
  ownerEmail?: string;
  ownerName?: string;
  categoryUUIDs: string[];
  createdAt: number;
  updatedAt: number;
  importedURL?: string;
  autoImportBitrate: number;
  shouldAutoImport: boolean;
  shouldRedirectNewEpisodes: boolean;
  rssURL: string;
  redirectVerified?: boolean;
  nextRecalculationAt?: number;
  bucketedAudienceSize: IBucketAudienceSize;
  estimatedWeeklyDownloads: number;
  averageEpisodeDuration: number;
  averageEpisodeDownloads: number;
  estimatedEpisodeFrequencyDays: number;
  estimatedEpisodeFrequency?: string;
  canShareAudienceSize: boolean;
  redirectAwayURL?: string;
  softRedirectAwayURL: string;
  redirectAwayFeedback?: IRedirectAwayFeedback;
  redirectedAwayAt: number;
  redirectedAt?: number;
  episodeCountLimit: number;
  subscriptionDetails: ISubscriptionDetails;
  enclosurePrefix?: string;
  stopAutoImportDateAt?: number;
  claimedBy?: string;
  claimedAt?: number;
  youtubeAuthToken?: IYouTubeAuthToken;
  advertisementSettings: IAdvertisementSettings;
  programmaticCutBasisPoints: number;
  postalAddresses?: IAddress[];
  promoCode?: string;
  isHardDeleted: boolean;
  advertisingCutBasisPoints: number;
  isDemo: boolean;
  listenerDemographics?: IListenerDemographics;
  canAutoDistributeToYouTube: boolean;
  programmaticAdSettings?: IProgrammaticAdSettings;
  invitedToRAP?: boolean;
  HideRelatedShows: boolean;
  preexistingTotalDownloads: number;
  isRemote?: boolean;
  vastURLs?: IVastURL[];
}

/**
 * Type guard to check if an unknown value is a valid Show object,
 * re enforces the Campaign type post check
 *
 * @param value
 * @returns Boolean
 */
export function isShow(value?: unknown): value is IShow {
  if (
    typeof value === "object" &&
    value !== null &&
    "uuid" in value &&
    "userUUID" in value &&
    "title" in value &&
    "rssURL" in value &&
    "isVisible" in value &&
    typeof value.uuid === "string" &&
    typeof value.title === "string" &&
    typeof value.rssURL === "string" &&
    typeof value.isVisible === "boolean"
  ) {
    return value.uuid?.length > 1 && value.rssURL?.length > 1 && value.title?.length > 1;
  }

  return false;
}

/**
 * Type guard to check if an unknown array of value is a valid Array of Show Objects,
 *
 * @param value
 * @returns Boolean
 */
export function isArrayOfShows(value?: Array<unknown>): value is IShow[] {
  if (
    Array.isArray(value) &&
    value.length > 0 &&
    value !== null &&
    value.every((val) => isShow(val))
  ) {
    return true;
  }

  return false;
}
