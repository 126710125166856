import { Alert, Tooltip } from "antd";
import { useMemo } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { formatMoney } from "redcircle-lib";
import { COLORS } from "redcircle-ui";
import { useGetCampaignItemReforecast } from "src/hooks/campaign_items";
import { useSelectorTS } from "src/hooks/redux-ts";
import { getAverageCPM } from "src/lib/campaigns";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";

export default function ReforecastAlert({
  campaign,
  campaignItems,
}: {
  campaign?: ICampaign;
  campaignItems: Record<string, ICampaignItem>;
}) {
  const campaignItemUUIDs = useMemo(
    () => campaignItems && Object.values(campaignItems).map((item) => item.uuid),
    [campaignItems]
  );

  const publicShows = useSelectorTS((state) => state.publicShows);
  const reforecastData = useGetCampaignItemReforecast(campaignItemUUIDs);

  const data = useMemo(() => {
    if (!reforecastData) return null;

    return Object.keys(reforecastData)
      .map((uuid) => {
        const campaignItem = campaignItems[uuid];
        const show = campaignItem && publicShows[campaignItem.showUUID];
        const cpm = campaignItem && show && getAverageCPM({ campaign, campaignItem, show });
        if (!campaignItem || !show || !cpm) return null;

        const impressionsByPosition = reforecastData?.[uuid]?.impressionsByPosition ?? {};
        const totalImpressions = Object.values(impressionsByPosition)?.reduce(
          (a: number, c: number) => a + c,
          0
        ) as number;

        const totalBudget = campaignItem.totalBudget / 100;
        const totalUsage = (totalImpressions * cpm) / 1000;
        return {
          uuid,
          show,
          totalUsage,
          totalBudget,
          percentage: (totalUsage / totalBudget) * 100,
        };
      })
      .filter((x) => x);
  }, [reforecastData, publicShows, campaign, campaignItems]);

  const maxPercentage = data && Math.max(...data.map((x) => x?.percentage || 0));
  const warn = maxPercentage && maxPercentage > 90;
  const error = maxPercentage && maxPercentage > 100;

  return (
    <Alert
      message={
        <>
          <h5>Usage:</h5>
          {data && data.length > 1 && (
            <div className="flex-row-container align-center">
              <span
                className="m-rxxxs"
                style={{
                  color: error ? COLORS.COLOR_ERROR : warn ? COLORS.COLOR_WARNING : "inherit",
                }}>
                Max Usage: {maxPercentage?.toFixed(0)}%
              </span>{" "}
              <Tooltip title="The highest percentage of the spend vs max spend out of all selected campaign items">
                <AiFillInfoCircle />
              </Tooltip>
            </div>
          )}
          {data && (
            <ul className="list-style-none p-l0 m-b0">
              {data.map((values) => {
                if (!values) return null;
                const { uuid, show, totalUsage, totalBudget } = values;
                return (
                  <li key={uuid} className="flex-row-container align-center">
                    <div className="ellipsis" style={{ maxWidth: "50%" }}>
                      <strong>{show.title}</strong>
                    </div>
                    <span>
                      : {formatMoney(totalUsage)} / {formatMoney(totalBudget)}
                    </span>
                  </li>
                );
              })}
            </ul>
          )}
        </>
      }
      type="warning"
      className="m-txs"
    />
  );
}
