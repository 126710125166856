import { Col, Row, Tabs } from "antd";
import { intersection } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { showWarning } from "src/actions/app";
import { getShowCampaign } from "src/actions/campaigns";
import { showModal } from "src/actions/modal";
import { getScriptsForCampaign } from "src/action_managers/script";
import { AlbumArt } from "src/components/lib/album_art";
import Breadcrumbs from "src/components/lib/breadcrumbs";
import Button from "src/components/lib/button";
import ExclusiveTag from "src/components/lib/exclusive_tag";
import Loading from "src/components/lib/loading";
import Page from "src/components/lib/page";
import RedDot from "src/components/lib/red_dot/red_dot";
import SwapAudioModal from "src/components/modals/campaign_audio_swap/swap_audio_modal";
import { DECLINE_CAMPAIGN_SURVEY } from "src/components/modals/modal_root";
import AcceptCampaignOfferModal from "src/components/pages/shows/accept_campaign_offer_modal";
import { AdDetailsSteps } from "src/components/pages/shows/ad_details_step";
import ProductInstructionsInfo from "src/components/pages/shows/product_instructions_info";
import {
  CampaignItemStateAccepted,
  CampaignItemStateAudioSwapRequested,
  CampaignItemStateAwaitingAudio,
  CampaignItemStateCompleted,
  CampaignItemStateDeclined,
  CampaignItemStateExpired,
  CampaignItemStateNeedsScript,
  CampaignItemStatePaused,
  CampaignItemStateRunning,
  CampaignItemStateSent,
  CampaignItemStateToFriendlyPodcaster,
} from "src/constants/campaigns";
import { permissionTypes } from "src/constants/permission_roles";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { isAudioSwapRequested, processCampaignItemState } from "src/lib/campaigns";
import { useCanAccess } from "src/lib/permissions";
import { isShowRedirected } from "src/lib/show";
import { ICampaignItem } from "src/reducers/campaign_items";
import ReviewAndUploadAudioModal from "../../shows/review_and_upload_audio_modal";
import ShowCampaignMedia from "./show_campaign_media";
import ShowCampaignMetadata from "./show_campaign_metadata";
import "./show_campaign_page.scss";
import ShowCampaignPageDetails from "./show_campaign_page_details";
import { AudioSwapWarningBanner, NextStepsBanner } from "./show_campaign_page_warnings";

const CAMPAIGN_ITEM_STATES_POST_ACCEPT = [
  CampaignItemStateAccepted,
  CampaignItemStateRunning,
  CampaignItemStatePaused,
  CampaignItemStateNeedsScript,
  CampaignItemStateAudioSwapRequested,
  CampaignItemStateAwaitingAudio,
  CampaignItemStateCompleted,
];

export default function ShowCampaignPage() {
  const { campaignUUID, showUUID } = useParams<{ campaignUUID: string; showUUID: string }>();
  const dispatch = useReduxDispatch();
  const history = useHistory();

  const [showAcceptModal, setAcceptModal] = useState(false);
  const [showAwaitingModal, setAwaitingModal] = useState(false);
  const [showAudioSwapModal, setAudioSwapModal] = useState(false);

  const user = useSelectorTS((state) => state.user.user || {});
  const show = useSelectorTS((state) => state.shows.shows[showUUID] || {});
  const initialCampaign = useSelectorTS((state) => state.campaigns.campaigns[campaignUUID] || {});
  const [campaign, setCampaign] = useState(initialCampaign);
  const [campaignItem, setCampaignItem]: [ICampaignItem | undefined, any] = useState();
  const [isLoading, setLoading] = useState(true);

  // TODO: Need to refactor better way to use react-redux shallow equality function to save re-render cycles in this page
  // with camapignItem fetch/updates, instead of using a local state camopaignItem copy
  const currentCampaignItemUpdated = useSelectorTS(
    (state) => {
      const { campaignItemByCampaignUUID, campaignItems, showCampaignItemUUIDs } =
        state?.campaignItems;

      const campaignItemUUID = intersection(
        campaignItemByCampaignUUID[campaignUUID],
        showCampaignItemUUIDs[showUUID]
      )[0];

      const currentCampaignItem = campaignItems?.[campaignItemUUID];

      return currentCampaignItem;
    },
    (prev, current) => prev?.state === current?.state
  );

  useEffect(() => {
    currentCampaignItemUpdated &&
      setCampaignItem(processCampaignItemState(currentCampaignItemUpdated));
  }, [currentCampaignItemUpdated?.state]);

  // data fetch to make campaign item load faster
  useEffect(() => {
    dispatch(getShowCampaign(showUUID, campaignUUID)).then((res) => {
      if (res.status === 200) {
        setCampaignItem(processCampaignItemState(res.json?.campaignItem || {}));
        setCampaign(res.json?.campaign || {});
      }
      setLoading(false);
    });
    dispatch(getScriptsForCampaign(campaignUUID));
  }, []);

  // block expired and declined campaigns
  useEffect(() => {
    if (!campaignItem) return;
    if (campaignItem.state === CampaignItemStateExpired) {
      dispatch(
        showWarning(
          "Unfortunately, the response time for this campaign has expired and your show is no longer eligible to participate."
        )
      );
      history.push(`/shows/${showUUID}/advertising`);
    }
    if (campaignItem.state === CampaignItemStateDeclined) {
      dispatch(
        showWarning(
          "Unfortunately, you previously declined this offer and your show is no longer eligible to participate in this campaign."
        )
      );
      history.push(`/shows/${showUUID}/advertising`);
    }
  }, [campaignItem?.state]);

  const canEditRap = useCanAccess(permissionTypes.editRap, showUUID);

  // Audio Swap Requests
  const isPodcasterViewing = user.uuid === campaignItem?.creatorUUID;
  const { isAudioSwapActive, isPodcasterInitiated } = isAudioSwapRequested(campaignItem);
  const showAudioSwapWarning = isPodcasterViewing && isAudioSwapActive && !isPodcasterInitiated;
  const showAudioAlert =
    (campaignItem && campaignItem.state === CampaignItemStateAwaitingAudio) || showAudioSwapWarning;

  return (
    <Page className="show-campaign-page">
      <Breadcrumbs
        crumbs={[
          { path: "/ad-platform", name: "All Podcasts" },
          { path: `/ad-platform/${showUUID}`, name: show?.title },
          {
            path: `/ad-platform/${showUUID}/campaigns/${campaignUUID}`,
            name: campaign?.name,
            active: true,
          },
        ]}
      />

      {/* WARNINGS */}
      {campaignItem && (
        <ProductInstructionsInfo
          campaignItem={campaignItem}
          campaign={campaign}
          additionalContent={
            <div className={"redcircle-form-label m-ts"}>
              ONCE YOU RECEIVE THE PRODUCT, UPLOAD YOUR HOST READ AD AUDIO BELOW.
            </div>
          }
        />
      )}
      {!showAudioSwapWarning && <NextStepsBanner campaign={campaign} campaignItem={campaignItem} />}
      {showAudioSwapWarning && <AudioSwapWarningBanner />}

      <Page.Header className="flex-row-container align-center justify-space-between flex-wrap m-bs">
        <div className="flex-row-container flex-1">
          <AlbumArt
            imageSize={"64x64"}
            className="medium m-rxs"
            src={show?.imageURL}
            title={show?.title}
            isRedirected={isShowRedirected(show)}
          />
          <div className="flex-column-container">
            {isLoading && <Loading />}
            {!isLoading && (
              <>
                {campaignItem && campaignItem.state && (
                  <span>
                    <ExclusiveTag className={`podcaster tag-${campaignItem.state} m-bxxs`}>
                      {CampaignItemStateToFriendlyPodcaster[campaignItem.state]}
                    </ExclusiveTag>
                  </span>
                )}
                <Page.Title className="line-clamp-1">{campaign?.name}</Page.Title>
              </>
            )}
          </div>
        </div>

        {/* BUTTON CTAs */}
        {campaignItem && campaignItem.state === CampaignItemStateAwaitingAudio && canEditRap && (
          <Button
            type="primary"
            size="large"
            className="p-hs cta"
            disabled={isLoading}
            onClick={() => setAwaitingModal(true)}>
            View & Upload
          </Button>
        )}
        {campaignItem && campaignItem.state === CampaignItemStateSent && canEditRap && (
          <Button
            type="primary"
            size="large"
            className="p-hs cta"
            disabled={isLoading}
            onClick={() => setAcceptModal(true)}>
            Respond to Invite
          </Button>
        )}
      </Page.Header>

      {isLoading && <Loading />}
      {!isLoading && campaignItem && (
        <Page.Section>
          <Row>
            {/* SIDEBAR */}
            <Col xs={0} md={6}>
              <div className="m-rs">
                <AdDetailsSteps campaign={campaign} campaignItem={campaignItem} />
              </div>
            </Col>

            {/* PAGE */}
            <Col xs={24} md={18}>
              {/* POST-ACCEPT */}
              {CAMPAIGN_ITEM_STATES_POST_ACCEPT.includes(campaignItem.state) && (
                <>
                  <ShowCampaignMetadata
                    campaign={campaign}
                    campaignItem={campaignItem}
                    isLoading={isLoading}
                    className="m-bs"
                  />

                  <Tabs defaultActiveKey={"ad-audio"} className="show-campaign-table">
                    <Tabs.TabPane
                      tab={
                        <div className="flex-row-container align-center">
                          Ad Audio
                          {showAudioAlert && <RedDot size={8} className="m-lxxxs m-bxxs" />}
                        </div>
                      }
                      key="ad-audio">
                      <ShowCampaignMedia
                        campaign={campaign}
                        campaignItem={campaignItem}
                        showAudioSwapWarning={showAudioSwapWarning}
                        setAudioSwapModal={setAudioSwapModal}
                        setAwaitingModal={setAwaitingModal}
                        canEditRap={canEditRap}
                      />
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={`Campaign Details`} key="campaign-details">
                      <ShowCampaignPageDetails
                        campaign={campaign}
                        campaignItem={campaignItem}
                        show={show}
                      />
                    </Tabs.TabPane>
                  </Tabs>
                </>
              )}

              {/* PRE-ACCEPT */}
              {!CAMPAIGN_ITEM_STATES_POST_ACCEPT.includes(campaignItem.state) && (
                <>
                  <ShowCampaignPageDetails
                    campaign={campaign}
                    campaignItem={campaignItem}
                    show={show}
                  />
                  {campaignItem.state === CampaignItemStateSent && canEditRap && (
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={12}>
                        <Button
                          type="secondary"
                          size="large"
                          className="width-100"
                          onClick={() =>
                            dispatch(showModal(DECLINE_CAMPAIGN_SURVEY, { campaign, campaignItem }))
                          }>
                          Not Interested
                        </Button>
                      </Col>
                      <Col xs={24} md={12}>
                        <Button
                          type="primary"
                          size="large"
                          disabled={isLoading}
                          className="width-100"
                          onClick={() => setAcceptModal(true)}>
                          Respond to Invite
                        </Button>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Page.Section>
      )}

      {/* MODALS */}
      {campaignItem && (
        <>
          <AcceptCampaignOfferModal
            open={showAcceptModal}
            onClose={() => setAcceptModal(false)}
            show={show}
            campaign={campaign}
            campaignItem={campaignItem}
          />

          <ReviewAndUploadAudioModal
            open={showAwaitingModal}
            onClose={() => setAwaitingModal(false)}
            show={show}
            campaign={campaign}
            campaignItem={campaignItem}
          />

          <SwapAudioModal
            visible={showAudioSwapModal}
            setVisible={setAudioSwapModal}
            campaignItemUUID={campaignItem.uuid}
          />
        </>
      )}
    </Page>
  );
}
