import { useContext, useMemo, useState } from "react";
import { Button, MultiModal } from "redcircle-ui";
import { showWarning } from "src/actions/app";
import { getCampaign, removeCampaignItems, sendCampaign } from "src/action_managers/campaigns";
import { fetchPublicShowsByUUIDs } from "src/action_managers/public_show";
import { CampaignItemStateDraft, CreditCardPaymentMethod } from "src/constants/campaigns";
import { CampaignSendMessages } from "src/constants/notificationMessages";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { isScriptRequired } from "src/lib/campaigns";
import { isShowExcludingCampaign, isShowRedirected } from "src/lib/show";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { IShow } from "src/reducers/shows";
import {
  CampaignSchedulerContext,
  CampaignSchedulerContextWrapper,
} from "./campaign_scheduler_context";
import SchedulerAddScriptPage, { SchedulerAddScriptPageFooter } from "./page_add_scripts";
import SchedulerAssignScriptsPage, {
  SchedulerAssignScriptsPageFooter,
} from "./page_assign_scripts";
import SchedulerPaymentPage, { SchedulerPaymentFooter } from "./page_payment";
import SchedulerPaymentConfirmPage, { SchedulerPaymentConfirmFooter } from "./page_payment_confirm";
import SchedulerPaymentTypePage, { SchedulerPaymentTypeFooter } from "./page_payment_type";
import SchedulerPage, { SchedulerPageFooter } from "./page_scheduler";
import SchedulerSuccessPage from "./page_success";
import SchedulerSummaryPage, { SchedulerSummaryPageFooter } from "./page_summary";

const SchedulerPretitle = ({ campaign }: { campaign?: ICampaign }) => {
  if (campaign) return <span className="redcircle-form-label">{campaign.name}</span>;
  return null;
};

const CampaignSchedulerModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatchTS();
  const { campaign, campaignItems, scriptState, paymentMethodType, paymentCardId } =
    useContext(CampaignSchedulerContext);
  const { user } = useSelectorTS((state) => state.user);
  const [currentPage, setCurrentPage] = useState(0);
  const [isScriptsSkipped, setIsScriptsSkipped] = useState(false);

  const currentScriptsToBeAdded = scriptState?.filter((script: any) => !script.delete);
  const hideAssignScriptsPage = currentScriptsToBeAdded?.length === 0;
  const scriptRequired = useMemo(() => {
    return campaign ? isScriptRequired(campaign) : false;
  }, [campaign?.startsAt]);

  const hidePaymentTypePage = campaign?.paymentType || !user.invoicingPaymentEnabled;

  const handleSubmitCampaign = async () => {
    if (campaign && paymentMethodType) {
      try {
        // Do a check that all current draftItems have not had their setting updated and makes them unable to participate in the campaign
        const draftItems = campaignItems.filter((item) => item.state === CampaignItemStateDraft);
        const draftItemShowUUIDs = draftItems.map((item) => item.showUUID);
        const showListInfoRes = await dispatch(fetchPublicShowsByUUIDs(draftItemShowUUIDs));
        if (showListInfoRes.status !== 200)
          throw new Error(CampaignSendMessages.Error.CouldNotSendCampaign); // something went wrong in grabbing current show info on draft items

        const draftItemsToRemove: IShow[] = showListInfoRes.json?.filter((show: IShow) => {
          !show?.advertisementSettings?.isHostReadEnabled ||
            isShowRedirected(show) ||
            isShowExcludingCampaign(show, campaign);
        });

        if (draftItemsToRemove?.length > 0) {
          await removeInvalidDraftItems(draftItemsToRemove, draftItems); // remove any items that are unable to join campaign
          throw new Error("Some shows were removed from the campaign due to invalid settings");
        }

        const sendResponse = await dispatch(
          sendCampaign(campaign.uuid, paymentCardId, paymentMethodType)
        );

        if (sendResponse.status === 200) {
          dispatch(getCampaign(campaign.uuid));
          setCurrentPage(currentPage + 1);
        } else {
          // Forcing error to stop the user from heading to the next modal page since the campaign update failed
          throw new Error(CampaignSendMessages.Error.CouldNotSendCampaign);
        }
      } catch (err: any) {
        console.error("Error submitting campaign", err);
        err?.message && dispatch(showWarning(err.message));
      }
    }
  };

  const removeInvalidDraftItems = async (showsToRemove: IShow[], draftItems: ICampaignItem[]) => {
    if (campaign && showsToRemove?.length > 0) {
      const showToDraftItemMap = draftItems?.reduce(
        (accu, item) => {
          accu[item.showUUID] = item;
          return accu;
        },
        {} as Record<string, ICampaignItem>
      );

      const draftItemsToRemove = showsToRemove.map((show) => showToDraftItemMap[show.uuid].uuid);

      const allPodsAreUnavailable = showsToRemove?.length === draftItems?.length;
      const successMessage = allPodsAreUnavailable
        ? `The podcast(s) you have selected are unfortunately no longer available to be part of this campaign. Please go back and choose other podcasts that are available.`
        : `${
            showsToRemove?.length > 1
              ? `${showsToRemove?.length} podcasts have been removed since they are`
              : `${showsToRemove[0]?.title} has been removed since it is`
          } no longer available to participate in this campaign. Please recheck the campaign schedule information.`;

      const res = await dispatch(
        removeCampaignItems({ campaign, campaignItemUUIDs: draftItemsToRemove, successMessage })
      );
      if (res.status === 200) {
        allPodsAreUnavailable ? onClose() : setCurrentPage(0);
      } else {
        onClose();
      }
    }
  };

  const pages = [
    // SCHEDULE CAMPAIGNS
    {
      pretitle: <SchedulerPretitle campaign={campaign} />,
      title: "Schedule Podcasts",
      subtitle: "Select your timeline and distribute your campaign budget across each show.",
      bodyClassName: "p-b0",
      body: <SchedulerPage />,
      footer: <SchedulerPageFooter onSubmitSuccess={() => setCurrentPage(currentPage + 1)} />,
    },
    // CAMPAIGN SUMMARY + CAMPAIGN VALIDATION
    {
      pretitle: <SchedulerPretitle campaign={campaign} />,
      title: "Campaign Summary",
      subtitle:
        "Here’s a summary of your campaign. Make sure everything looks good before sending your campaign.",
      hidden: false,
      body: <SchedulerSummaryPage />,
      footer: (
        <SchedulerSummaryPageFooter
          onBack={() => setCurrentPage(currentPage - 1)}
          onSubmit={() => setCurrentPage(currentPage + 1)}
        />
      ),
    },

    // ADD SCRIPTS
    {
      pretitle: <SchedulerPretitle campaign={campaign} />,
      title: "Add Scripts",
      subtitle: "Add the details for your campaign’s scripts.",
      body: <SchedulerAddScriptPage />,
      footer: (
        <SchedulerAddScriptPageFooter
          onBack={() => setCurrentPage(currentPage - 1)}
          onSkip={() => {
            setIsScriptsSkipped(true);
            setCurrentPage(
              hideAssignScriptsPage && !scriptRequired ? currentPage + 1 : currentPage + 2
            );
          }}
          onSubmitSuccess={() => {
            setIsScriptsSkipped(false);
            setCurrentPage(currentPage + 1);
          }}
        />
      ),
    },
    // ASSIGN+VALIDATE SCRIPTS
    {
      pretitle: <SchedulerPretitle campaign={campaign} />,
      title: "Assign Scripts to Podcasts",
      subtitle:
        "Select a script for each podcast. Promo codes will be auto-filled based on your selected script.",
      body: <SchedulerAssignScriptsPage />,
      hidden: hideAssignScriptsPage && !scriptRequired,
      footer: (
        <SchedulerAssignScriptsPageFooter
          onBack={() => setCurrentPage(currentPage - 1)}
          onSubmitSuccess={() => setCurrentPage(currentPage + 1)}
        />
      ),
    },

    // PAYMENT TIMELINE
    // note - unused until we implement invoicing updates
    // {
    // pretitle: <SchedulerPretitle campaign={campaign} />,
    // title: "Payment",
    // body: <SchedulerPaymentTimelinePage />,
    // footer: (
    //   <SchedulerPaymentTimelineFooter
    //     onBack={() => setCurrentPage(isScriptsSkipped ? currentPage - 2 : currentPage - 1)}
    //     onSubmit={() => setCurrentPage(currentPage + 1)}
    //   />
    // ),
    // },
    // PAYMENT TYPE
    {
      pretitle: <SchedulerPretitle campaign={campaign} />,
      title: "Payment",
      subtitle:
        "Select how you’d like to pay. Whichever payment type you choose will be used for the entirety of the campaign.",
      body: <SchedulerPaymentTypePage />,
      hidden: hidePaymentTypePage,
      footer: (
        <SchedulerPaymentTypeFooter
          onBack={() => setCurrentPage(isScriptsSkipped ? currentPage - 2 : currentPage - 1)}
          onSubmit={() => setCurrentPage(currentPage + 1)}
        />
      ),
    },
    // PAYMENT PAGE
    {
      pretitle: <SchedulerPretitle campaign={campaign} />,
      title: "Payment",
      body: <SchedulerPaymentPage />,
      hidden: paymentMethodType !== CreditCardPaymentMethod,
      footer: (
        <SchedulerPaymentFooter
          onBack={() => setCurrentPage(currentPage - 1)}
          onSubmit={() => setCurrentPage(currentPage + 1)}
        />
      ),
    },
    // PAYMENT REVIEW
    {
      pretitle: <SchedulerPretitle campaign={campaign} />,
      title: "Payment",
      body: <SchedulerPaymentConfirmPage />,
      footer: (
        <SchedulerPaymentConfirmFooter
          onBack={() => setCurrentPage(currentPage - 1)}
          onSubmit={handleSubmitCampaign}
        />
      ),
    },
    // SUCCESS
    {
      pretitle: <SchedulerPretitle campaign={campaign} />,
      title: "Success!",
      subtitle: <>Your campaign has been sent to the podcasts you selected.</>,
      body: <SchedulerSuccessPage />,
      footer: (
        <>
          <Button type="primary" size="large" className="m-la" onClick={onClose}>
            Close
          </Button>
        </>
      ),
    },
  ].filter((page) => !page.hidden);

  return (
    <MultiModal
      onClose={onClose}
      open
      pages={pages}
      currentPage={currentPage}
      size="lg"
      isViewportHeight
    />
  );
};

export default function CampaignSchedulerModalWrapper({
  closeModal,
  closeRoute,
  match,
}: {
  closeModal: () => void;
  closeRoute: () => void;
  match: any;
}) {
  const campaignUUID: string | undefined = match?.params?.campaignUUID;

  const handleClose = () => {
    closeModal();
    closeRoute();
  };

  return (
    <CampaignSchedulerContextWrapper campaignUUID={campaignUUID}>
      <CampaignSchedulerModal onClose={handleClose} />
    </CampaignSchedulerContextWrapper>
  );
}
