import { useContext } from "react";
import { localDate } from "src/lib/date";
import { ICampaignItem } from "src/reducers/campaign_items";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";

export default function SchedulerTimelineCell({
  campaignItem,
}: {
  campaignItem: ICampaignItem & { overrides: any };
}) {
  const { timeline, minEndDatesState } = useContext(CampaignSchedulerContext);
  const { minEndDates, isLoading, error } = minEndDatesState;

  let startDate = timeline?.[0]?.unix() ?? 0;
  if (campaignItem.lifecycleSettings.startAt?.overridden) {
    startDate = campaignItem.lifecycleSettings.startAt.value;
  }

  const isErrored = minEndDates?.[campaignItem.showUUID]?.streamulatorErrored;
  const forecastedEndDate = minEndDates?.[campaignItem.showUUID]?.estimatedEndTime;
  let endDate = forecastedEndDate ?? 0;

  const isPaced = campaignItem.pacing.value;
  if (isPaced && campaignItem.lifecycleSettings.endAt) {
    endDate = campaignItem.lifecycleSettings.endAt.value;
  }

  /**
   * On v2 items only, helps to visually let user know the timeline shown is either paced or estimated
   */
  const itemPacingState = campaignItem?.isV2 ? (
    <p className="fs-11 lh-11 pa-0 m-bxxxs">{isPaced ? "Evenly Paced" : "Estimated Forecast"}</p>
  ) : (
    ""
  );

  return (
    <div className="flex-column-container justify-start">
      {itemPacingState}
      <div className="no-wrap">
        {localDate(startDate)}
        {isLoading && !error ? "..." : ` - ${isErrored ? "N/A" : localDate(endDate)}`}
      </div>
    </div>
  );
}
