import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Input } from "antd";
import classNames from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Loading } from "redcircle-ui";
import { addCard } from "src/action_managers/subscriptions";
import useGetPaymentCards from "src/hooks/payment";
import { useDispatchTS } from "src/hooks/redux-ts";
import { getStripePublicKey } from "src/lib/config";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";
import { StripeCard } from "./stripe_components";

import styles from "./page_payment.module.scss";
import cardStyles from "./stripe_components.module.scss";

const stripePromise = loadStripe(getStripePublicKey());

export default function SchedulerPaymentPage() {
  const { paymentCardId, setPaymentCardId } = useContext(CampaignSchedulerContext);
  return (
    <Elements stripe={stripePromise}>
      <StripeSelectCardForm value={paymentCardId} onSelect={setPaymentCardId} className="p-bs" />
    </Elements>
  );
}

export const SchedulerPaymentFooter = ({
  onBack,
  onSubmit,
}: {
  onBack: () => void;
  onSubmit: () => void;
}) => {
  const { paymentCardId } = useContext(CampaignSchedulerContext);
  return (
    <>
      <Button type="link" size="large" className="p-a0" onClick={onBack}>
        Back
      </Button>
      <Button type="primary" size="large" onClick={onSubmit} disabled={!paymentCardId}>
        Continue
      </Button>
    </>
  );
};

// TODO: these are better stripe card forms with new API - move to its own component
const StripeSelectCardForm = ({
  value,
  onSelect,
  className,
}: {
  value: string | null | undefined; // stripe card id
  onSelect: (value: string) => void;
  className?: string;
}) => {
  const { cards, isLoading: isCardsLoading } = useGetPaymentCards();
  const [isAddingNewCard, setIsAddingNewCard] = useState(false);

  return (
    <div className={className}>
      {isCardsLoading && <Loading className="m-as" />}
      {!isCardsLoading && (
        <div className={styles.grid}>
          {Object.entries(cards).map(([id, card]) => {
            return (
              <StripeCard
                key={id}
                card={card}
                isDisabled={isAddingNewCard}
                isSelected={value === id}
                onSelect={() => onSelect(id)}
              />
            );
          })}

          {!isAddingNewCard && (
            <Button
              type="link"
              className={cardStyles.card}
              onClick={() => setIsAddingNewCard(true)}>
              <h3>+ Add New Card</h3>
            </Button>
          )}

          {isAddingNewCard && (
            <StripeAddCardForm
              onClose={() => setIsAddingNewCard(false)}
              onSubmit={(res) => onSelect(res.id)}
            />
          )}
        </div>
      )}
    </div>
  );
};

const StripeAddCardForm = ({
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit?: (card: any) => void;
}) => {
  const dispatch = useDispatchTS();
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) return;
    setErrorMessage("");
    setIsSubmitLoading(true);

    const cardElement = elements.getElement("card");
    if (cardElement === null) return;
    const res = await stripe.createToken(cardElement, { name });
    if (res.error?.message) {
      setErrorMessage(res.error.message);
    } else if (res.token) {
      const res2 = await dispatch(addCard(res.token.id));
      if (res2.status !== 200) {
        setErrorMessage("Error adding card");
      } else {
        if (onSubmit) onSubmit(res2.json);
        onClose();
      }
    }
    setIsSubmitLoading(false);
  };

  return (
    <div className={classNames(cardStyles.card)}>
      <label className="redcircle-form-label" htmlFor="cardName">
        Name on Card
      </label>
      <Input
        autoFocus
        id="cardName"
        placeholder="Card name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <label className="redcircle-form-label m-ts" htmlFor="card">
        Card
      </label>
      <CardElement />
      {errorMessage && <small className="color-primary">{errorMessage}</small>}

      <div className="flex-row-container justify-between m-ts">
        <Button type="link" size="small" className="p-a0" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="primary"
          size="small"
          disabled={!name}
          onClick={handleSubmit}
          loading={isSubmitLoading}>
          Add Card
        </Button>
      </div>
    </div>
  );
};
