import { AutoComplete, Form, Input, TreeSelect } from "antd";
import { useEffect, useMemo, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Button, COLORS, Loading, Tag } from "redcircle-ui";
import { searchBrands } from "src/action_managers/brands";
import daastAdCategories from "src/data/daast-ad-categories";
import { useDebounce } from "src/hooks/lib";
import { useReduxDispatch } from "src/hooks/redux-ts";
import { isValidUrl } from "src/lib/url";

export default function CampaignFormBrand() {
  const form = Form.useFormInstance();
  const brand = Form.useWatch("brand", { form, preserve: true });
  const dispatch = useReduxDispatch();

  const [inputValue, setInputValue] = useState("");
  const [isBrandSelected, setIsBrandSelected] = useState(false);

  const debouncedValue = useDebounce(inputValue);
  const [options, setOptions] = useState<{ value: string | null; label: any; data?: any }[]>([]);

  const treeCategories = useMemo(() => {
    return daastAdCategories.map((c) => ({
      title: `${c.value} ${c.title}`,
      value: c.value,
      children: c.children.map((c2) => ({ title: `${c2.value} ${c2.title}`, value: c2.value })),
    }));
  }, []);

  useEffect(() => {
    if (brand?.instanceUUID) setIsBrandSelected(true);
  }, [brand]);

  useEffect(() => {
    search(debouncedValue);
  }, [debouncedValue]);

  const search = async (value: string) => {
    if (!value || value.length < 3) {
      setOptions([]);
      return;
    }

    const capitalized = value.charAt(0).toUpperCase() + value.slice(1);
    const defaultOption = {
      value: capitalized,
      label: (
        <Button type="link" className="p-a0 flex-row-container align-center">
          <AiOutlinePlusCircle className="m-rxxs" />
          New Brand: {capitalized}
        </Button>
      ),
    };
    const loadingOption = {
      value: "loading",
      disabled: true,
      label: (
        <div className="width-100 flex-row-container justify-center">
          <Loading />
        </div>
      ),
    };
    setOptions([defaultOption, loadingOption]);

    const res = await dispatch(searchBrands(value));
    if (res.status === 200 && res.json) {
      const searchedOptions = res.json.map((b) => ({
        value: b.instanceUUID || null,
        label: (
          <Button type="link" className="p-a0">
            {b.name}
          </Button>
        ),
        data: b,
      }));
      setOptions([defaultOption, ...searchedOptions]);
    }
  };

  const handleSearch = async (value: string) => {
    setInputValue(value);
  };

  // if brand exists, clear fields on change
  const handleChange = () => {
    if (isBrandSelected) setIsBrandSelected(false);
    if (brand.instanceUUID) {
      form.setFieldsValue({
        brand: {
          instanceUUID: undefined,
          iabCategory: undefined,
          domain: undefined,
          description: undefined,
        },
      });
    }
  };

  // populate form fields with selected brand
  const handleSelect = (value: string) => {
    const option = options.find((o) => o.value === value);
    if (option?.data) {
      form.setFieldsValue({ brand: option.data });
    } else {
      form.setFieldsValue({
        brand: {
          name: value,
          instanceUUID: undefined,
          ...(brand.instanceUUID
            ? {
                iabCategory: undefined,
                domain: undefined,
                description: undefined,
              }
            : {}),
        },
      });
      form.validateFields();
    }
    setIsBrandSelected(true);
  };

  const renderTag = () => {
    if (brand && brand.name && isBrandSelected) {
      if (brand.instanceUUID) return <Tag color={COLORS.COLOR_SUCCESS}>Existing Brand</Tag>;
      return <Tag color={COLORS.COLOR_WARNING}>New Brand</Tag>;
    }
  };

  return (
    <div>
      <label className="redcircle-form-label fs-11" htmlFor="brand_name">
        Brand Name*
      </label>
      <Form.Item
        name={["brand", "name"]}
        className="flex-column-container"
        rules={[
          {
            validator: (_, value) => {
              if (!value) return Promise.reject(new Error("Brand name is required"));
              if (!isBrandSelected)
                return Promise.reject(new Error("Please select a brand or create a new one"));
              return Promise.resolve();
            },
          },
        ]}>
        <AutoComplete
          placeholder="example: Nike"
          size="large"
          options={options}
          onChange={handleChange}
          onSearch={handleSearch}
          onSelect={handleSelect}
          suffixIcon={renderTag()}
        />
      </Form.Item>
      {isBrandSelected && (
        <>
          <label className="redcircle-form-label fs-11" htmlFor="brand_iabCategory">
            Primary Advertising Category*
          </label>
          <Form.Item
            name={["brand", "iabCategory"]}
            className="flex-column-container"
            rules={[{ required: true, message: "Please select an advertising category" }]}>
            <TreeSelect
              showSearch
              placeholder="Select IAB category..."
              treeData={treeCategories}
              size="large"
              filterTreeNode={(input, node) =>
                node && node.title
                  ? (node.title as string).toLowerCase().includes(input.toLowerCase())
                  : false
              }
            />
          </Form.Item>

          <label className="redcircle-form-label fs-11" htmlFor="brand_domain">
            Advertiser Domain
          </label>
          <Form.Item
            name={["brand", "domain"]}
            className="flex-column-container"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) return Promise.reject(new Error("Website URL is required"));
                  if (isValidUrl(value)) return Promise.resolve();
                  return Promise.reject(new Error("Invalid URL"));
                },
              },
            ]}>
            <Input placeholder="https://nike.com" size="large" />
          </Form.Item>

          <label className="redcircle-form-label fs-11" htmlFor="brand_description">
            Brand Description
          </label>
          <Form.Item
            name={["brand", "description"]}
            className="flex-column-container"
            rules={[{ required: true, message: "Brand description is required" }]}>
            <Input.TextArea
              placeholder="Tell us more about the brand..."
              size="large"
              autoSize={{ minRows: 2, maxRows: 5 }}
            />
          </Form.Item>
        </>
      )}
    </div>
  );
}
