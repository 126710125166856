import { Col, Form, InputNumber, Row } from "antd";
import { useEffect, useState } from "react";
import { AiFillCaretRight } from "react-icons/ai";
import { formatMoney } from "redcircle-lib";
import { Button } from "redcircle-ui";
import { useSelectorTS } from "src/hooks/redux-ts";
import { getAverageCPM } from "src/lib/campaigns";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";

const DEFAULT_CUT = 3000;

export default function ModifyPricingForm({
  campaign,
  campaignItem,
}: {
  campaign?: ICampaign;
  campaignItem?: ICampaignItem;
}) {
  const form = Form.useFormInstance();
  const cut = Form.useWatch("cut", form);
  const cpm = Form.useWatch("cpm", form);
  const formTotalBudget = Form.useWatch("totalBudget", { form, preserve: true });

  // get budget
  const budgets = useSelectorTS((state) => state.budgets);
  const budget = campaignItem?.budgetUUID ? budgets[campaignItem.budgetUUID] : undefined;

  // get stats
  const campaignStatsByUUID = useSelectorTS((state) => state.campaigns.campaignStatsByUUID);
  const campaignStats = campaignItem && campaignStatsByUUID?.[campaignItem.campaignUUID];

  // get show
  const publicShows = useSelectorTS((state) => state.publicShows);
  const show = campaignItem && publicShows[campaignItem.showUUID];

  // calculate fields
  const finalCut = campaignItem?.advertisingCutBasisPoints || DEFAULT_CUT;
  const originalRate = getAverageCPM({ campaign, campaignItem, show, when: "original" });
  const finalRate = getAverageCPM({ campaign, campaignItem, show, when: "final" });

  const impressions =
    (campaignItem && campaignStats?.downloadsByShowUUID?.[campaignItem.showUUID]) ?? 0;
  const spend = (finalRate * impressions) / 1000 / 100;
  const totalBudget = (campaignItem?.totalBudget ?? 0) / 100;
  const remaining = totalBudget - spend;
  const totalImpressions = Math.floor(totalBudget / (finalRate / 1000 / 100));

  const adjustedFormBudget = (formTotalBudget ?? 0) / 100;
  const adjustedTotalImpressions = Math.floor(adjustedFormBudget / (finalRate / 1000 / 100));

  const [remainingBudget, setRemainingBudget] = useState<number | null>(remaining);
  useEffect(() => {
    setRemainingBudget(remaining);
  }, [remaining]);

  const handleBudgetChange = (value: number | null) => {
    if (typeof value !== "number") return;
    setRemainingBudget(value);
    form.setFieldsValue({ totalBudget: (value + spend) * 100 });
  };

  if (!campaignItem) return null;
  return (
    <div className="flex-column-container">
      <Row>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label">Spend So Far</div>
          <strong>{formatMoney(spend)}</strong>
        </Col>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label">Impressions So Far</div>
          <strong>{campaignStats?.downloadsByShowUUID?.[campaignItem.showUUID] ?? "N/A"}</strong>
        </Col>
      </Row>

      <Row className="m-txs">
        <Col xs={11} md={7}>
          <div className="redcircle-form-label">RedCircle Cut</div>
          <div className="p-txxxs">
            <strong>{finalCut / 100}%</strong>
          </div>
        </Col>
        <Col span={1}>
          <AiFillCaretRight />
        </Col>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label">Modified Cut</div>
          <div className="flex-row-container">
            <Form.Item name="cut" className="m-b0">
              <InputNumber suffix="%" min={0.01} style={{ width: 120 }} />
            </Form.Item>
            {cut !== DEFAULT_CUT / 100 && (
              <Button
                type="link"
                className="p-a0 m-lxs"
                onClick={() => form.setFieldsValue({ cut: DEFAULT_CUT / 100 })}>
                Reset
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <Row className="m-txs">
        <Col xs={11} md={7}>
          <div className="redcircle-form-label">Budget Remaining</div>
          <div className="p-txxxs">
            <strong>{formatMoney(remaining)}</strong>
          </div>
        </Col>
        <Col span={1}>
          <AiFillCaretRight />
        </Col>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label">Modified Budget</div>
          <div className="flex-row-container">
            <InputNumber
              prefix="$"
              min={0.01}
              precision={2}
              value={remainingBudget}
              onChange={handleBudgetChange}
              style={{ width: 120 }}
            />
            {remainingBudget !== remaining && (
              <Button
                type="link"
                className="p-a0 m-lxs"
                onClick={() => handleBudgetChange(remaining)}>
                Reset
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <Row className="m-txs">
        <Col xs={11} md={7}>
          <div className="redcircle-form-label">CPM</div>
          <div className="p-txxxs">
            <strong>{formatMoney(finalRate / 100)}</strong>
          </div>
        </Col>
        <Col span={1}>
          <AiFillCaretRight />
        </Col>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label">Modified CPM</div>
          <div className="flex-row-container">
            <Form.Item name="cpm" className="m-b0">
              <InputNumber prefix="$" min={0.01} precision={2} style={{ width: 120 }} />
            </Form.Item>
            {cpm !== originalRate / 100 && (
              <Button
                type="link"
                className="p-a0 m-lxs"
                onClick={() => form.setFieldsValue({ cpm: originalRate / 100 })}>
                Reset
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label m-txs">Total Amount</div>
          <strong
            style={{
              textDecoration: adjustedFormBudget !== totalBudget ? "line-through" : "none",
            }}>
            {formatMoney(totalBudget)}
          </strong>
          {adjustedFormBudget !== totalBudget && (
            <strong className="m-lxxs">{formatMoney(adjustedFormBudget)}</strong>
          )}
        </Col>
        <Col xs={12} md={8}>
          <div className="redcircle-form-label m-txs">Total Impressions</div>
          <strong
            style={{
              textDecoration:
                adjustedTotalImpressions !== totalImpressions ? "line-through" : "none",
            }}>
            {totalImpressions}
          </strong>
          {adjustedTotalImpressions !== totalImpressions && (
            <strong className="m-lxxs">{adjustedTotalImpressions}</strong>
          )}
        </Col>
      </Row>
    </div>
  );
}
