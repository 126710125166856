import { Form, InputNumber } from "antd";
import { Fragment, useContext, useEffect, useState } from "react";
import { Button, Modal } from "redcircle-ui";
import { showError, showSuccess } from "src/actions/app";
import { updateCampaignItems } from "src/action_managers/campaigns";
import { permissionTypes } from "src/constants/permission_roles";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { getAverageCPM } from "src/lib/campaigns";
import { canAdvertiserAccess } from "src/lib/permissions";
import { ICampaignItem } from "src/reducers/campaign_items";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";

const FIELDS = {
  cpm: {
    label: "CPM",
    placeholder: "Enter CPM",
    type: "currency",
  },
  downloads: {
    label: "Downloads",
    placeholder: "Enter Downloads",
    type: "number",
  },
  budget: {
    label: "Budget",
    placeholder: "Enter Budget",
    type: "currency",
  },
};

export default function SchedulerAdjustModal({
  open,
  onClose,
  allCampaignItems,
  campaignItemUUIDs = [],
}: {
  open: boolean;
  onClose: () => void;
  allCampaignItems?: ICampaignItem[];
  campaignItemUUIDs?: string[];
}) {
  const [form] = Form.useForm();
  const fields = Form.useWatch([], { form });

  const { budgets, setBudgets, campaign, timeline, maxImpressionsState } =
    useContext(CampaignSchedulerContext);

  const dispatch = useDispatchTS();
  const { user } = useSelectorTS((state) => state.user);
  const publicShows = useSelectorTS((state) => state.publicShows);
  const [isFieldDisabled, setIsFieldDisabled] = useState<Record<string, boolean>>({
    cpm: true,
    downloads: true,
    budget: true,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const canUserNegotiate =
    user?.userAttributes?.canNegotiate === "true" &&
    canAdvertiserAccess(permissionTypes.negotiateRates, campaign);

  useEffect(() => {
    if (!open) {
      setIsFieldDisabled({
        cpm: true,
        downloads: true,
        budget: true,
      });
    } else {
      form.resetFields();
    }
  }, [open]);

  const resyncFields = (fieldKey: string) => {
    // this only triggers if all three fields are enabled
    if (Object.entries(isFieldDisabled).every(([k, v]) => !v)) {
      const { cpm, downloads, budget } = fields;
      if (!cpm || !downloads || !budget) return;

      if (fieldKey === "cpm") {
        const newDownloads = (budget / cpm) * 1000;
        form.setFieldsValue({ downloads: Math.max(newDownloads, 1) });
      }

      if (fieldKey === "downloads") {
        const newBudget = (downloads / 1000) * cpm;
        form.setFieldsValue({ budget: Math.max(newBudget, 0.01) });
      }

      if (fieldKey === "budget") {
        const newDownloads = (budget / cpm) * 1000;
        form.setFieldsValue({ downloads: Math.max(newDownloads, 1) });
      }
    }
  };

  const handleSubmit = async () => {
    if (!campaign) return;
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      let hasError = false;

      setIsSubmitting(true);
      if (values.cpm) {
        const res = await dispatch(
          updateCampaignItems({
            campaign,
            items: campaignItemUUIDs.reduce((acc, campaignItemUUID) => {
              const item = {} as any;
              const campaignItem = allCampaignItems?.find((ci) => ci.uuid === campaignItemUUID);
              const show = campaignItem && publicShows[campaignItem.showUUID];

              if (values.cpm) {
                const cpm = values.cpm * 100;
                item.offerRates = {
                  enabled: true,
                  originalPreRollCPM: show?.advertisementSettings?.hostReadPreRollCPM,
                  originalMidRollCPM: show?.advertisementSettings?.hostReadMidRollCPM,
                  originalPostRollCPM: show?.advertisementSettings?.hostReadPostRollCPM,
                  finalAdjustedPreRollCPM: cpm,
                  finalAdjustedMidRollCPM: cpm,
                  finalAdjustedPostRollCPM: cpm,
                };

                if (!item.isV2) {
                  item.startAt = timeline?.[0]?.unix() ?? 0;
                }
              }
              return {
                ...acc,
                [campaignItemUUID]: item,
              };
            }, {}),
          })
        );

        if (res.status !== 200) {
          hasError = true;
        }
      }

      if (values.downloads && !values.budget) {
        setBudgets({
          ...budgets,
          ...campaignItemUUIDs.reduce((acc, campaignItemUUID) => {
            const campaignItem = allCampaignItems?.find((ci) => ci.uuid === campaignItemUUID);
            const show = campaignItem && publicShows[campaignItem.showUUID];
            const cpm = values.cpm
              ? values.cpm * 100
              : getAverageCPM({ show, campaign, campaignItem });
            const newBudget = (values.downloads / 1000) * cpm;
            return {
              ...acc,
              [campaignItemUUID]: newBudget,
            };
          }, {}),
        });
      }

      if (values.budget) {
        setBudgets({
          ...budgets,
          ...campaignItemUUIDs.reduce((acc, campaignItemUUID) => {
            return {
              ...acc,
              [campaignItemUUID]: values.budget * 100,
            };
          }, {}),
        });
      }

      setIsSubmitting(false);
      if (hasError) {
        dispatch(showError("Failed to update items"));
      } else {
        dispatch(showSuccess(`Successfully updated ${campaignItemUUIDs.length} items`));
        onClose();
      }
    } catch (e) {
      // nothing happens
    }
  };

  return (
    <Form form={form}>
      <Modal open={open} onClose={onClose} size="xs">
        <Modal.TitleSection>
          <h2>Adjusting {campaignItemUUIDs.length} Items</h2>
          <span>These settings will be applied to all items.</span>
        </Modal.TitleSection>
        <Modal.Body>
          {Object.entries(FIELDS).map(([key, value]) => {
            return (
              <Fragment key={key}>
                {(key !== "cpm" || (key === "cpm" && canUserNegotiate)) && (
                  <>
                    <div className="redcircle-form-label">{value.label}</div>
                    {isFieldDisabled[key] && (
                      <Button
                        onClick={() => setIsFieldDisabled({ ...isFieldDisabled, [key]: false })}
                        style={{ marginBottom: 24 }}>
                        No Changes
                      </Button>
                    )}
                    {!isFieldDisabled[key] && (
                      <div className="flex-row-container">
                        <Form.Item name={key} rules={[{ required: true }]}>
                          <InputNumber
                            style={{ width: 160 }}
                            prefix={value.type === "currency" ? "$" : ""}
                            precision={value.type === "currency" ? 2 : 0}
                            min={value.type === "currency" ? 0.01 : 1}
                            autoFocus
                            onBlur={() => resyncFields(key)}
                            addonAfter={
                              <>
                                <Button
                                  type="link"
                                  size="small"
                                  className="p-a0"
                                  onClick={() => {
                                    setIsFieldDisabled({ ...isFieldDisabled, [key]: true });
                                    form.resetFields([key]);
                                  }}>
                                  Reset
                                </Button>
                              </>
                            }
                          />
                        </Form.Item>
                      </div>
                    )}
                  </>
                )}
              </Fragment>
            );
          })}
        </Modal.Body>
        <Modal.Footer noBorder>
          <Modal.CloseButton onClick={onClose} />
          <Modal.SubmitButton
            onClick={handleSubmit}
            disabled={Object.entries(isFieldDisabled).every(([k, v]) => v)}
            isLoading={isSubmitting}>
            Save
          </Modal.SubmitButton>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}
