import { UnixTimeStamp } from "src/lib/date";
import getAPIPrefix from "../lib/api_prefix";
import type { User } from "../reducers/user";
import { ICampaign } from "src/reducers/campaigns/types";

type POSITIONS = "PREROLL" | "MIDROLL" | "POSTROLL";

export type ForecastImpressionsRequest = {
  campaignItems: {
    showUUID: string;
    positionsOverride?: Partial<Record<POSITIONS, Record<string, never>>>;
    startDateOverride?: UnixTimeStamp;
    endDateOverride?: UnixTimeStamp;
    recentEpisodesOnlyOverride?: boolean;
    frequencyConfigsOverride?: ICampaign["frequencyConfigs"];
  }[];
  positions: Partial<Record<POSITIONS, Record<string, never>>>;
  startDate: UnixTimeStamp;
  endDate: UnixTimeStamp;
  recentEpisodesOnly: boolean;
  frequencyConfigs: ICampaign["frequencyConfigs"];
};

export type ForecastImpressionResponse = {
  resultsByShowUUID: {
    [showUUID: string]:
      | {
          impressionsByPosition: Partial<Record<POSITIONS, number>>;
          streamulatorErrored: false;
        }
      | {
          impressionsByPosition: null;
          streamulatorErrored: true;
        };
  };
};

export type ForecastImpressionFailureResponse = {
  statusCode: number;
  message: string;
  details: ForecastImpressionResponse;
};

export const getCampaignForecastImpressions = (
  requestInfo: ForecastImpressionsRequest,
  user: User
) => {
  return fetch(`${getAPIPrefix()}campaign-forecast/impressions`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + user.authToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestInfo),
  });
};

export type ForecastEndDateRequest = {
  campaignItems: {
    showUUID: string;
    cpm: Partial<Record<POSITIONS, number>>;
    budget: number;
    positionsOverride?: Partial<Record<POSITIONS, Record<string, never>>>;
    startDateOverride?: UnixTimeStamp;
    recentEpisodesOnlyOverride?: boolean;
    frequencyConfigsOverride?: ICampaign["frequencyConfigs"];
  }[];
  positions: Partial<Record<POSITIONS, Record<string, never>>>;
  startDate: UnixTimeStamp;
  recentEpisodesOnly: boolean;
  frequencyConfigs: ICampaign["frequencyConfigs"];
};

export type ForecastEndDateResponse = {
  resultsByShowUUID: {
    [showUUID: string]:
      | {
          estimatedEndTime: UnixTimeStamp;
          streamulatorErrored: false;
        }
      | {
          estimatedEndTime: null;
          streamulatorErrored: true;
        };
  };
};

export type ForecastEndDateFailureResponse = {
  statusCode: number;
  message: string;
  details: ForecastEndDateResponse;
};

export const getCampaignForecastEndDates = (requestInfo: ForecastEndDateRequest, user: User) => {
  return fetch(`${getAPIPrefix()}campaign-forecast/timeline`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + user.authToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestInfo),
  });
};

export const getCampaignZippedMediaFiles = ({
  campaignUUID,
  user,
}: {
  campaignUUID: string;
  user: User;
}) => {
  return fetch(`${getAPIPrefix()}campaigns/${campaignUUID}/media-files`, {
    headers: {
      Authorization: "Bearer " + user.authToken,
      "Content-Type": "application/zip",
    },
  });
};
