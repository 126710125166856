import { Button } from "antd";
import { ButtonProps, ButtonType } from "antd/lib/button";
import classNames from "classnames";
import styles from "./RCButton.module.scss";

/* Extending all props from antd button props */
export interface TButton extends Omit<ButtonProps, "type"> {
  type?:
    | ButtonType
    | "secondary"
    | "secondary-red"
    | "outline"
    | "outline-light"
    | "outline-red"
    | "icon";
  /**
   * flex: boolean
   * allows button to flex lines
   */
  flex?: boolean; // allows button to flex lines
}

export const RCButton = ({
  children,
  className = "",
  type = "default",
  flex,
  ...props
}: TButton) => {
  let antdType;
  let extraButtonStyle;

  switch (type) {
    case "secondary":
      antdType = "default";
      break;
    case "secondary-red":
      antdType = "default";
      extraButtonStyle = styles["secondary-red"];
      break;
    case "outline":
      antdType = "default";
      extraButtonStyle = styles["outline"];
      break;
    case "outline-light":
      antdType = "default";
      extraButtonStyle = styles["outline-light"];
      break;
    case "outline-red":
      antdType = "default";
      extraButtonStyle = styles["outline-red"];
      break;
    case "icon":
      antdType = "link";
      extraButtonStyle = styles["icon"];
      break;
    default:
      antdType = type;
  }

  return (
    <Button
      role="button"
      type={antdType as ButtonType}
      className={classNames(styles.base, extraButtonStyle, flex && styles.flex, className)}
      {...props}>
      {children}
    </Button>
  );
};
