import { Alert, Form } from "antd";
import { getCampaignTimelineWarnings } from "../campaign_scheduler_modal/campaign_scheduler_utils";
import FlightingDateForm from "./flighting_date_form";
import FlightingOptionsForm from "./flighting_options_form";

export default function CampaignFormFlighting() {
  const form = Form.useFormInstance();
  const campaign = Form.useWatch([], { form, preserve: true });
  const isV2 = campaign?.isV2;
  const timeline = campaign?.timeline;
  const deadlines = campaign?.deadlines;
  const warnings = getCampaignTimelineWarnings({
    campaign,
    startsAt: timeline?.[0],
    endsAt: timeline?.[1],
    responseDeadline: deadlines?.responseDeadline,
    assignAudioDeadline: deadlines?.assignAudioDeadline,
  });

  return (
    <>
      {warnings.length > 0 &&
        warnings.map((warning) => (
          <Alert
            key={warning.message}
            showIcon
            className="m-bxs"
            type="warning"
            message={warning.message}
          />
        ))}

      <FlightingDateForm isV2={isV2} />
      <FlightingOptionsForm />
    </>
  );
}
