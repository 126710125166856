import { useContext } from "react";
import { Button } from "redcircle-ui";
import RadioSelectFrame from "src/components/lib/radio-select-frame/radio-select-frame";
import { CreditCardPaymentMethod, InvoicingPaymentMethod } from "src/constants/campaigns";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";

export default function SchedulerPaymentTypePage() {
  const { paymentMethodType, setPaymentMethodType } = useContext(CampaignSchedulerContext);

  return (
    <div>
      <RadioSelectFrame
        isSelected={paymentMethodType === CreditCardPaymentMethod}
        onClick={() => setPaymentMethodType(CreditCardPaymentMethod)}
        title="Pay By Credit Card"
        description="You’ll be charged incrementally as the campaign runs, up to your maximum budget amount."
      />
      <RadioSelectFrame
        isSelected={paymentMethodType === InvoicingPaymentMethod}
        onClick={() => setPaymentMethodType(InvoicingPaymentMethod)}
        title="Pay By Invoice"
        description="You’ll be invoiced incrementally by RedCircle, based on the number of insertions over that invoice period."
      />
    </div>
  );
}

export const SchedulerPaymentTypeFooter = ({
  onBack,
  onSubmit,
}: {
  onBack: () => void;
  onSubmit: () => void;
}) => {
  const { paymentMethodType } = useContext(CampaignSchedulerContext);

  return (
    <>
      <Button type="link" size="large" className="p-a0" onClick={onBack}>
        Back
      </Button>
      <Button type="primary" size="large" onClick={onSubmit} disabled={!paymentMethodType}>
        Continue
      </Button>
    </>
  );
};
