import isEmpty from "lodash/isEmpty";
import {
  CampaignDistributionType,
  CampaignPaymentType,
  CampaignProductExchangeType,
  CampaignPromoCodeType,
  CampaignState,
  CampaignStyle,
  ICampaign,
  ICampaignTargetingOptions,
} from "src/reducers/campaigns/types";
import { CampaignItemState } from "src/reducers/campaign_items/types";

export const CreditCardPaymentMethod = CampaignPaymentType.CampaignPaymentTypeCreditCard;
export const InvoicingPaymentMethod = CampaignPaymentType.CampaignPaymentTypeInvoicing;

export const CampaignStyleHostRead = CampaignStyle.CampaignStyleHostReadAudio;
export const CampaignStyleCampaignAudio = CampaignStyle.CampaignStyleCampaignAudio;

export const CampaignStyleToDisplay: Record<CampaignStyle, string> = {
  [CampaignStyleHostRead]: "60 second Host-Read",
  [CampaignStyleCampaignAudio]: "Pre-Recorded",
};

export const CampaignStateCreated = CampaignState.CampaignStateCreated;
export const CampaignStateInProgress = CampaignState.CampaignStateInProgress;
export const CampaignStateCompleted = CampaignState.CampaignStateCompleted;

export const CampaignStateToFriendly: Record<string, string> = {
  [CampaignStateCreated]: "Created",
  [CampaignStateInProgress]: "In Progress",
  [CampaignStateCompleted]: "Completed",
};

export const ProductExchangeTypeNone = CampaignProductExchangeType.ProductExchangeTypeNone;
export const ProductExchangeTypeInstructions =
  CampaignProductExchangeType.ProductExchangeTypeInstructions;
export const ProductExchangeTypeMailing = CampaignProductExchangeType.ProductExchangeTypeMailing;

export const PromoCodeTypePodcasterUnique = CampaignPromoCodeType.PromoCodeTypePodcasterUnique;
export const PromoCodeTypeStandard = CampaignPromoCodeType.PromoCodeTypeStandard;
export const PromoCodeTypeNone = CampaignPromoCodeType.PromoCodeTypeNone;

export const VettingStateAwaiting = "vetting"; // this is a FE only state, vetting is actually a separately tracked entity
export const CampaignItemStateNeedsScript: CampaignItemState = "awaiting-script";
export const CampaignItemStateAwaitingAudio: CampaignItemState = "awaiting-audio-upload";
export const CampaignItemStateSent: CampaignItemState = "sent";
export const CampaignItemStateDraft: CampaignItemState = "draft";
export const CampaignItemStateNone: CampaignItemState = "none";
export const CampaignItemStateRunning: CampaignItemState = "running";
export const CampaignItemStateAccepted: CampaignItemState = "accepted";
export const CampaignItemStateCompleted: CampaignItemState = "completed";
export const CampaignItemStatePaused: CampaignItemState = "paused";
export const CampaignItemStateExpired: CampaignItemState = "expired";
export const CampaignItemStateDeclined: CampaignItemState = "declined";
export const CampaignItemStateAudioSwapRequested: CampaignItemState = "audio-swap-requested";

export const CampaignItemStateToFriendly: Record<CampaignItemState, string> = {
  [VettingStateAwaiting]: "Pending Interest",
  [CampaignItemStateDraft]: "Draft",
  [CampaignItemStateSent]: "Pending Invite",
  [CampaignItemStateAccepted]: "Not Started",
  [CampaignItemStateRunning]: "In Progress",
  [CampaignItemStatePaused]: "Paused",
  [CampaignItemStateNeedsScript]: "Needs Script",
  [CampaignItemStateAwaitingAudio]: "Awaiting Audio",
  [CampaignItemStateAudioSwapRequested]: "Paused",
  [CampaignItemStateCompleted]: "Complete",
  [CampaignItemStateExpired]: "Invite Expired",
  [CampaignItemStateDeclined]: "Invite Declined",
};

export const CampaignItemStateToFriendlyPodcaster: Record<CampaignItemState, string> = {
  [VettingStateAwaiting]: "Pending Interest",
  [CampaignItemStateDraft]: "Draft",
  [CampaignItemStateSent]: "Pending Invite",
  [CampaignItemStateAccepted]: "Not Started",
  [CampaignItemStateRunning]: "In Progress",
  [CampaignItemStatePaused]: "Paused",
  [CampaignItemStateNeedsScript]: "Awaiting Script",
  [CampaignItemStateAwaitingAudio]: "Upload Audio",
  [CampaignItemStateAudioSwapRequested]: "Paused",
  [CampaignItemStateCompleted]: "Complete",
  [CampaignItemStateExpired]: "Invite Expired",
  [CampaignItemStateDeclined]: "Invite Declined",
};

export const ProductExchangeHelpDocURL =
  "https://pod-public-media-files.s3.amazonaws.com/assets/help-docs/SharingProductSamples_RedCircleGuide.pdf";
export const GreatScriptHelpDocURL = "https://TODO.org";

export const DistributionTypeContinuous = CampaignDistributionType.DistributionTypeContinuous;
export const DistributionTypeDiscrete = CampaignDistributionType.DistributionTypeDiscrete;

export const CampaignDistributionReachDiscrete = CampaignDistributionType.DistributionTypeDiscrete;
export const CampaignDistributionReachNonContinuous =
  CampaignDistributionType.DistributionTypeNonContinuous;
export const CampaignDistributionReachContinuous =
  CampaignDistributionType.DistributionTypeContinuous;

export const CampaignTargetingOptionMidroll: keyof Pick<ICampaignTargetingOptions, "midRoll"> =
  "midRoll";
export const CampaignTargetingOptionPreroll = "preRoll";
export const CampaignTargetingOptionPostroll = "postRoll";

export const CampaignTargetingOptionToFriendly: Record<keyof ICampaignTargetingOptions, string> = {
  preRoll: "Pre Roll",
  midRoll: "Mid Roll",
  postRoll: "Post Roll",
};

export const CampaignToDiscreteTargetingOptions = (campaign: ICampaign) => {
  if (isEmpty(campaign)) {
    return "";
  }
  if (campaign.targetingOptions.midRoll) {
    return CampaignTargetingOptionMidroll;
  }
  if (campaign.targetingOptions.preRoll) {
    return CampaignTargetingOptionPreroll;
  }
  return CampaignTargetingOptionPostroll;
};

// Campaign Item States where podcast has not explicitly accepted or is currently not in the middle of a campaign.
export const inactiveCampaignItemsList = new Set<CampaignItemState>([
  CampaignItemStateDraft,
  CampaignItemStateSent,
  CampaignItemStateCompleted,
  CampaignItemStateExpired,
  CampaignItemStateDeclined,
]);

export const ScriptDueDateDaysBeforeStart = 8;

export const pacingToFriendly = {
  true: "Even Pacing",
  false: "No Pacing",
};

// TAGS
export const CAMPAIGN_TAG_TYPE__CAMPAIGN = "campaign";
export const CAMPAIGN_TAG_TYPE__TAG = "campaignTag";
