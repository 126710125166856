import { Popover } from "antd";
import { AiOutlineFileSync } from "react-icons/ai";
import { Button, Divider } from "redcircle-ui";
import { CAMPAIGN_ITEM_OVERRIDE_FIELDS, overrideValueToFriendly } from "src/lib/overrides";
import { CampaignItemOverrideValue } from "src/reducers/campaign_items/types";

export default function OverridePopover({
  overrides = {},
}: {
  overrides?: Record<string, CampaignItemOverrideValue<any>>;
}) {
  return (
    <Popover
      content={
        <div>
          <h5>Overrides</h5>
          <Divider className="m-txxxs m-bxxxs" />
          <OverridesText overrides={overrides} />
        </div>
      }>
      <Button type="icon" size="small">
        <AiOutlineFileSync />
      </Button>
    </Popover>
  );
}

export const OverridesText = ({
  overrides,
}: {
  overrides: Record<string, CampaignItemOverrideValue<any>>;
}) => {
  return (
    <div>
      {Object.entries(overrides).map(([key, value]) => {
        return (
          <div key={key}>
            <strong>{CAMPAIGN_ITEM_OVERRIDE_FIELDS[key]}</strong>:{" "}
            {overrideValueToFriendly(key, value.default)} →{" "}
            {overrideValueToFriendly(key, value.value)}
          </div>
        );
      })}
    </div>
  );
};
