export const audioBlockTypes = {
  defaultCrossPromo: "default-cross-promo",
  defaultAd: "default-redcircle-ad",
  custom: "custom",
};

export const positionsCamel = ["preRoll", "midRoll", "postRoll"];

// Allowed file types for BE consumption

export const allowedAudioTypes = [".mp3", ".mp4", ".m4a"];
