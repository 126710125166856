import {
  DatePicker,
  Descriptions,
  Dropdown,
  DropdownProps,
  Input,
  MenuProps,
  Radio,
  RadioGroupProps,
  Select,
  Spin,
  Tag,
  TimeRangePickerProps,
  Tooltip,
  TreeSelect,
  TreeSelectProps,
} from "antd";
import {
  ReportingPageState,
  UTC,
  buildIntervalOptions,
  calcBestDateRange,
  getDefaultInterval,
  validateKeys,
} from "./utilities";
import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from "react";
import { ICampaign, ICampaignTag } from "redcircle-types";
import classes from "./advertiser_reporting_page.module.scss";
import dayjs from "dayjs";
import { isUUID } from "src/lib/uuid";
import {
  CloseCircleOutlined,
  CloseOutlined,
  FileAddOutlined,
  FolderFilled,
  LinkOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useGetCampaignTags, useGetCampaigns } from "src/hooks/campaigns";
import { useSelectorTS } from "src/hooks/redux-ts";
import { PublicShowsReduxState } from "src/reducers/public_show";
import { getSeedcastURL } from "src/lib/config";
import { copyToClipboard } from "redcircle-lib";
import { COLORS } from "redcircle-ui";
import { store } from "src";
import { showSuccess } from "src/actions/app";
import { LOCAL_STORAGE_KEYS, useLocalStorage } from "src/hooks/useLocalStorage";
import { canAdvertiserAccess } from "src/lib/permissions";
import { permissionTypes } from "src/constants/permission_roles";
import { RangePickerProps } from "antd/es/date-picker";
import ExclusiveTag from "src/components/lib/exclusive_tag/exclusive_tag";
import { isCampaignArchived } from "src/lib/campaigns";
import { primaryColor } from "src/constants/css";

/**
 * Constants
 */

const dateFormat = "MM/DD/YYYY";

const { RangePicker } = DatePicker;

export const AdvertisingReportControls = ({
  reportingState,
  setReportingState,
  showUUIDsNeeded,
}: {
  reportingState: ReportingPageState;
  setReportingState: Dispatch<SetStateAction<ReportingPageState>>;
  showUUIDsNeeded: string[];
}) => {
  const [localCampaignUUIDs, setLocalCampaignUUIDs] = useState(reportingState.campaignUUIDs);
  const [includeArchived, setIncludeArchived] = useState(false);

  const {
    campaigns,
    campaignsForUserInitialFetched,
    campaignsForUserIsLoading,
    campaignTagsIsLoading,
    campaignTagsInitialFetched,
  } = useGetCampaigns();
  const { campaignItemByCampaignUUID, campaignItems: campaignItemsMap } = useSelectorTS(
    (state) => state.campaignItems
  );
  const campaignTags = useGetCampaignTags();
  const userCampaigns = Object.values(campaigns).filter(
    (campaign) => {
      if (includeArchived) {
        return canAdvertiserAccess(permissionTypes.viewCampaignReporting, campaign);
      }

      return (
        canAdvertiserAccess(permissionTypes.viewCampaignReporting, campaign) &&
        !isCampaignArchived(campaign)
      );
    } // filters for permissions
  ) as ICampaign[];
  const publicShows = useSelectorTS((state) => state.publicShows);

  const campaignTreeOptions = useMemo(() => {
    return createCampaignAndTagTreeOptions(userCampaigns, campaignTags);
  }, [userCampaigns?.length, campaignTags?.length]);

  const podcastTreeOptions = useMemo(() => {
    return createPodcastTreeOptions(showUUIDsNeeded, publicShows);
  }, [showUUIDsNeeded?.length, publicShows?.isLoading]);

  const campaignTagsMap = useMemo(() => {
    return campaignTags.reduce(
      (accu, curr) => {
        if (!accu[curr.uuid]) accu[curr.uuid] = { ...curr };
        return accu;
      },
      {} as { [campaignTagUUID: string]: ICampaignTag }
    );
  }, [campaignTags?.length]);

  const start =
    reportingState.timeZone === UTC
      ? dayjs.unix(reportingState.dateRange[0]).utc()
      : dayjs.unix(reportingState?.dateRange[0]);
  const end =
    reportingState.timeZone === UTC
      ? dayjs.unix(reportingState.dateRange[1]).utc()
      : dayjs.unix(reportingState.dateRange[1]);

  const isPodcastDropdownReady =
    showUUIDsNeeded.every((showUUID) => !!publicShows[showUUID]) &&
    reportingState.campaignUUIDs.every((campaignUUID) => {
      return campaignItemByCampaignUUID[campaignUUID]?.every(
        (campaignItemUUID) => !!campaignItemsMap[campaignItemUUID]
      );
    });
  const disablePodcasts =
    reportingState.campaignUUIDs?.length === 0 ||
    reportingState.groupBy !== "showUUID" ||
    !isPodcastDropdownReady;

  const placeHolder =
    reportingState.campaignUUIDs?.length === 0
      ? "Please select a campaign first"
      : isPodcastDropdownReady
        ? ""
        : "Loading Podcasts";

  const numOfCampaigns = reportingState?.campaignUUIDs?.length;
  const numOfPodcasts = reportingState?.showUUIDs?.length;

  const intervalOptions = buildIntervalOptions(reportingState.dateRange);

  /**
   * Helper funcs
   */

  const isCampaignTag = (uuid: string) => !!campaignTagsMap?.[uuid];

  const isCampaignLoading = (uuid: string) =>
    !campaigns?.[uuid] || campaignsForUserIsLoading || !campaignsForUserInitialFetched;

  const isCampaignTagLoading = (uuid: string) =>
    !campaignTagsMap?.[uuid] || campaignTagsIsLoading || !campaignTagsInitialFetched;

  /**
   * Sync local campaignUUIDs with reporting state
   */
  useEffect(() => {
    setLocalCampaignUUIDs(reportingState.campaignUUIDs);
  }, [reportingState.campaignUUIDs]);

  /**
   * Adding a timer, basically if user picks campaigns and does not close the dropdown, will do a sync check to update report state
   */
  useEffect(() => {
    const syncState = () => {
      if (!areArraysEqual(localCampaignUUIDs, reportingState.campaignUUIDs)) {
        setReportingState((prev) => {
          return { ...prev, campaignUUIDs: localCampaignUUIDs };
        });
      }
    };

    const timer = setTimeout(() => {
      syncState();
    }, 1000 * 15);

    return () => {
      clearTimeout(timer);
    };
  }, [localCampaignUUIDs.length, reportingState.campaignUUIDs?.length]);

  /**
   * Handler
   */

  const handleIncludeArchived = () => setIncludeArchived((prev) => !prev);

  const handleCampaignSelect: TreeSelectProps<
    string,
    (typeof campaignTreeOptions)[number]
  >["onSelect"] = (value, node) => {
    switch (node.type) {
      case "campaign":
        setLocalCampaignUUIDs((prev) => {
          const newCampaignUUIDs = new Set(prev);
          newCampaignUUIDs.add(value);
          const campaignUUIDs = [...newCampaignUUIDs];

          return campaignUUIDs;
        });

        break;
      case "tag":
        const childCampaigns =
          node.children
            ?.map((item) => (item.type === "campaign" ? item.value : ""))
            .filter((uuid) => isUUID(uuid)) ?? [];

        setLocalCampaignUUIDs((prev) => {
          const newCampaignUUIDs = new Set(prev);

          for (const uuid of childCampaigns) {
            newCampaignUUIDs.add(uuid);
          }

          const campaignUUIDs = [...newCampaignUUIDs];

          return campaignUUIDs;
        });

        break;
    }
  };

  const handleCampaignDeSelect: TreeSelectProps<
    string,
    (typeof campaignTreeOptions)[number]
  >["onDeselect"] = (value, node) => {
    switch (node.type) {
      case "campaign":
        setLocalCampaignUUIDs((prev) => {
          const newCampaignUUIDs = new Set(prev);
          newCampaignUUIDs.delete(value);
          const campaignUUIDs = [...newCampaignUUIDs];
          return campaignUUIDs;
        });

        break;
      case "tag":
        const childCampaigns =
          node.children
            ?.map((item) => (item.type === "campaign" ? item.value : ""))
            .filter((uuid) => isUUID(uuid)) ?? [];

        setLocalCampaignUUIDs((prev) => {
          const newCampaignUUIDs = new Set(prev);

          for (const uuid of childCampaigns) {
            newCampaignUUIDs.delete(uuid);
          }
          const campaignUUIDs = [...newCampaignUUIDs];
          return campaignUUIDs;
        });

        break;
    }
  };

  // Will update report state for campaign UUIDs selected on dropdown close
  // this delays/controls multiple data fetching operations
  const onDropdownVisibleChange: TreeSelectProps<
    string,
    (typeof campaignTreeOptions)[number]
  >["onDropdownVisibleChange"] = (open) => {
    if (!open) {
      if (!areArraysEqual(localCampaignUUIDs, reportingState.campaignUUIDs)) {
        setReportingState((prev) => {
          return { ...prev, campaignUUIDs: localCampaignUUIDs };
        });
      }
    }
  };

  const campaignTagRender: TreeSelectProps<
    string,
    (typeof campaignTreeOptions)[number]
  >["tagRender"] = (props) => {
    const { onClose, value, isMaxTag, label } = props;

    /**
     * For responsive tag renders, if view screen is too small
     * will auto truncate all tags into 1 tag with no uuid value
     */
    if (!isUUID(value) && isMaxTag && typeof label === "string") {
      return (
        <Tag
          closeIcon={<CloseOutlined />}
          title={label}
          onClose={onClose}
          className="flex-row-container align-center fs-13"
          bordered={false}
          style={{ background: COLORS.GRAY_LIGHTER }}>
          {label}
        </Tag>
      );
    }

    const isTag = isCampaignTag(value);

    const isStillLoading = isTag ? isCampaignTagLoading(value) : isCampaignLoading(value);

    const title = isTag ? campaignTagsMap?.[value]?.title : campaigns?.[value]?.name;

    return (
      <Tag
        closeIcon={<CloseOutlined />}
        title={title}
        onClose={onClose}
        className="flex-row-container align-center fs-13"
        bordered={false}
        style={{ background: COLORS.GRAY_LIGHTER }}>
        {isTag && <FolderFilled style={{ color: COLORS.GRAY_DARK }} className="m-rxxs m-bxxxs" />}
        <Spin size="small" spinning={isStillLoading}>
          {title}
        </Spin>
      </Tag>
    );
  };

  const podcastTagRender: TreeSelectProps<
    string,
    (typeof campaignTreeOptions)[number]
  >["tagRender"] = (props, ...rest) => {
    const { onClose, label, value } = props;

    const isStillLoading =
      typeof label === "string" ? label.substring(0, 7) === value?.substring(0, 7) : false;

    return (
      <Tag
        closeIcon={<CloseOutlined />}
        onClose={onClose}
        className="flex-row-container align-center fs-13"
        bordered={false}
        style={{ background: COLORS.GRAY_LIGHTER }}>
        <Spin size="small" spinning={isStillLoading}>
          {label}
        </Spin>
      </Tag>
    );
  };

  const treeTitleRender: TreeSelectProps["treeTitleRender"] = (node: any) => {
    const showArchiveTag = node?.type === "campaign" && node?.isArchived;

    return (
      <span>
        {node?.title}{" "}
        {showArchiveTag && (
          <ExclusiveTag
            className="m-lxxs"
            color="rgb(128, 128, 128)"
            backgroundRGB={[149, 149, 149]}>
            [ARCHIVED]
          </ExclusiveTag>
        )}
      </span>
    );
  };

  const onPodcastSelect: TreeSelectProps<
    string,
    (typeof podcastTreeOptions)[number]
  >["onSelect"] = (value, node) => {
    setReportingState((prev) => {
      const newShowUUIDs = new Set(prev.showUUIDs);
      newShowUUIDs.add(value);
      return { ...prev, showUUIDs: [...newShowUUIDs] };
    });
  };

  const onPodcastDeSelect: TreeSelectProps<
    string,
    (typeof podcastTreeOptions)[number]
  >["onDeselect"] = (value, node) => {
    setReportingState((prev) => {
      const newShowUUIDs = new Set(prev.showUUIDs);
      newShowUUIDs.delete(value);
      return { ...prev, showUUIDs: [...newShowUUIDs] };
    });
  };

  const onTimeZoneChange: RadioGroupProps["onChange"] = (event) => {
    const newTimeZone = event.target.value;
    setReportingState((prev) => {
      const startString =
        prev.timeZone === UTC
          ? dayjs.unix(prev.dateRange[0]).utc().format("MM/DD/YYYY")
          : dayjs.unix(prev.dateRange[0]).format("MM/DD/YYYY");
      const endString =
        prev.timeZone === UTC
          ? dayjs.unix(prev.dateRange[1]).utc().format("MM/DD/YYY")
          : dayjs.unix(prev.dateRange[1]).format("MM/DD/YYYY");
      const newDates = getNewDateRanges([startString, endString], newTimeZone);

      return { ...prev, timeZone: newTimeZone, dateRange: newDates };
    });
  };

  const onDateRangeChange: RangePickerProps["onCalendarChange"] = (_, dateStrings, { range }) => {
    setReportingState((prev) => {
      const newVal = getNewDateRanges(dateStrings, reportingState.timeZone);

      const changeNeeded = !buildIntervalOptions(newVal)
        .map((item) => item.value)
        .includes(prev.interval);

      return {
        ...prev,
        dateRange: newVal,
        interval: changeNeeded ? getDefaultInterval(newVal) : prev.interval,
      };
    });
  };

  // Mon Jan 01 2018 00:00:00 GMT-0800 (Pacific Standard Time), pretty sure we cant go earlier than this.
  // The company was founded on 2018
  const earliestDatePossible = 1514793600;

  const bestDateRange = calcBestDateRange(reportingState.campaignUUIDs, reportingState.timeZone);

  let rangePresets: TimeRangePickerProps["presets"] =
    reportingState.timeZone === UTC
      ? [
          {
            label: "Last 24 Hours",
            value: [dayjs().utc(true).startOf("day").add(-24, "h"), dayjs().utc(true).endOf("day")],
          },
          {
            label: "Last 48 Hours",
            value: [dayjs().utc(true).startOf("day").add(-48, "h"), dayjs().utc(true).endOf("day")],
          },
          {
            label: "Last 7 Days",
            value: [dayjs().utc(true).startOf("day").add(-7, "d"), dayjs().utc(true).endOf("day")],
          },
          {
            label: "Last 30 Days",
            value: [dayjs().utc(true).startOf("day").add(-30, "d"), dayjs().utc(true).endOf("day")],
          },
          {
            label: "Last 60 Days",
            value: [dayjs().utc(true).startOf("day").add(-60, "d"), dayjs().utc(true).endOf("day")],
          },
          {
            label: "Last 90 Days",
            value: [dayjs().utc(true).startOf("day").add(-90, "d"), dayjs().utc(true).endOf("day")],
          },
          {
            label: "Last 12 Months",
            value: [
              dayjs().utc(true).startOf("day").add(-12, "months"),
              dayjs().utc(true).endOf("day"),
            ],
          },
          {
            label: "All time",
            value: [dayjs.unix(earliestDatePossible), dayjs().utc(true).endOf("day")],
          },
        ]
      : [
          {
            label: "Last 24 Hours",
            value: [dayjs().startOf("day").add(-24, "h"), dayjs().endOf("day")],
          },
          {
            label: "Last 48 Hours",
            value: [dayjs().startOf("day").add(-48, "h"), dayjs().endOf("day")],
          },
          {
            label: "Last 7 Days",
            value: [dayjs().startOf("day").add(-7, "d"), dayjs().endOf("day")],
          },
          {
            label: "Last 30 Days",
            value: [dayjs().startOf("day").add(-30, "d"), dayjs().endOf("day")],
          },
          {
            label: "Last 60 Days",
            value: [dayjs().startOf("day").add(-60, "d"), dayjs().endOf("day")],
          },
          {
            label: "Last 90 Days",
            value: [dayjs().startOf("day").add(-90, "d"), dayjs().endOf("day")],
          },
          {
            label: "Last 12 Months",
            value: [dayjs().startOf("day").add(-12, "months"), dayjs().endOf("day")],
          },
          { label: "All time", value: [dayjs.unix(earliestDatePossible), dayjs().endOf("day")] },
        ];

  if (Array.isArray(bestDateRange) && Array.isArray(rangePresets)) {
    rangePresets = [{ label: "Campaign Timeline", value: bestDateRange }, ...rangePresets];
  }

  return (
    <>
      <div className="flex-row-container align-center justify-between width-100 m-bxxs">
        <div className="m-ra flex-row-container align-center justify-start"></div>
        <Tooltip title="Include Archived Campaigns" placement="topLeft">
          <FileAddOutlined
            onClick={handleIncludeArchived}
            className="m-rxs"
            style={{ color: includeArchived ? primaryColor : COLORS.BLUE_MEDIUM, fontSize: 15 }}
          />
        </Tooltip>
        <Tooltip title="Copy Share URL" placement="topLeft">
          <LinkOutlined
            onClick={handleCopyLink(reportingState)}
            className="m-rxs"
            style={{ color: COLORS.BLUE_MEDIUM, fontSize: 15 }}
          />
        </Tooltip>
        <SaveConfigs reportingState={reportingState} setReportingState={setReportingState} />
      </div>
      <div className={classes.controls_container}>
        <Descriptions
          size="small"
          className="width-100"
          bordered
          column={{ xs: 1, sm: 1, md: 1, lg: 3, xl: 3 }}
          items={[
            {
              key: "Campaigns",
              label: `Campaigns${numOfCampaigns > 0 ? `: (${numOfCampaigns})` : ""}`,
              span: 3,
              children: (
                <TreeSelect
                  style={{ maxHeight: "32px" }}
                  className="width-100"
                  value={localCampaignUUIDs}
                  treeData={campaignTreeOptions}
                  multiple
                  showSearch
                  treeNodeFilterProp="title"
                  onSelect={handleCampaignSelect}
                  onDeselect={handleCampaignDeSelect}
                  onDropdownVisibleChange={onDropdownVisibleChange}
                  showCheckedStrategy="SHOW_PARENT"
                  treeCheckable={true}
                  tagRender={campaignTagRender}
                  treeIcon={true}
                  maxTagTextLength={30}
                  maxTagCount="responsive"
                  treeTitleRender={treeTitleRender}
                />
              ),
            },
            {
              key: "DateRange",
              label: "Date Range",
              span: 3,
              children: (
                <div className="flex-row-container align-center">
                  <RangePicker
                    size="small"
                    format={dateFormat}
                    value={[start, end]}
                    presets={rangePresets}
                    onCalendarChange={onDateRangeChange}
                  />
                </div>
              ),
            },
            {
              key: "GroupBy",
              label: "Group By",
              children: (
                <Select
                  size="small"
                  className="width-100"
                  style={{ width: "100px" }}
                  value={reportingState.groupBy}
                  onChange={(value) => {
                    setReportingState((prev) => {
                      return { ...prev, groupBy: value };
                    });
                  }}
                  options={[
                    { label: "Campaign", value: "campaignUUID" },
                    { label: "Podcast", value: "showUUID" },
                    { label: "None", value: "none" },
                  ]}
                />
              ),
            },
            {
              key: "interval",
              label: "Interval",
              children: (
                <Radio.Group
                  value={reportingState.interval}
                  optionType="button"
                  buttonStyle="solid"
                  size="small"
                  onChange={(event) => {
                    setReportingState((prev) => {
                      return { ...prev, interval: event.target.value };
                    });
                  }}
                  options={intervalOptions}
                />
              ),
            },
            {
              key: "timezone",
              label: "Timezone",
              children: (
                <Radio.Group
                  value={reportingState.timeZone}
                  optionType="button"
                  buttonStyle="solid"
                  size="small"
                  onChange={onTimeZoneChange}
                  options={[
                    { label: UTC, value: UTC },
                    { label: "Current", value: dayjs.tz.guess() },
                  ]}
                />
              ),
            },

            {
              key: "Podcasts",
              label: `Podcasts${numOfPodcasts > 0 ? `: (${numOfPodcasts}/${showUUIDsNeeded.length})` : ""}`,
              span: 3,
              children: (
                <TreeSelect
                  className="width-100"
                  value={reportingState.showUUIDs}
                  treeData={podcastTreeOptions}
                  disabled={disablePodcasts}
                  placeholder={placeHolder}
                  multiple
                  showSearch
                  treeNodeFilterProp="title"
                  onSelect={onPodcastSelect}
                  onDeselect={onPodcastDeSelect}
                  tagRender={podcastTagRender}
                  maxTagTextLength={20}
                  maxTagCount={"responsive"}
                />
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

/**
 * Menu for saving graph control configuration in local storage
 */
export const SaveConfigs = ({
  reportingState,
  setReportingState,
}: {
  reportingState: ReportingPageState;
  setReportingState: Dispatch<SetStateAction<ReportingPageState>>;
}) => {
  const SAVED_CONFIG_LIMIT = 10;
  const [savedConfigs, setSavedConfigs] = useLocalStorage(
    LOCAL_STORAGE_KEYS.advertising_report_saved_configs,
    []
  );
  const [configName, setConfigName] = useState("");
  const [open, setOpen] = useState(false); // open Dropdown

  const handleOpenChange: DropdownProps["onOpenChange"] = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen);
    }
  };

  /**
   * Set graph configuration from locally saved config
   */
  const handleUpdateState = (state: ReportingPageState) => () => {
    setReportingState({ ...state });
  };

  const handleDeleteLocalConfig = ({
    name,
    createdAt,
  }: {
    name: string;
    state: ReportingPageState;
    createdAt: number;
  }) => {
    setSavedConfigs((prev) => {
      const newItems = Array.isArray(prev)
        ? [...prev].filter((config) => {
            return !(config.name === name && config.createdAt === createdAt);
          })
        : [];

      return newItems;
    });
  };

  const handleSaveLocalConfig = () => {
    setSavedConfigs((prev) => {
      const items = Array.isArray(prev) ? [...prev] : [];

      const name = configName?.length === 0 ? dayjs().format("MM/DD/YYYY hh:mm:ss ") : configName;

      items.push({
        name: name,
        createdAt: dayjs().unix(),
        state: reportingState,
      });

      return items;
    });
    setConfigName("");
  };

  let saveItems: MenuProps["items"] = Array.isArray(savedConfigs)
    ? savedConfigs?.map((savedConfig) => {
        const { name, createdAt, state } = savedConfig;
        return {
          key: `${name}_${createdAt}`,
          title: name,
          label: (
            <div
              className="width-100 flex-row-container align-center justify-space-between"
              onClick={handleUpdateState(state)}>
              {name}{" "}
              <CloseCircleOutlined
                style={{ fontSize: 16 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDeleteLocalConfig(savedConfig);
                }}
              />
            </div>
          ),
        };
      })
    : [];

  if (saveItems.length < SAVED_CONFIG_LIMIT) {
    saveItems = saveItems.concat([
      {
        key: "add_items",
        title: "add items",
        label: (
          <div className="flex-row-container align-center ">
            <Input
              placeholder="Name for saved configuration."
              maxLength={40}
              showCount
              onPressEnter={handleSaveLocalConfig}
              onChange={(e) => {
                setConfigName(e.target.value);
              }}
            />
            <UploadOutlined
              className="m-ls"
              style={{ fontSize: 16 }}
              onClick={handleSaveLocalConfig}
            />
          </div>
        ),
      },
    ]);
  }

  return (
    <Dropdown
      menu={{ items: saveItems }}
      trigger={["click"]}
      onOpenChange={handleOpenChange}
      open={open}>
      <Tooltip title="Save Report to Browser" placement="topLeft">
        <SaveOutlined style={{ color: COLORS.BLUE_MEDIUM, fontSize: 15 }} />
      </Tooltip>
    </Dropdown>
  );
};

const createCampaignAndTagTreeOptions = (campaigns: ICampaign[], campaignTags: ICampaignTag[]) => {
  const campaignMap: { [campaignUUID: string]: ICampaign } = {};
  const parentFolderToChildCampaignMap: { [parentFolderUUID: string]: string[] } = {};
  const campaignOptions: {
    value: string;
    title: string;
    type: "tag" | "campaign";
    isArchived?: boolean;
  }[] = [];

  for (const campaign of campaigns) {
    campaignMap[campaign.uuid] = { ...campaign };
    if (
      Array.isArray(campaign?.parentCampaignTagUUIDs) &&
      campaign?.parentCampaignTagUUIDs?.length > 0
    ) {
      if (!Array.isArray(parentFolderToChildCampaignMap[campaign?.parentCampaignTagUUIDs?.[0]])) {
        parentFolderToChildCampaignMap[campaign?.parentCampaignTagUUIDs?.[0]] = [];
      }
      parentFolderToChildCampaignMap[campaign?.parentCampaignTagUUIDs?.[0]].push(campaign.uuid);
    } else {
      campaignOptions.push({
        value: campaign.uuid,
        title: campaign.name,
        type: "campaign",
        isArchived: campaign.archived,
      });
    }
  }

  const options = [...campaignTags]
    .sort((a, b) => a.title?.localeCompare(b.title))
    .map((tag) => {
      const option: {
        value: string;
        title: string;
        type: "tag" | "campaign";
        icon?: ReactNode;
        isArchived?: boolean;
        children?: {
          value: string;
          title: string;
          type: "tag" | "campaign";
          isArchived?: boolean;
        }[];
      } = {
        value: tag.uuid,
        title: tag.title,
        type: "tag",
        icon: <FolderFilled style={{ color: COLORS.GRAY_DARK }} />,
      };

      const childCampaigns = parentFolderToChildCampaignMap[tag.uuid];

      if (Array.isArray(childCampaigns) && childCampaigns.length > 0) {
        option.children = childCampaigns.map((campaignUUID) => {
          return {
            value: campaignMap[campaignUUID].uuid,
            title: campaignMap[campaignUUID]?.name,
            isArchived: campaignMap[campaignUUID]?.archived,
            label: "hello",
            type: "campaign",
          };
        });
      }

      return option;
    })
    .concat(campaignOptions?.sort((a, b) => a.title?.localeCompare(b.title)));

  return options;
};

const createPodcastTreeOptions = (showUUIDs: string[] = [], publicShows: PublicShowsReduxState) => {
  return showUUIDs
    ?.map((showUUID) => {
      return {
        value: showUUID,
        title: publicShows?.[showUUID]?.title ?? "",
      };
    })
    .sort((a, b) => a.title?.localeCompare(b.title));
};

/**
 *  Helper function, based on provided reporting page state, creates a url object.
 */
export const generateAdvertisingReportCopyLink = (state: Partial<ReportingPageState>) => {
  const url = new URL(`${getSeedcastURL()}reporting`);

  const params = url.searchParams;
  state.dateRange &&
    validateKeys(state, ["dateRange"]) &&
    params.set("dateRange", state.dateRange.map((i) => i.toString()).join(","));

  state.campaignUUIDs &&
    validateKeys(state, ["campaignUUIDs"]) &&
    params.set("campaignUUIDs", state.campaignUUIDs.join(","));

  state.showUUIDs &&
    validateKeys(state, ["showUUIDs"]) &&
    params.set("showUUIDs", state.showUUIDs.join(","));

  state.groupBy && validateKeys(state, ["groupBy"]) && params.set("groupBy", state.groupBy);
  state.interval && validateKeys(state, ["interval"]) && params.set("interval", state.interval);

  state.graphFocus &&
    validateKeys(state, ["graphFocus"]) &&
    params.set("graphFocus", state.graphFocus);

  state.timeZone && params.set("timeZone", state.timeZone);

  return url;
};

/**
 * Generates url copy link and saves it to clipboard
 * @param {ReportingPageState}
 * @returns {void}
 */
export const handleCopyLink = (state: ReportingPageState) => () => {
  const link = generateAdvertisingReportCopyLink(state).toString();

  copyToClipboard(link);

  store.dispatch(showSuccess("Copied current report settings URL to clipboard!"));
};

/**
 * Helper func for controls sections,
 * Based on date strings and utc value, will calculate date range start,end boundaries
 */
const getNewDateRanges = (
  dateStrings: [string, string],
  timeZone: ReportingPageState["timeZone"]
) => {
  const start =
    timeZone === UTC
      ? dayjs.utc(dateStrings[0], "MM/DD/YYYY").startOf("day").unix()
      : dayjs(dateStrings[0], "MM/DD/YYYY").startOf("day").unix();
  const end =
    timeZone === UTC
      ? dayjs.utc(dateStrings[1], "MM/DD/YYYY").endOf("day").unix()
      : dayjs(dateStrings[1], "MM/DD/YYYY").endOf("day").unix();

  return [start, end] as [number, number];
};

/**
 * Compare sets
 */

const areArraysEqual = (a: string[], b: string[]) => {
  const as = new Set(a);
  const bs = new Set(b);

  return as.size === bs.size && [...as].every((x) => bs.has(x));
};
