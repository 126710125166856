import fetch from "cross-fetch";
import get from "lodash/get";
import { stringify } from "query-string";
import getAPIPrefix from "../lib/api_prefix";
import { getUserTimezone } from "../lib/timezones";
import { User } from "redcircle-types";
import { StatsRequestFilter } from "src/components/pages/analytics/analyticsUtility";

function fetchDownloads({
  filters,
  user,
  scalar = false,
}: {
  filters: StatsRequestFilter;
  user: User;
  scalar?: boolean;
}) {
  if (!get(filters, "timezone")) {
    filters.timezone = getUserTimezone();
  }

  let endpoint = "downloads";
  if (scalar) {
    endpoint += "-scalar";
  }

  return fetchStats({ endpoint, filters, user });
}

function fetchInsertions({
  filters,
  user,
  scalar = false,
}: {
  filters: StatsRequestFilter;
  user: User;
  scalar?: boolean;
}) {
  if (!get(filters, "timezone")) {
    filters.timezone = getUserTimezone();
  }

  // The default is UTC when no timezone is provided to the endpoint
  if (get(filters, "timezone") === "utc") {
    delete filters.timezone;
  }

  let endpoint = "insertions";
  if (scalar) {
    endpoint += "-scalar";
  }

  return fetchStats({ endpoint, filters, user });
}

function fetchStats({
  endpoint,
  filters,
  user,
}: {
  endpoint: string;
  filters: StatsRequestFilter;
  user: User;
}) {
  const query = stringify(filters);

  return fetch(getAPIPrefix() + "stats/" + endpoint + "?" + query, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + user.authToken,
    },
  });
}

/**
 * New Request filter for insertions/downloads endpoints.
 * Passes filter as body instead of query params
 */
export type StatsRequestFilterWBody = {
  app?: string;
  country?: string;
  device?: string;
  os?: string;
  showUUID?: string;
  episodeUUID?: string;
  isUnique?: boolean;
  adUUID?: string;
  campaignUUIDs: string[];
  creatorUUID?: string;
  advertiserUUID?: string;
  timeRange?: string;
  timezone?: Exclude<string, "utc"> | "UTC"; // TODO update typescript
  arbitraryTimeRange?: string;
  bucketTerms: string[];
  sumterms?: string;
  downloadsGeoBoundingBox?: {
    top_left: { lat: number; lon: number };
    bottom_right: { lat: number; lon: number };
  };
  isProgrammatic?: boolean;
  nonExistTerms?: string[];
  permittedShowUUIDs?: string[];
  interval?: "1h" | "1d" | "1w" | "1M" | "1y" | "1q";
};

/**
 * Final layer check on filters parameters.  In the future if there are more checks for lets say timezones or sumTerms, this
 * would be the place to add it to ensure api always works.
 */
const checkFilters: (filters: StatsRequestFilterWBody) => StatsRequestFilterWBody = (filters) => {
  const newFilters = structuredClone(filters);

  if (newFilters.timezone === "utc") {
    newFilters.timezone = "UTC";
  }

  return filters;
};

const fetchStatsWithBody = ({
  endpoint,
  filters,
  user,
}: {
  endpoint: "insertions" | "downloads";
  filters: StatsRequestFilterWBody;
  user: User;
}) => {
  return fetch(getAPIPrefix() + "stats/" + endpoint, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + user.authToken,
    },
    body: JSON.stringify(checkFilters(filters)),
  });
};

const statsAPI = {
  fetchDownloads,
  fetchInsertions,
  fetchStatsWithBody,
};

export default statsAPI;
