import React, { Suspense, lazy, useCallback, useEffect, useState } from "react";
import {
  AdvertisingGraphDataPoint,
  RawData,
  ReportingPageState,
  SPEND_DATE_BOUNDARY_START,
  UTC,
  createCacheKeyFromState,
  exportToExcel,
  priceToCent,
  validateKeys,
} from "./utilities";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { InsertionsStatsWBodyActionManager } from "src/action_managers/stats";
import { yAxisLabelFormatter } from "../analytics/analyticsUtility";
import { UnixTimeStamp } from "src/lib/date";
import RCArea from "../analytics/RedCircle_graphs/Area";
import numeral from "numeral";
import { PublicShowsReduxState } from "src/reducers/public_show";
import { ICampaign } from "src/reducers/campaigns/types";
import { formatMoney } from "redcircle-lib";
import { colors } from "src/lib/design";
import { Alert, Col, Descriptions, Row, Spin, Switch, Tooltip } from "antd";
import { primaryColor } from "src/constants/css";
import classes from "./advertiser_reporting_page.module.scss";
import { classNames } from "react-extras";
import { AreaChartOutlined, FundOutlined } from "@ant-design/icons";
import dayjs, { ManipulateType } from "dayjs";
import { debounce } from "lodash";
import { isCampaignItemAccepted } from "src/lib/campaigns";
import { showInfo } from "src/actions/app";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { COLORS } from "redcircle-ui";
import { StatsRequestFilterWBody } from "src/api/stats";

const Area = lazy(() => import("@ant-design/plots/es/components/area"));

const FETCH_INSERTION_STATS_DEBOUNCE_WAIT_MILLISECONDS = 1700;

export const AdvertisingReportGraph = ({
  reportingState,
  showUUIDsNeeded,
  graphFocus,
  handleChangeGraphFocus,
}: {
  reportingState: ReportingPageState;
  showUUIDsNeeded: string[];
  graphFocus: ReportingPageState["graphFocus"];
  handleChangeGraphFocus: (graphFocus: ReportingPageState["graphFocus"]) => void;
}) => {
  const dispatch = useReduxDispatch();
  const { user } = useSelectorTS((state) => state.user);

  const [graphConfig, setGraphConfig] = useState<{ stack: boolean; nice: boolean }>({
    stack: false,
    nice: false,
  });

  const cacheInsertionDataKey = createCacheKeyFromState({
    prefix: "ARGI", // Advertising Report Graph
    state: reportingState,
    keys: ["dateRange", "campaignUUIDs", "interval", "timeZone"],
  });

  const cacheSpendDataKey = createCacheKeyFromState({
    prefix: "ARGS", // Advertising Report Graph
    state: reportingState,
    keys: ["dateRange", "campaignUUIDs", "interval", "timeZone"],
  });
  const cachedData = useSelectorTS((state) => state.stats?.stats);
  const insertionRawData = cachedData[cacheInsertionDataKey];
  const spendRawData = cachedData[cacheSpendDataKey];

  const campaigns = useSelectorTS((state) => state.campaigns.campaigns);
  const { campaignItemByCampaignUUID, campaignItems: campaignItemsMap } = useSelectorTS(
    (state) => state.campaignItems
  );
  const publicShows = useSelectorTS((state) => state.publicShows);

  const budgetByCampaignUUID = reportingState.campaignUUIDs.reduce(
    (accu, campaignUUID) => {
      const campaignItemUUIDs = campaignItemByCampaignUUID?.[campaignUUID] ?? [];
      const totalBudget = campaignItemUUIDs.reduce((accu, campaignItemUUID) => {
        const item = campaignItemsMap?.[campaignItemUUID];
        const budget = typeof item?.totalBudget === "number" ? item.totalBudget / 100 : 0;

        if (isCampaignItemAccepted(item)) {
          accu += budget;
        }

        return accu;
      }, 0);

      accu[campaignUUID] = totalBudget;

      return accu;
    },
    {} as { [campaignUUID: string]: number }
  );

  const allowedPodcasts = reportingState.showUUIDs.reduce(
    (accu, curr) => {
      if (!accu[curr]) {
        accu[curr] = true;
      }
      return accu;
    },
    {} as { [showUUID: string]: true }
  );

  const [campaignInsertionData, podcastInsertionData, allInsertionData] = transformData({
    rawData: insertionRawData,
    type: "insertion",
    campaigns,
    publicShows,
    reportingState,
    showUUIDsNeeded,
  });

  const [campaignSpendData, podcastSpendData, allSpendData] = transformData({
    rawData: spendRawData,
    type: "spend",
    campaigns,
    publicShows,
    reportingState,
    showUUIDsNeeded,
  });

  const isCampaignItemsReady = reportingState.campaignUUIDs.every((campaignUUID) => {
    return campaignItemByCampaignUUID[campaignUUID]?.every(
      (campaignItemUUID) => !!campaignItemsMap[campaignItemUUID]
    );
  });

  const isPodcastsReady = showUUIDsNeeded.every((showUUID) => !!publicShows[showUUID]);

  const isInsertionDataLoading = useSelectorTS((state) =>
    state.stats.loadingUUIDs?.includes(cacheInsertionDataKey)
  );

  const isSpendDataLoading = useSelectorTS((state) =>
    state.stats.loadingUUIDs?.includes(cacheSpendDataKey)
  );

  const isDataLoaded =
    reportingState.campaignUUIDs.length > 0
      ? graphFocus === "insertion"
        ? !isInsertionDataLoading && Array.isArray(insertionRawData)
        : !isSpendDataLoading && Array.isArray(spendRawData)
      : true;

  const isLoading = !isCampaignItemsReady || !isPodcastsReady || !isDataLoaded;

  let dataInUse = graphFocus === "insertion" ? allInsertionData : allSpendData;

  switch (reportingState.groupBy) {
    case "campaignUUID":
      dataInUse = graphFocus === "insertion" ? campaignInsertionData : campaignSpendData;
      break;
    case "showUUID":
      dataInUse =
        graphFocus === "insertion"
          ? podcastInsertionData.filter((dataPoint) => {
              return dataPoint.showUUID ? !!allowedPodcasts[dataPoint?.showUUID] : false;
            })
          : podcastSpendData.filter((dataPoint) => {
              return dataPoint.showUUID ? !!allowedPodcasts[dataPoint?.showUUID] : false;
            });
      break;
    default:
      dataInUse = graphFocus === "insertion" ? allInsertionData : allSpendData;
  }

  /**
   * Debounced Insertions data fetch function
   */
  const debouncedFetchInsertionData = useCallback(
    debounce((filters: StatsRequestFilterWBody, cacheKey: string) => {
      dispatch(new InsertionsStatsWBodyActionManager({ filters, requestID: cacheKey }).run());
    }, FETCH_INSERTION_STATS_DEBOUNCE_WAIT_MILLISECONDS),
    []
  );

  /**
   * Debounced Spend data fetch function
   */
  const debouncedFetchSpendData = useCallback(
    debounce((filters: StatsRequestFilterWBody, cacheKey: string) => {
      dispatch(new InsertionsStatsWBodyActionManager({ filters, requestID: cacheKey }).run());
    }, FETCH_INSERTION_STATS_DEBOUNCE_WAIT_MILLISECONDS),
    []
  );

  /**
   * Fetch insertion data for graph
   */
  useEffect(() => {
    if (!validateKeys(reportingState, ["dateRange", "campaignUUIDs", "interval", "timeZone"]))
      return;

    if (!cachedData[cacheInsertionDataKey]) {
      const filters: StatsRequestFilterWBody = {
        isUnique: true,
        arbitraryTimeRange: reportingState?.dateRange.join(","),
        interval: reportingState.interval,
        campaignUUIDs: reportingState.campaignUUIDs,
        bucketTerms: ["insertion.campaignUUID", "insertion.showUUID"],
        timezone: reportingState.timeZone,
      };

      debouncedFetchInsertionData(filters, cacheInsertionDataKey);
    }
  }, [cacheInsertionDataKey]);

  /**
   * Fetch spend data for graph
   */
  useEffect(() => {
    if (!validateKeys(reportingState, ["dateRange", "campaignUUIDs", "interval", "timeZone"]))
      return;

    if (!cachedData[cacheSpendDataKey]) {
      const filters: StatsRequestFilterWBody = {
        isUnique: true,
        arbitraryTimeRange: reportingState?.dateRange.join(","),
        interval: reportingState.interval,
        campaignUUIDs: reportingState.campaignUUIDs,
        bucketTerms: ["insertion.campaignUUID", "insertion.showUUID"],
        sumterms: "insertion.price",
        timezone: reportingState.timeZone,
      };

      debouncedFetchSpendData(filters, cacheSpendDataKey);
    }
  }, [cacheSpendDataKey]);

  const getNameForEntity = (entityUUID: string) => {
    switch (reportingState["groupBy"]) {
      case "campaignUUID":
        return campaigns[entityUUID]?.name;
      case "showUUID":
        return publicShows[entityUUID]?.title ?? "";
      case "none":
      default:
        return reportingState["groupBy"];
    }
  };

  const handleExcelDownload = () => {
    if (isLoading) {
      dispatch(showInfo("Data is still loading, please wait a few seconds and download again."));
    } else {
      exportToExcel({
        campaignSpendData,
        campaignInsertionData,
        podcastSpendData,
        podcastInsertionData,
        allSpendData,
        allInsertionData,
        reportingState,
        budgetByCampaignUUID,
        showUUIDsNeeded,
      });
    }
  };

  const stackTooltip = `${graphConfig.stack ? "Unstack" : "Stack"}`;

  const niceToolTip = `${graphConfig.nice ? "Un-Zoom" : "Zoom to Fit"}`;

  const showSpendCalcWarning =
    reportingState.graphFocus === "spend"
      ? reportingState.dateRange[0] < SPEND_DATE_BOUNDARY_START
      : false;

  return (
    <div>
      <Descriptions
        size="small"
        className={classNames(classes.item_container, "width-100")}
        extra={
          <div className="flex-row-container align-center">
            <div onClick={handleExcelDownload} className="pointer">
              <Tooltip title="Download report." placement="topLeft">
                <MdOutlineDownloadForOffline style={{ fontSize: 15, color: COLORS.BLUE_MEDIUM }} />
              </Tooltip>
            </div>
            <div
              className="m-lxs pointer"
              onClick={() => setGraphConfig((prev) => ({ ...prev, stack: !prev.stack }))}>
              <Tooltip title={stackTooltip} placement="topLeft">
                <AreaChartOutlined
                  disabled={reportingState.groupBy === "none"}
                  style={{
                    color: graphConfig.stack ? primaryColor : COLORS.BLUE_MEDIUM,
                    fontSize: 15,
                  }}
                />
              </Tooltip>
            </div>
            <div
              className="m-lxs pointer"
              onClick={() => setGraphConfig((prev) => ({ ...prev, nice: !prev.nice }))}>
              <Tooltip title={niceToolTip} placement="topLeft">
                <FundOutlined
                  style={{
                    color: graphConfig.nice ? primaryColor : COLORS.BLUE_MEDIUM,
                    fontSize: 15,
                  }}
                />
              </Tooltip>
            </div>
          </div>
        }
        items={[
          {
            key: "Graph",
            children: (
              <Row className="width-100">
                <Col xs={24} className="m-bxs">
                  <div className="flex-row-container align-center justify-center width-100">
                    <h5>Ad insertions</h5>
                    <Switch
                      className="m-hxs"
                      size="small"
                      checked={graphFocus === "spend"}
                      onChange={(checked) => {
                        handleChangeGraphFocus(checked ? "spend" : "insertion");
                      }}
                    />
                    <h5>Spend</h5>
                  </div>
                </Col>
                <Col xs={24} className="width-100">
                  <AdvertisingAreaGraph
                    data={dataInUse}
                    xField="date"
                    yField="value"
                    loading={isLoading}
                    reportingPageState={reportingState}
                    getNameForEntity={getNameForEntity}
                    graphFocus={graphFocus}
                    isStack={graphConfig.stack}
                    nice={graphConfig.nice}
                  />
                </Col>
                {showSpendCalcWarning && (
                  <Col xs={24} className="width-100 m-txs">
                    <Alert
                      className="text-center"
                      message={`Spend data monitoring enabled after ${dayjs.unix(SPEND_DATE_BOUNDARY_START).format("MM/DD/YYYY")}. Spend data for date ranges earlier than this date will display as zero.`}
                      type="warning"
                      showIcon
                    />
                  </Col>
                )}
              </Row>
            ),
          },
        ]}
      />
    </div>
  );
};

/**
 * Transform raw data into 3 sections, campaign, podcast, all. Takes into account either insertion or spend data values
 */
const transformData: ({
  rawData,
  type,
  campaigns,
  publicShows,
  reportingState,
}: {
  rawData: RawData;
  type: ReportingPageState["graphFocus"];
  campaigns: { [campaignUUID: string]: ICampaign };
  publicShows: PublicShowsReduxState;
  reportingState: ReportingPageState;
  showUUIDsNeeded: string[];
}) => [AdvertisingGraphDataPoint[], AdvertisingGraphDataPoint[], AdvertisingGraphDataPoint[]] = ({
  rawData,
  type,
  campaigns,
  publicShows,
  reportingState,
  showUUIDsNeeded,
}) => {
  if (Array.isArray(rawData)) {
    const campaignMapperZeroState = reportingState.campaignUUIDs.reduce(
      (accu, curr) => {
        if (!accu[curr]) {
          accu[curr] = 0;
        }
        return accu;
      },
      {} as { [campaignUUID: string]: 0 }
    );

    const podcastMapperZeroState = showUUIDsNeeded.reduce(
      (accu, curr) => {
        if (!accu[curr]) {
          accu[curr] = 0;
        }
        return accu;
      },
      {} as { [showUUID: string]: 0 }
    );

    const start = rawData?.[0]?.date;

    const campaignMapper: { [timeStamp: UnixTimeStamp]: { [campaignUUID: string]: number } } =
      buildDataMapper({ reportingState, defaultState: campaignMapperZeroState, startDate: start });
    const podcastMapper: { [timeStamp: UnixTimeStamp]: { [podcastUUID: string]: number } } =
      buildDataMapper({ reportingState, defaultState: podcastMapperZeroState, startDate: start });
    const normalMapper: { [timeStamp: UnixTimeStamp]: number } = buildDataMapper({
      reportingState,
      defaultState: 0,
      startDate: start,
    });

    const podcastColorFunc = createColorFunc(showUUIDsNeeded);
    const campaignColorFunc = createColorFunc(reportingState.campaignUUIDs);

    const allowedCampaigns = reportingState.campaignUUIDs.reduce(
      (accu, curr) => {
        if (!accu[curr]) {
          accu[curr] = true;
        }
        return accu;
      },
      {} as { [campaignUUID: string]: true }
    );

    const allowedPodcasts = reportingState.showUUIDs.reduce(
      (accu, curr) => {
        if (!accu[curr]) {
          accu[curr] = true;
        }
        return accu;
      },
      {} as { [campaignUUID: string]: true }
    );

    // Iterate through the raw data aggregate value based on time via a mapping it, to be flattened later
    for (const insertion of rawData) {
      const [_, campaignUUID, showUUID] = insertion.pathValues;
      const date = insertion.date * 1000;

      if (!allowedPodcasts[showUUID]) continue; // disregards values for filtered out podcasts

      // Campaigns
      if (!campaignMapper[date]) campaignMapper[date] = { ...campaignMapperZeroState };
      if (!campaignMapper[date][campaignUUID]) campaignMapper[date][campaignUUID] = 0;

      campaignMapper[date][campaignUUID] +=
        type === "spend" ? priceToCent(insertion.count) : insertion.count;

      // Shows
      if (!podcastMapper[date]) podcastMapper[date] = { ...podcastMapperZeroState };
      if (!podcastMapper[date][showUUID]) podcastMapper[date][showUUID] = 0;

      podcastMapper[date][showUUID] +=
        type === "spend" ? priceToCent(insertion.count) : insertion.count;

      // Normal
      if (!normalMapper[date]) normalMapper[date] = 0;

      normalMapper[date] += type === "spend" ? priceToCent(insertion.count) : insertion.count;
    }

    const campaignData = Object.keys(campaignMapper)
      .flatMap((date) => {
        const values = Object.keys(campaignMapper[parseInt(date)]).map((campaignUUID) => {
          return {
            date: parseInt(date),
            campaignUUID,
            campaignName: campaigns[campaignUUID]?.name,
            brandUUID: campaigns[campaignUUID]?.brand?.instanceUUID,
            brandName: campaigns[campaignUUID]?.brand?.name,
            color: campaignColorFunc(campaignUUID),
            value: campaignMapper[parseInt(date)][campaignUUID],
            type: "campaignUUID" as const,
          };
        });

        return values;
      })
      .filter((dataPoint) => !!allowedCampaigns[dataPoint?.campaignUUID]);

    const podcastData = Object.keys(podcastMapper)
      .flatMap((date) => {
        const values = Object.keys(podcastMapper[parseInt(date)]).map((showUUID) => {
          return {
            date: parseInt(date),
            showUUID,
            podcastName: publicShows[showUUID]?.title,
            color: podcastColorFunc(showUUID),
            value: podcastMapper[parseInt(date)][showUUID],
            type: "showUUID" as const,
          };
        });
        return values;
      })
      .filter((dataPoint) => !!allowedPodcasts[dataPoint?.showUUID]);

    const allData = Object.keys(normalMapper).map((date) => {
      return {
        date: parseInt(date),
        value: normalMapper[parseInt(date)],
        type: "none" as const,
        color: colors.primaryColor,
      };
    });

    return [campaignData, podcastData, allData];
  }

  return [[], [], []];
};

// Fancy Typescript - Basically requiring only a subset ogf RCArea props
interface AdvertisingAreaGraphProps
  extends Pick<
    Parameters<typeof RCArea<AdvertisingGraphDataPoint>>[number],
    "data" | "xField" | "yField" | "loading" | "isStack"
  > {
  reportingPageState: ReportingPageState;
  getNameForEntity: (entityUUID: string) => string;
  nice?: boolean;
  graphFocus: ReportingPageState["graphFocus"];
}

/**
 * Wrapper for RCArea component, basically adding all styling configurations here specifically for advertising graph.
 */
const AdvertisingAreaGraph = React.memo(
  ({
    data,
    xField,
    yField,
    loading,
    reportingPageState,
    getNameForEntity,
    isStack,
    nice,
    graphFocus,
  }: AdvertisingAreaGraphProps) => {
    const height = 260;
    const sortedData = [...data].sort((a, b) => a.date - b.date);
    const min = nice
      ? (sortedData?.[0]?.date ?? reportingPageState?.["dateRange"]?.[0] * 1000)
      : reportingPageState?.["dateRange"]?.[0] * 1000;
    const max = nice
      ? (sortedData?.[sortedData.length - 1]?.date ?? reportingPageState?.["dateRange"]?.[1] * 1000)
      : reportingPageState?.["dateRange"]?.[1] * 1000;
    const { groupBy, showUUIDs, campaignUUIDs } = reportingPageState;

    const podcastColorFunc = createColorFunc(showUUIDs);
    const campaignColorFunc = createColorFunc(campaignUUIDs);

    const colorMapper = (
      datum: Record<Extract<ReportingPageState["groupBy"], "showUUID" | "campaignUUID">, "string">
    ) => {
      switch (groupBy) {
        case "showUUID":
          return podcastColorFunc(datum[groupBy]);
        case "campaignUUID":
          return campaignColorFunc(datum[groupBy]);
        case "none":
        default:
          return colors.primaryColor;
      }
    };

    const formatValues = (text: any) => {
      return `${graphFocus === "spend" ? "$ " : ""}${yAxisLabelFormatter(text)}`;
    };

    const graphMessage = buildGraphMessage(reportingPageState);

    const config: Parameters<typeof Area>[number] = {
      animation: { appear: false },
      autoFit: true,
      style: {
        minHeight: `${height}px`,
      },
      data,
      height,
      xField,
      yField,
      seriesField: groupBy === "none" ? undefined : groupBy,
      loading,
      isStack,
      interactions: [{ type: "legend-item:mouseover", enable: false }],
      tooltip: {
        fields: [
          "date",
          "value",
          "type",
          "campaignUUID",
          "campaignName",
          "showUUID",
          "podcastName",
        ],
        formatter: tooltipFormatter(graphFocus) as any,
      },
      limitInPlot: true,
      // @ts-expect-error colorField prop doesn't match well
      colorField: "color" as any,
      color: colorMapper as any,
      meta: {
        date: {
          type: "time",
          mask: "MMM DD",
          range: [0, 1],
          min: min,
          max: max,
          maxLimit: max,
          nice,
          showLast: true,
          tickMethod: "time-pretty",
        },
        value: {
          formatter: formatValues,
        },
      },
      legend: {
        flipPage: false,
        maxItemWidth: 150,
        position: "bottom",
        itemName: {
          formatter: getNameForEntity,
        },
      },
    };

    return (
      <div className="width-100 relative" style={{ minHeight: height }}>
        <Suspense fallback={<Spin />}>
          <Area {...config} />
        </Suspense>
        {graphMessage.length > 0 && (
          <div className={classNames(classes.graph_message)}>
            <h3>{graphMessage}</h3>
          </div>
        )}
      </div>
    );
  }
);

const tooltipFormatter =
  (graphFocus: ReportingPageState["graphFocus"]) => (datum: AdvertisingGraphDataPoint) => {
    switch (datum.type) {
      case "none":
        return {
          name: graphFocus === "insertion" ? "Insertions" : "Spend",
          value:
            graphFocus === "insertion"
              ? numeral(datum?.value).format("0,0")
              : formatMoney(datum.value),
        };
      case "campaignUUID":
        return {
          name: datum.campaignName,
          value:
            graphFocus === "insertion"
              ? numeral(datum?.value).format("0,0")
              : formatMoney(datum.value),
        };
      case "showUUID":
        return {
          name: datum.podcastName,
          value:
            graphFocus === "insertion"
              ? numeral(datum?.value).format("0,0")
              : formatMoney(datum.value),
        };
    }
  };

/**
 * Creates a color palette for RedCircle Primary color 'red', the range of the palette is based on the input
 */
const getColorPalette = (colors: number) => {
  const colorPalette = Array(colors)
    .fill(0)
    .map((val, index, arr) => {
      const total = arr.length;
      const fraction = ((index + 1) / total).toFixed(2);
      return `rgba(234, 64, 77, ${fraction})`;
    })
    .reverse();

  return colorPalette;
};

/**
 * Factory helper func, creates a mapped for entity uuids (campaignUUID, showUUID, etc)
 * and maps them to a color palette
 */
const createColorFunc = (entityUUIDs: string[]) => {
  const colorPalette = getColorPalette(entityUUIDs.length);
  const map = entityUUIDs.reduce(
    (accu, curr, index) => {
      if (!accu[curr]) {
        accu[curr] = colorPalette[index];
      }
      return accu;
    },
    {} as { [campaignUUID: string]: string }
  );

  return (entityUUID: string) => {
    return map[entityUUID];
  };
};

/**
 * Based on report page state, set messages to display in graph.
 */
const buildGraphMessage = (reportingState: ReportingPageState) => {
  let message = "";

  if (reportingState.dateRange[0] === 0 || reportingState.dateRange[1] === 0) {
    message = "Please select a date range.";
  } else if (reportingState.campaignUUIDs.length === 0) {
    message = "Please choose campaigns to display.";
  } else if (dayjs.unix(reportingState.dateRange[0]).isAfter(dayjs())) {
    message = "Campaigns selected have not started yet";
  } else if (reportingState.groupBy === "showUUID" && reportingState.showUUIDs.length === 0) {
    message = "Please choose podcasts to display.";
  }

  return message;
};

const buildDataMapper = ({
  reportingState,
  defaultState = 0,
  startDate,
}: {
  reportingState: ReportingPageState;
  defaultState: Record<string, any> | number;
  startDate?: UnixTimeStamp;
}) => {
  const { dateRange, interval, timeZone } = reportingState;

  const mapper: { [timeStamp: UnixTimeStamp]: any } = {};

  const intervalToDayjs = {
    "1h": "hour",
    "1d": "day",
    "1w": "week",
    "1q": "quarter",
    "1M": "month",
    "1y": "year",
  } as const;

  let startDayjs =
    typeof startDate === "number"
      ? dayjs.unix(startDate)
      : dayjs.unix(dateRange[0]).startOf(intervalToDayjs[interval]);
  let endDayjs = dayjs.unix(dateRange[1]);
  if (timeZone === UTC) {
    startDayjs = startDayjs.utc();
    endDayjs = endDayjs.utc();
  }

  let current = startDayjs.clone();

  while (current.unix() < endDayjs.unix()) {
    mapper[current.unix() * 1000] =
      typeof defaultState === "number" ? defaultState : structuredClone(defaultState);

    const newInterval = intervalToDayjs[interval] as ManipulateType;

    current = current.add(1, newInterval);
  }

  return mapper;
};
