import { Tabs, TabsProps } from "antd";
import groupBy from "lodash/groupBy";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { fetchDistributions } from "src/action_managers/distributions";
import Breadcrumbs from "src/components/lib/breadcrumbs";
import ExclusiveTag from "src/components/lib/exclusive_tag";
import Page from "src/components/lib/page";
import RedDot from "src/components/lib/red_dot/red_dot";
import { SummaryPage } from "src/components/modals/campaign_editor/summary_page";
import { sortCampaignItems } from "src/components/pages/ad_platform/show_advertising_page/show_advertising_page_util";
import {
  CampaignItemStateAudioSwapRequested,
  CampaignItemStateAwaitingAudio,
  CampaignItemStateDraft,
  CampaignItemStateNeedsScript,
  CampaignStateCreated,
  CampaignStateToFriendly,
} from "src/constants/campaigns";
import { permissionTypes } from "src/constants/permission_roles";
import { useGetCampaignJuiced } from "src/hooks/campaigns";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { processCampaignItemState } from "src/lib/campaigns";
import { canAdvertiserAccess } from "src/lib/permissions";
import { Budget, BudgetsReduxState } from "src/reducers/budgets";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { PublicShowsReduxState } from "src/reducers/public_show";
import { IShow } from "src/reducers/shows";
import "./campaign_page.scss";
import CampaignPageDashboard from "./campaign_page_dashboard";
import { getCampaignEstimatedDates } from "./campaign_page_utils";
import CampaignScriptSummary from "./campaign_script_summary";

const tabKeys = {
  dashboard: "dashboard",
  details: "details",
  script: "script",

  // mapped
  select: "dashboard",
  schedule: "dashboard",
  edit: "details",
} as const;

export interface IAppendedCampaignItem extends ICampaignItem {
  show: IShow;
  budget: Budget;
  campaign: ICampaign;
}

export default function CampaignPage() {
  const { campaignUUID, modifier } = useParams<{ campaignUUID: string; modifier: string }>();

  const history = useHistory();
  const dispatch = useReduxDispatch();

  const { campaign, isLoading: isInitiallyLoading } = useGetCampaignJuiced(campaignUUID);
  const budgets: BudgetsReduxState = useSelectorTS((state) => state.budgets);
  const publicShows: PublicShowsReduxState = useSelectorTS((state) => state.publicShows);

  const scripts = useSelectorTS((state) => state?.scripts.scriptsByCampaignUUID);
  const isScriptsLoading = useSelectorTS((state) => state?.scripts.isLoading);
  const scriptsForCampaign = scripts?.[campaignUUID] || [];

  const campaignStatsByUUID = useSelectorTS((state) => state.campaigns.campaignStatsByUUID);
  const campaignStats = campaignStatsByUUID?.[campaignUUID];

  const allCampaignItems = useSelectorTS((state) => state?.campaignItems?.campaignItems);

  const campaignItems = (campaign?.items ?? []).map(({ item, mediaFile }) => {
    const { uuid, showUUID, budgetUUID, ...rest }: ICampaignItem = item;
    return processCampaignItemState({
      show: showUUID && publicShows?.[showUUID],
      budget: budgetUUID && budgets?.[budgetUUID],
      campaign,
      mediaFile,
      ...rest,
      ...allCampaignItems[uuid], // campaign.item are grabbed one time but on subsequent updates need to reapply them from allCampaignItems,
    } as IAppendedCampaignItem);
  });

  const canShowDashboard = canAdvertiserAccess(permissionTypes.viewCampaignReporting, campaign);

  // map states
  const campaignItemsByState = sortCampaignItems({
    draft: [],
    sent: [],
    accepted: [],
    declined: [],
    running: [],
    completed: [],
    expired: [],
    paused: [],
    [CampaignItemStateAwaitingAudio]: [],
    [CampaignItemStateAudioSwapRequested]: [],
    [CampaignItemStateNeedsScript]: [],
    ...groupBy(campaignItems, "state"),
  });

  const campaignCanEdit = campaign?.isV2 || campaign?.state === CampaignStateCreated;
  const campaignEstimatedDates = getCampaignEstimatedDates(
    publicShows,
    campaign,
    campaignItems,
    budgets
  );
  const hasSentAnyItems = Object.keys(campaignItemsByState).some(
    (state) => state !== CampaignItemStateDraft && campaignItemsByState[state].length > 0
  );
  const showScriptNotification = !isScriptsLoading && !scripts[campaignUUID] && hasSentAnyItems;

  const handleTabChange = (key: string) => history.push(`/campaigns/${campaignUUID}/${key}`);
  const handleCampaignEdit = (pageNumber?: number) =>
    history.push(`/campaigns/${campaignUUID}/edit/${pageNumber}`);

  const handleGetDistribution = (showUUID: string) => dispatch(fetchDistributions(showUUID));

  let tabItems: TabsProps["items"] = [
    {
      label: "Dashboard",
      key: tabKeys.dashboard,
      children: (
        <CampaignPageDashboard
          campaign={campaign}
          shows={publicShows}
          budgets={budgets}
          campaignItems={campaignItems as ICampaignItem[]}
          campaignStats={campaignStats}
          campaignItemsByState={campaignItemsByState}
          getDistribution={handleGetDistribution}
          scriptsForCampaign={scriptsForCampaign}
          isLoading={isInitiallyLoading}
        />
      ),
    },
    {
      label: "Details",
      key: tabKeys.details,
      children: (
        <SummaryPage
          campaign={campaign}
          updatePage={campaignCanEdit ? handleCampaignEdit : undefined}
        />
      ),
    },
    {
      label: (
        <div className="flex-row-container align-center">
          Scripts
          {showScriptNotification && <RedDot size={8} className="m-lxxxs m-bxxs" />}
        </div>
      ),
      key: tabKeys.script,
      children: <CampaignScriptSummary campaign={campaign} />,
    },
  ];

  if (!canShowDashboard) {
    tabItems = tabItems.filter((item) => item.key !== tabKeys.dashboard);
  }

  // because modifier can be other things like /select or /edit, we map those to the tab keys
  const defaultTab = canShowDashboard ? tabKeys.dashboard : tabKeys.details;
  const activeTab = tabKeys[modifier as keyof typeof tabKeys] || defaultTab;

  return (
    <Page className="campaign-page" pageTitle={campaign?.name}>
      <Breadcrumbs
        crumbs={[
          { path: "/campaigns", name: "All Campaigns" },
          { path: `/campaigns/${campaignUUID}/dashboard`, name: campaign?.name, active: true },
        ]}
      />

      <Page.Header className="flex-column-container align-start m-bs">
        {campaign && (
          <>
            <div className="flex-row-container align-center">
              {campaign.state && (
                <ExclusiveTag>{CampaignStateToFriendly[campaign.state]}</ExclusiveTag>
              )}
              {campaign?.archived && (
                <ExclusiveTag
                  className="m-lxxs"
                  color="rgb(128, 128, 128)"
                  backgroundRGB={[149, 149, 149]}>
                  [ARCHIVED]
                </ExclusiveTag>
              )}
            </div>
            <Page.Title className="m-txxs m-bxxs line-clamp-2">{campaign.name}</Page.Title>
            {campaignEstimatedDates && (
              <span>
                {campaignEstimatedDates.start && (
                  <span>{moment.unix(campaignEstimatedDates.start).format("M.DD.YY")}</span>
                )}
                {campaignEstimatedDates.end && (
                  <span> &mdash; {moment.unix(campaignEstimatedDates.end).format("M.DD.YY")}</span>
                )}
              </span>
            )}
          </>
        )}
      </Page.Header>

      <Page.Section>
        <Tabs
          activeKey={activeTab}
          onChange={handleTabChange}
          className="campaign-tabs"
          items={tabItems}
        />
      </Page.Section>
    </Page>
  );
}
