import { Alert, Form } from "antd";
import { useContext, useEffect, useState } from "react";
import { ICampaignTargetingOptions } from "redcircle-types";
import { Button, Modal } from "redcircle-ui";
import { showError } from "src/actions/app";
import { editCampaign, getCampaign } from "src/action_managers/campaigns";
import {
  CampaignStateCompleted,
  CampaignStateCreated,
  CampaignStateInProgress,
} from "src/constants/campaigns";
import { useDispatchTS } from "src/hooks/redux-ts";
import FlightingOptionsForm from "../campaign_editor_modal/flighting_options_form";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";

export default function SchedulerFlighting() {
  const dispatch = useDispatchTS();
  const { campaign } = useContext(CampaignSchedulerContext);
  const [form] = Form.useForm();

  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const showPostSendAlert =
    campaign &&
    (campaign.state === CampaignStateInProgress || campaign.state === CampaignStateCompleted);

  // initialize fields
  useEffect(() => {
    if (campaign && showModal) {
      const newFormFields: any = { ...campaign };

      // set custom transformation fields
      newFormFields.targetingOptionsArray =
        campaign.targetingOptions &&
        Object.keys(campaign.targetingOptions).filter(
          (key) => campaign.targetingOptions[key as keyof ICampaignTargetingOptions]
        );

      form.setFieldsValue(newFormFields);
    }
  }, [campaign, showModal]);

  const handleSubmit = async () => {
    const formFields = form.getFieldsValue(true);
    const payload = {
      pacing: formFields.pacing,
      frequencyConfigs: formFields.frequencyConfigs,
      hardEndDate: formFields.hardEndDate,
      recentEpisodesOnly: formFields.recentEpisodesOnly,
      targetingOptions: formFields.targetingOptionsArray
        ? formFields.targetingOptionsArray.reduce((acc: any, curr: any) => {
            acc[curr] = true;
            return acc;
          }, {})
        : {},
      isV2: formFields.isV2,
    };
    if (campaign) {
      setIsSubmitLoading(true);
      const res = await dispatch(editCampaign({ uuid: campaign.uuid, ...payload }));
      if (res && res.status === 200) {
        await dispatch(getCampaign(campaign.uuid));
        setShowModal(false);
      } else {
        dispatch(showError("Failed to save flighting options"));
      }
      setIsSubmitLoading(false);
    }
  };

  if (!campaign || (!campaign.isV2 && campaign.state !== CampaignStateCreated)) return null;

  return (
    <div>
      <Button type="secondary-red" size="small" onClick={() => setShowModal(true)}>
        Flighting
      </Button>

      <Modal open={showModal} size="xs" onClose={() => setShowModal(false)}>
        <Form onFinish={handleSubmit} form={form}>
          <Modal.Title>Flighting</Modal.Title>
          <Modal.Body className="p-b0">
            {showPostSendAlert && (
              <Alert
                type="warning"
                className="m-bxs"
                message="This campaign is already in progress - any changes to flighting options will also be applied to all podcasts that have already been sent. This will not affect any existing overrides."
              />
            )}
            <FlightingOptionsForm />
          </Modal.Body>
          <Modal.Footer noBorder>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="m-la"
              loading={isSubmitLoading}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
