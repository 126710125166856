import isEmpty from "lodash/isEmpty";
import parseInt from "lodash/parseInt";
import * as moment from "moment";
import numeral from "numeral";

export function humanizeNumber(number: number) {
  if (number < 10000) {
    return number.toLocaleString();
  }
  if (number < 100000) {
    return numeral(number).format("0.0a");
  }
  return numeral(number).format("0.0a");
}

export function humanizeAudienceSize(number: number) {
  return numeral(number).format("0a");
}

export const secondsToString = (seconds: number, withMs = false) => {
  const duration = moment.duration(seconds * 1000);
  const epoch = moment.utc(duration.asMilliseconds());
  if (duration.hours() && withMs) {
    return epoch.format("hh:mm:ss.SS");
  } else if (duration.hours()) {
    return epoch.format("hh:mm:ss");
  } else if (withMs) {
    return epoch.format("mm:ss.SS");
  }

  return epoch.format("mm:ss");
};
