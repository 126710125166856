import { Tabs, Tooltip } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { classNames } from "react-extras";
import { Link } from "react-router-dom";
import Button from "src/components/lib/button";
import { TButton } from "src/components/lib/button/button";
import ExclusiveTag from "src/components/lib/exclusive_tag";
import MediaPlayer from "src/components/lib/media_player";
import RedDot from "src/components/lib/red_dot/red_dot";
import {
  CampaignItemStateAudioSwapRequested,
  CampaignItemStateAwaitingAudio,
  CampaignItemStateDeclined,
  CampaignItemStateExpired,
  CampaignItemStateNeedsScript,
  CampaignItemStatePaused,
  CampaignItemStateSent,
  CampaignItemStateToFriendlyPodcaster,
  VettingStateAwaiting,
} from "src/constants/campaigns";
import { useSelectorTS } from "src/hooks/redux-ts";
import { useGetVettingInfoForCampaignItem } from "src/hooks/vetting";
import { ReactComponent as ConflictIcon } from "src/icons/warning-yellow.svg";
import { formatMoney } from "src/lib/format-money";
import { ICampaignItem } from "src/reducers/campaign_items";
import { CampaignItemState } from "src/reducers/campaign_items/types";
import {
  TableDueDate,
  TableExpandedRow,
  TableStatusCell,
  TableWithEmptyState,
} from "../../campaigns/campaign_page/campaign_dashboard_table";
import { getCampaignItemContextText } from "../../campaigns/campaign_page/campaign_page_utils";
import CampaignProgress from "../../campaigns/campaign_page/campaign_progress";
import { getCampaignItemStart, sortCampaignItemsByDueDate } from "./show_advertising_page_util";

interface IProps {
  campaignItemsByState: Record<CampaignItemState, ICampaignItem[]>;
  conflicts?: any;
}

export default function AdvertisingTable({ campaignItemsByState, conflicts }: IProps) {
  const [expandedRowKeys, setExpandedRowKeys] = useState<{ [key: string]: boolean }>({});
  const { user } = useSelectorTS((state) => state.user);

  // all states besides draft
  const {
    vetting,
    sent,
    accepted,
    running,
    paused,
    completed,
    expired,
    declined,
    [CampaignItemStateAwaitingAudio]: awaitingAudio,
    [CampaignItemStateAudioSwapRequested]: audioSwapRequested,
    [CampaignItemStateNeedsScript]: needsScript,
  } = campaignItemsByState;

  // we have a default sort order for all items
  const allItems = [
    // these all require responses
    ...vetting,
    ...audioSwapRequested,
    ...sortCampaignItemsByDueDate([...awaitingAudio, ...sent]),

    // current campaigns
    ...paused,
    ...running,
    ...accepted,

    // no actions required
    ...needsScript,
    ...completed,
    ...expired,
    ...declined,
  ];

  const acceptedItems = [
    // these all require responses
    ...awaitingAudio,
    ...audioSwapRequested,

    // current campaigns
    ...paused,
    ...running,
    ...accepted,

    // no actions required
    ...needsScript,
    ...completed,
  ];

  const pendingActionItems = [
    ...vetting,
    ...paused,
    ...audioSwapRequested,
    ...sortCampaignItemsByDueDate([...awaitingAudio, ...sent]),
  ];

  const runningItems = [...running];

  // we track expanded rows through react state and not antd
  const handleRowExpandClick = (campaignUUID: string) => {
    const rows = { ...expandedRowKeys };
    if (rows[campaignUUID]) {
      delete rows[campaignUUID];
    } else {
      rows[campaignUUID] = true;
    }
    setExpandedRowKeys(rows);
  };

  const columns = [
    {
      title: "Campaign",
      key: "campaign",
      render: (campaignItem: ICampaignItem) => (
        <TableCampaignCell
          campaignItem={campaignItem}
          onClickExpand={handleRowExpandClick}
          rowIsExpanded={!!expandedRowKeys[campaignItem.uuid]}
          conflicts={conflicts}
        />
      ),
      sorter: (a: ICampaignItem, b: ICampaignItem) => {
        return a.campaignName.localeCompare(b.campaignName) ?? 0;
      },
    },
    {
      title: "Start",
      key: "startAt",
      responsive: ["sm"],
      render: (campaignItem: ICampaignItem) => {
        return <TableStartCell campaignItem={campaignItem} />;
      },
      sorter: (a: ICampaignItem, b: ICampaignItem) => b.startAt - a.startAt,
    },
    {
      title: "Progress",
      key: "progress",
      responsive: ["sm"],
      render: (campaignItem: ICampaignItem) => {
        return <CampaignProgress campaignItem={campaignItem} />;
      },
    },
    {
      title: "Status",
      key: "status",
      responsive: ["sm"],
      render: (campaignItem: ICampaignItem) => (
        <TableStatusCell campaignItem={campaignItem} user={user} isPodcaster={true} />
      ),
      sorter: (a: ICampaignItem, b: ICampaignItem) => a.state.localeCompare(b.state),
    },
    {
      title: "Ad Audio",
      key: "context",
      render: (campaignItem: ICampaignItem) => <TableContextCell campaignItem={campaignItem} />,
    },
  ];

  const sharedTableProps = {
    columns,
    rowKey: (campaignItem: ICampaignItem) => campaignItem.uuid,
    pagination: false,
    expandable: {
      expandIcon: () => false,
      expandIconColumnIndex: -1,
      expandedRowRender: (campaignItem: ICampaignItem) => (
        <TableExpandedRow campaignItem={campaignItem} />
      ),
      expandedRowKeys: Object.keys(expandedRowKeys),
    },
  };

  const tabs = [
    {
      key: "all",
      label: `All (${allItems.length})`,
      children: (
        <TableWithEmptyState
          dataSource={allItems}
          {...sharedTableProps}
          emptyState={
            <>
              Thanks for setting up your Advertising Profile. You can update your profile at any
              time by clicking "RAP Settings".
              <br />
              All host-read campaigns, current, past and pending will appear here.
            </>
          }
        />
      ),
    },
    {
      key: "accepted",
      label: `Accepted (${acceptedItems.length})`,
      children: (
        <TableWithEmptyState
          dataSource={acceptedItems}
          {...sharedTableProps}
          emptyState="You have no accepted host-read campaigns."
        />
      ),
    },
    {
      key: "pending",
      label: (
        <div className="flex-row-container align-center">
          Pending Action ({pendingActionItems.length})
          {pendingActionItems.length > 0 && <RedDot size={8} className="m-lxxxs m-bxxs" />}
        </div>
      ),
      children: (
        <TableWithEmptyState
          dataSource={pendingActionItems}
          {...sharedTableProps}
          emptyState="There is no action needed from you on any host-read campaigns."
        />
      ),
    },
    {
      key: "running",
      label: `Running (${runningItems.length})`,
      children: (
        <TableWithEmptyState
          dataSource={runningItems}
          {...sharedTableProps}
          emptyState="There are no host-read campaigns currently running on your podcast."
        />
      ),
    },
  ];

  return (
    <Tabs
      defaultActiveKey={"all"}
      className="advertising-tables"
      onChange={() => setExpandedRowKeys({})}
      items={tabs}
    />
  );
}

const TableCampaignCell = ({
  campaignItem,
  rowIsExpanded,
  onClickExpand,
  conflicts,
}: {
  campaignItem: ICampaignItem;
  rowIsExpanded: boolean;
  onClickExpand: (campaignUUID: string) => void;
  conflicts?: any;
}) => {
  const path = `/ad-platform/${campaignItem.showUUID}/campaigns/${campaignItem.campaignUUID}`;

  const hasConflict = conflicts?.has(campaignItem.uuid);
  const isVetting = campaignItem.state === VettingStateAwaiting;

  const { form: vettingForm } = useGetVettingInfoForCampaignItem(campaignItem.uuid);

  const showBudget =
    (!isVetting || vettingForm?.visibility?.shareBudget) && campaignItem.totalCreatorAmount !== 0;
  const showCampaignInfo = !isVetting || showBudget;

  const isDisabled =
    campaignItem.state === CampaignItemStateExpired ||
    campaignItem.state === CampaignItemStateDeclined ||
    campaignItem.state === CampaignItemStatePaused;

  const renderTitle = () => (
    <strong className="line-clamp-1 title">
      {isVetting
        ? `Brand Inquiry: ${vettingForm ? vettingForm.brandName : "Loading..."}`
        : campaignItem.campaignName}
    </strong>
  );

  const handleClickExpand = () => {
    onClickExpand(campaignItem.uuid);
  };

  return (
    <div className={classNames("campaign-table-cell", isDisabled && "disabled")}>
      <div className="flex-column-container">
        <div className="flex-row-container align-center">
          {hasConflict && (
            <Tooltip
              className="m-rxxxs"
              title={`Two or more pending invites have an overlapping week with an accepted campaign. You can have up to 2 “Select Episodes” campaigns running on the same week, so please review the pending campaigns carefully.`}>
              <ConflictIcon />
            </Tooltip>
          )}
          {!isDisabled && !isVetting ? <Link to={path}>{renderTitle()}</Link> : renderTitle()}
        </div>

        {showCampaignInfo && (
          <div className="flex-row-container align-center">
            <span className="m-rxxs m-txxxs fs-13">
              {showBudget && (
                <span>
                  Total: <strong>{formatMoney(campaignItem.totalCreatorAmount)}</strong>
                </span>
              )}
            </span>
          </div>
        )}

        <span className="d-block d-none-sm">
          <ExclusiveTag className={`no-wrap tag-${campaignItem.state} podcaster`}>
            {
              CampaignItemStateToFriendlyPodcaster[
                campaignItem.state as keyof typeof CampaignItemStateToFriendlyPodcaster
              ]
            }
          </ExclusiveTag>
        </span>
      </div>
    </div>
  );
};

const TableStartCell = ({ campaignItem }: { campaignItem: ICampaignItem }) => {
  const isVetting = campaignItem.state === VettingStateAwaiting;
  const { form: vettingForm } = useGetVettingInfoForCampaignItem(campaignItem.uuid);
  const showTimeline =
    (!isVetting || vettingForm?.visibility?.shareTimeline) && campaignItem.startAt > 0;

  return (
    <div>
      {showTimeline && (
        <span className="fs-13">{getCampaignItemStart(campaignItem)?.format("MMM D, YYYY")}</span>
      )}
    </div>
  );
};

const TableContextCell = ({ campaignItem }: { campaignItem: ICampaignItem }) => {
  const showMediaPlayer = !!campaignItem.mediaFileUUID;
  const showBrandDate = campaignItem.state === CampaignItemStateNeedsScript;
  const showEndDate =
    campaignItem.state === CampaignItemStateExpired ||
    campaignItem.state === CampaignItemStateDeclined;

  const { invitation: vettingInvitation } = useGetVettingInfoForCampaignItem(campaignItem.uuid);

  return (
    <div className="campaign-table-cell context">
      {showMediaPlayer && <MediaPlayer mediaFileUUID={campaignItem.mediaFileUUID} mini />}
      {vettingInvitation && campaignItem.state === VettingStateAwaiting && (
        <TableDueDate timestamp={vettingInvitation.responseDueAt} />
      )}
      {campaignItem.state === CampaignItemStateAwaitingAudio && (
        <TableDueDate timestamp={campaignItem.uploadAudioBy} />
      )}
      {campaignItem.state === CampaignItemStateSent && (
        <TableDueDate timestamp={campaignItem.respondByAt} />
      )}
      {showBrandDate && (
        <span className="fs-13 text-subtle">{getCampaignItemContextText(campaignItem)}</span>
      )}
      {showEndDate && (
        <span className="fs-13 end-date">{getCampaignItemContextText(campaignItem)}</span>
      )}
      <TableCTA campaignItem={campaignItem} />
    </div>
  );
};

const TableCTA = ({ campaignItem }: { campaignItem: ICampaignItem }) => {
  const campaignItemPath = `/ad-platform/${campaignItem.showUUID}/campaigns/${campaignItem.campaignUUID}`;

  const { invitation: vettingInvitation } = useGetVettingInfoForCampaignItem(campaignItem.uuid);

  const renderLink = (copy: string, path: string, type = "primary") => (
    <Link to={path} className="cta">
      <Button type={type as TButton["type"]}>{copy}</Button>
    </Link>
  );

  if (campaignItem.state === VettingStateAwaiting) {
    if (vettingInvitation?.respondedAt) {
      return renderLink(
        "View Response",
        `/ad-platform/${campaignItem.showUUID}/vetting/${campaignItem.uuid}`,
        "secondary"
      );
    }
    if (vettingInvitation?.responseDueAt && vettingInvitation?.responseDueAt < dayjs().unix()) {
      return renderLink(
        "View",
        `/ad-platform/${campaignItem.showUUID}/vetting/${campaignItem.uuid}`,
        "secondary"
      );
    }
    return renderLink(
      "Respond",
      `/ad-platform/${campaignItem.showUUID}/vetting/${campaignItem.uuid}`
    );
  }
  if (campaignItem.state === CampaignItemStateAudioSwapRequested)
    return renderLink("Replace Audio", campaignItemPath);
  if (campaignItem.state === CampaignItemStateAwaitingAudio)
    return renderLink("View & Upload", campaignItemPath);
  if (campaignItem.state === CampaignItemStateSent) return renderLink("Respond", campaignItemPath);

  return null;
};
