import { Button } from "redcircle-ui";
import classNames from "classnames";
import styles from "./stripe_components.module.scss";
import dayjs from "dayjs";
import { Radio } from "antd";
import { Card } from "src/reducers/payment";

export const StripeCard = ({
  card,
  isSelected,
  isDisabled,
  onSelect,
}: {
  card: Card;
  isDisabled?: boolean;
  isSelected?: boolean;
  onSelect?: () => void;
}) => {
  return (
    <Button
      type="link"
      disabled={isDisabled}
      className={classNames(styles.card, !onSelect && styles.readOnly, "width-100")}
      onClick={() => onSelect && onSelect()}>
      {onSelect && <Radio checked={isSelected} />}
      <div
        className="flex-column-container flex-1 align-start m-lxs"
        style={{ overflow: "hidden" }}>
        <h3>{card.brand}</h3>
        <span className="preheader">
          <span className="m-rxs uppercase">{card.name}</span>
          <span className="m-rxs ">**** **** **** {card.last4}</span>
          <span>
            {dayjs()
              /* moment months are zero indexed */
              .month(card.exp_month - 1)
              .year(card.exp_year)
              .format("MM/YY")}
          </span>
        </span>
      </div>
    </Button>
  );
};
