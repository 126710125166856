import { useEffect, useState } from "react";
import { getMyCards } from "src/action_managers/subscriptions";
import { useDispatchTS, useSelectorTS } from "./redux-ts";

export default function useGetPaymentCards() {
  const dispatch = useDispatchTS();
  const payments = useSelectorTS((state) => state.payments);
  const [isInitiallyLoading, setIsInitiallyLoading] = useState(true);

  useEffect(() => {
    const load = async () => {
      setIsInitiallyLoading(true);
      await dispatch(getMyCards());
      setIsInitiallyLoading(false);
    };
    load();
  }, []);

  return { ...payments, isLoading: isInitiallyLoading };
}
