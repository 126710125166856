import get from "lodash/get";
import maxBy from "lodash/maxBy";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import KnownAgentFriendlyNames from "../../../lib/known_agent_friendly";
import { humanizeNumber } from "../../../lib/numbers";
import Divider from "../divider";
import Spinner from "../spinner";
import AppGraph from "./app_graph";
import DeviceGraph from "./device_type_graph";
import DownloadsGraph from "./downloads_graph";
import GraphContainer from "./graph_container";
import KeyStatBox from "./key_stat_box";
import OSGraph from "./os_graph";
import "./stats_widget.scss";

class StatsWidgetContainer extends Component {
  state = {
    hasStats: false,
  };

  componentDidMount() {
    this._isMounted = true;
    this.configureFetchStats({})("initialize").then((response) => {
      if (Array.isArray(get(response, "json")) && response.json.some(({ count }) => count)) {
        this._isMounted && this.setState({ hasStats: true });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  configureFetchStats = (fetchConfig = {}) => {
    fetchConfig.isUnique = true;
    fetchConfig[this.props.uuidType] = this.props.uuid;
    if (!fetchConfig.timeRange) {
      fetchConfig.timeRange = "allTime";
    }
    return (requestID) => {
      return this.props.fetchStats(fetchConfig, this.props.user, requestID);
    };
  };

  bucketTermsCountMap = (stats) => {
    return stats.reduce((counts, current) => {
      var key = current.pathValues[1];
      counts[key] = counts[key] ? counts[key] + current.count : current.count;
      return counts;
    }, {});
  };

  getLargestKeySkippingUnknown = (countMap) => {
    var key = maxBy(
      Object.keys(countMap).filter((k) => k.toLowerCase() !== "unknown"),
      (key) => countMap[key]
    );
    return key;
  };

  getTotalFromStats = (stats) => {
    var result = Object.keys(stats).reduce((agg, current) => agg + stats[current].count, 0);
    return result;
  };

  getDownloadsStat = (stats) => {
    //figure out what we want to do regarding cutting by episode
    return this.getTotalFromStats(stats);
  };

  getRequestID = (category) => get(this.props, "uuid", "everything") + category;

  render() {
    if (!this.state.hasStats) {
      return null;
    }
    return (
      <div className="stats-widget">
        <Row>
          <Col xs={12}>
            <h1>Analytics</h1>
            <Divider className="stats-widget__divider" smallMargin />
          </Col>
        </Row>
        <div className="stats-widget-inner">
          <Spinner isLoading={this.props.isLoading} />
          {this.renderStats()}
        </div>
      </div>
    );
  }

  renderStats() {
    return [
      <Row key={"row1"}>
        <Col lg={3} md={6} xs={12}>
          <KeyStatBox
            title={"Total Downloads"}
            dataAccessor={(stats) => {
              return humanizeNumber(this.getDownloadsStat(stats));
            }}
            fetchStats={this.configureFetchStats({
              ...(this.props.uuid &&
                this.props.uuidType === "showUUID" && { showUUID: this.props.uuid }),
            })}
            requestID={this.getRequestID("total")}
            stats={this.props.stats}
          />
        </Col>
        <Col lg={3} md={6} xs={12}>
          <KeyStatBox
            title={"Top Platform"}
            dataAccessor={(stats) => {
              var allStats = this.bucketTermsCountMap(stats);
              return KnownAgentFriendlyNames.get("os", this.getLargestKeySkippingUnknown(allStats));
            }}
            fetchStats={this.configureFetchStats({
              bucketTerms: "download.metadata.os",
            })}
            requestID={this.getRequestID("os")}
            stats={this.props.stats}
          />
        </Col>
        <Col lg={3} md={6} xs={12}>
          <KeyStatBox
            title={"Top App"}
            dataAccessor={(stats) => {
              var allStats = this.bucketTermsCountMap(stats);
              return KnownAgentFriendlyNames.get(
                "app",
                this.getLargestKeySkippingUnknown(allStats)
              );
            }}
            fetchStats={this.configureFetchStats({
              bucketTerms: "download.metadata.app",
            })}
            requestID={this.getRequestID("app")}
            stats={this.props.stats}
          />
        </Col>
        <Col lg={3} md={6} xs={12}>
          <KeyStatBox
            title={"Device Type"}
            dataAccessor={(stats) => {
              var allStats = this.bucketTermsCountMap(stats);
              var topKey = this.getLargestKeySkippingUnknown(allStats);
              var topKeyFriendly = KnownAgentFriendlyNames.get("device", topKey);

              var total = this.getTotalFromStats(stats);
              return `${Math.round((allStats[topKey] / total) * 100)}% ${topKeyFriendly}`;
            }}
            fetchStats={this.configureFetchStats({
              bucketTerms: "download.metadata.device",
            })}
            requestID={this.getRequestID("device")}
            stats={this.props.stats}
          />
        </Col>
      </Row>,
      <Row className="stats-widget__graphs-wrapper" key={"row2"}>
        <Col xs={12} md={6}>
          <GraphContainer
            title={"Downloads"}
            titleClassName="stats-widget-graph-title--with-form-control"
            className="stats-widget-graph-container-with-height">
            <div className="stats-width-graph-height">
              <DownloadsGraph
                fetchStats={this.props.fetchStats}
                uuid={this.props.uuid}
                uuidType={this.props.uuidType}
                user={this.props.user}
                stats={this.props.stats}
              />
            </div>
          </GraphContainer>
        </Col>
        <Col xs={12} md={6}>
          <GraphContainer title={"Platforms"} className="stats-widget-graph-container-with-height">
            <div className="stats-width-graph-height">
              <OSGraph
                fetchStats={this.props.fetchStats}
                uuid={this.props.uuid}
                uuidType={this.props.uuidType}
                user={this.props.user}
                stats={this.props.stats}
              />
            </div>
          </GraphContainer>
        </Col>
        <Col xs={12} md={6}>
          <GraphContainer
            title={"Top Podcast Players"}
            className={"stats-widget-graph-container-with-height"}>
            <div className="stats-width-graph-height">
              <AppGraph
                fetchStats={this.props.fetchStats}
                uuid={this.props.uuid}
                uuidType={this.props.uuidType}
                user={this.props.user}
                stats={this.props.stats}
              />
            </div>
          </GraphContainer>
        </Col>
        <Col xs={12} md={6}>
          <GraphContainer
            title={"Device Types"}
            className="stats-widget-graph-container-with-height">
            <div className="stats-width-graph-height">
              <DeviceGraph
                fetchStats={this.props.fetchStats}
                uuid={this.props.uuid}
                uuidType={this.props.uuidType}
                user={this.props.user}
                stats={this.props.stats}
              />
            </div>
          </GraphContainer>
        </Col>
      </Row>,
    ];
  }
}

export default StatsWidgetContainer;
