import { Input } from "antd";
import { useEffect, useState } from "react";
import { Modal } from "redcircle-ui";
import { showWarning } from "src/actions/app";
import { getCampaign, updateCampaignItemWebhook } from "src/action_managers/campaigns";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import {
  getPixelURLValidationMessage,
  transformStringToWebhookURL,
  transformWebhookURLToString,
} from "src/lib/pixel_urls";
import AudioMacrosCollapse from "./audio_macros_collapse";
import { ReduxModalProps } from "../modal_root";
import { ICampaignItem } from "src/reducers/campaign_items";

export interface IPixelModal extends ReduxModalProps {
  campaignItem: ICampaignItem;
}

export default function PixelModal({ campaignItem, open, onClose, afterClose }: IPixelModal) {
  const [webhookURL, setWebhookURL] = useState("");
  const dispatch = useReduxDispatch();
  const { isLoading } = useSelectorTS((state) => state.campaigns);
  const campaignUUID = campaignItem?.campaignUUID;

  useEffect(() => {
    if (campaignUUID) {
      dispatch(getCampaign(campaignUUID)).then((res) => {
        if (res.status === 200) {
          const thisItem = res.json?.items?.find(
            (row: any) => row?.item?.uuid === campaignItem.uuid
          );
          if (thisItem) setWebhookURL(transformWebhookURLToString(thisItem.item.webhookURL));
        }
      });
    }
  }, [campaignUUID]);

  const handleSubmit = async () => {
    const url = transformStringToWebhookURL(webhookURL);
    const res = await dispatch(updateCampaignItemWebhook(campaignItem, url));
    if (res.status === 200) {
      onClose();
    } else {
      dispatch(showWarning("Invalid URL"));
    }
  };

  const validationMessage = getPixelURLValidationMessage(webhookURL);

  return (
    <Modal size="sm" open={open} onClose={onClose} afterClose={afterClose} onSubmit={handleSubmit}>
      <Modal.Title>Pixel Tracking</Modal.Title>
      <Modal.Body>
        <p>
          To set up third-party tracking for <strong>{campaignItem?.show?.title}</strong>, paste
          your tracking URL below and click save.
        </p>
        <p>
          To include multiple tracking URLs, separate each URL with a new line or <code>|||</code>.
        </p>
        <div className={"m-bxs"}>
          <Input.TextArea
            value={webhookURL}
            onChange={(e) => setWebhookURL(e.target.value)}
            placeholder={isLoading ? "Loading..." : "https://example.com"}
            rows={4}
            disabled={isLoading}
          />
          {validationMessage && <span className={"text-warning"}>{validationMessage}</span>}
        </div>
        <AudioMacrosCollapse />
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        <Modal.SubmitButton>Save</Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
}
