import { Spin } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";

export interface ILoading {
  size?: "small" | "default" | "large" | undefined;
  style?: React.CSSProperties;
  className?: string;
}

export default function Loading({ size = "default", style, className }: ILoading) {
  // for loading SSR
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  if (!isClient) return null;

  return (
    <div
      data-testid="test-loading"
      className={classNames("width-100 flex-row-container justify-center", className)}
      style={style}>
      <Spin size={size} />
    </div>
  );
}
