export type Budget = {
  uuid: string;
  name: string;
  userUUID: string;
  current: number; // current Budget in Millis cent i.e. (25_000_000 =>  $250.00) or (10_050_000 => $150.50)
  total: number; // total Budget in Millis cent i.e. (25_000_000 =>  $250.00) or (10_050_000 => $150.50)
  type: BudgetType;
  advertisementSettings: AdvertisementSetting;
  createAt: number;
  updatedAt: number;
};

export type BudgetType = "funds" | "insertions";

type AdvertisementSetting = {
  isHostReadEnabled: boolean;
  isPreRecordedEnabled: boolean;
  hostReadPreRollCPM: number;
  hostReadMidRollCPM: number;
  hostReadPostRollCPM: number;
  preRecordedPreRollCPM: number;
  preRecordedMidRollCPM: number;
  preRecordedPostRollCPM: number;
  hostNames: FullName[];
  hosts: { fullName: FullName; postalAddress: Address }[];

  twitterLink: string | null;
  facebookLink: string | null;
  instagramLink: string | null;
  youTubeLink: string | null;
  tikTokLink: string | null;

  /**
   * @deprecated The property is deprecated, no longer using discreet campaigns/spot rate
   */
  preRollSpotRate: number;
  /**
   * @deprecated The property is deprecated, no longer using discreet campaigns/spot rate
   */
  midRollSpotRate: number;
  /**
   * @deprecated The property is deprecated, no longer using discreet campaigns/spot rate
   */
  postRollSpotRate: number;

  automaticallyCalculateSpotRates: boolean;
};

type FullName = { firstName: string; lastName: string };

type Address = {
  country: string;
  name: string;
  organization: string;
  streetAddress: string[];
  dependentLocality: string;
  locality: string;
  administrativeArea: string;
  postCode: string;
  sortingCode: string;
};

/**
 * Type guard to check if an unknown value is a valid Budget object,
 * re enforces the Campaign type post check
 *
 * @param value
 * @returns Boolean
 */
export function isBudget(value?: unknown): value is Budget {
  const budgetTypeMap: Record<BudgetType, true> = {
    funds: true,
    insertions: true,
  };

  if (
    typeof value === "object" &&
    value !== null &&
    "uuid" in value &&
    "name" in value &&
    "userUUID" in value &&
    "current" in value &&
    "total" in value &&
    "type" in value &&
    typeof value.uuid === "string" &&
    typeof value.name === "string" &&
    typeof value.userUUID === "string" &&
    typeof value.type === "string" &&
    typeof value.total === "number"
  ) {
    return Object.keys(budgetTypeMap).includes(value.type);
  }

  return false;
}
