import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Button, Modal } from "redcircle-ui";
import { showError } from "src/actions/app";
import {
  createCampaignItems,
  getCampaign,
  removeCampaignItems,
} from "src/action_managers/campaigns";
import { fetchPublicShowsByUUIDs } from "src/action_managers/public_show";
import { permissionTypes } from "src/constants/permission_roles";
import { useGetCampaign } from "src/hooks/campaigns";
import { useReduxDispatch } from "src/hooks/redux-ts";
import { useGetCampaignRecommendedShows } from "src/hooks/search";
import { useCanAccess } from "src/lib/permissions";
import { CampaignItemStateDraft } from "../../../constants/campaigns";
import PodcastSelector from "./PodcastSelector";

interface IProps {
  closeModal: () => void;
  closeRoute: () => void;
  match: any;
  history: any;
}

export default function CampaignSelectPodcastModal({
  closeModal,
  closeRoute,
  match,
  history,
}: IProps) {
  const { params } = match;
  const { campaignUUID } = params;
  const dispatch = useReduxDispatch();
  const campaign = useGetCampaign(campaignUUID);
  const canAccess = useCanAccess(permissionTypes.editCampaign, campaign?.uuid);
  const { shows: recommendedShows } = useGetCampaignRecommendedShows(campaign);

  const disabledMessage = canAccess
    ? undefined
    : "You do not have permission to edit this campaign. Please contact your account administrator.";

  const disabledShowsByUUID =
    campaign?.items?.reduce((acc, i) => {
      if (i.item.state !== "draft") return { ...acc, [i.item.showUUID]: true };
      return { ...acc };
    }, {}) || {};

  const numDisabledShows = Object.keys(disabledShowsByUUID).length;

  const [selectedShowUUIDs, setSelectedShowUUIDs] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(getCampaign(campaignUUID));
  }, []);

  useEffect(() => {
    if (campaign) {
      const items = campaign?.items || [];
      const campaignShowUUIDs = items.map((i) => i.item.showUUID);
      setSelectedShowUUIDs(campaignShowUUIDs);
    }
  }, [campaign]);

  const handleClose = () => {
    closeModal();
    closeRoute();
  };

  const handleSelect = (selectedUUID: string) => {
    if (selectedShowUUIDs.includes(selectedUUID)) {
      setSelectedShowUUIDs(selectedShowUUIDs.filter((uuid) => uuid !== selectedUUID));
    } else {
      setSelectedShowUUIDs([...selectedShowUUIDs, selectedUUID]);
    }
  };

  const handleRemoveAll = () => {
    // resetting the modal should leave any non-draft items in place.
    const items = campaign?.items || [];
    setSelectedShowUUIDs(
      items.filter((i) => i.item.state != CampaignItemStateDraft).map((i) => i.item.showUUID)
    );
  };

  const getRemovedUUIDs = () => {
    const items = campaign?.items || [];
    const campaignShowUUIDs = items
      .filter((i) => i.item.state == CampaignItemStateDraft)
      .map((i) => i.item.showUUID);
    return campaignShowUUIDs.filter((uuid) => !selectedShowUUIDs.includes(uuid));
  };

  const getAddedUUIDs = () => {
    const items = campaign?.items || [];
    const campaignShowUUIDs = items.map((i) => i.item.showUUID);
    return selectedShowUUIDs.filter((uuid) => !campaignShowUUIDs.includes(uuid));
  };

  const handleSubmit = async () => {
    if (campaign) {
      setIsSubmitting(true);
      const removedUUIDs = getRemovedUUIDs();
      const addedUUIDs = getAddedUUIDs();

      try {
        let res;

        if (removedUUIDs.length > 0) {
          const removedCampaignItemUUIDs = removedUUIDs
            .map((uuid) => {
              const item = campaign.items?.find((i) => i.item.showUUID === uuid);
              return item?.item.uuid;
            })
            .filter((uuid) => uuid !== undefined);
          res = await dispatch(
            removeCampaignItems({
              campaign,
              campaignItemUUIDs: removedCampaignItemUUIDs as string[],
            })
          );
        }

        if (addedUUIDs.length > 0) {
          const toAdd = addedUUIDs.map((uuid) => ({ showUUID: uuid }));
          res = await dispatch(createCampaignItems(campaignUUID, toAdd));
        }

        if (res && res.status === 200) {
          dispatch(getCampaign(campaignUUID));
          dispatch(fetchPublicShowsByUUIDs(addedUUIDs));
          history.push(`/campaigns/${campaignUUID}`);
        }
      } catch (e: any) {
        dispatch(showError(e.message));
      }

      setIsSubmitting(false);
    }
  };

  return (
    <Modal onClose={handleClose} open size="md" maskClosable={false}>
      <Modal.TitleSection>
        <h2>Select Podcasts</h2>
        <span>
          We have hundreds of podcasts to choose from. You can filter or search to help narrow your
          selection.
        </span>
      </Modal.TitleSection>

      <Modal.Body className="p-b0">
        <PodcastSelector
          selectedShowUUIDs={selectedShowUUIDs}
          disabledShowUUIDs={disabledShowsByUUID}
          onSelect={handleSelect}
          recommendedShows={recommendedShows}
          campaign={campaign}
        />
      </Modal.Body>

      <Modal.Footer>
        <Modal.CloseButton onClick={handleClose}>Cancel</Modal.CloseButton>
        <div className="flex-row-container align-center">
          <div className="flex-row-container align-center m-rs">
            <strong className="color-primary m-rxxxs">
              {selectedShowUUIDs.length - numDisabledShows} selected{" "}
            </strong>
            {selectedShowUUIDs.length - numDisabledShows > 0 && (
              <Button type="link" className="p-a0" onClick={handleRemoveAll}>
                (clear)
              </Button>
            )}
          </div>
          <Tooltip title={!canAccess || !campaign ? disabledMessage : ""}>
            <Button
              type="primary"
              size="large"
              loading={isSubmitting}
              onClick={handleSubmit}
              disabled={!canAccess || !campaign}>
              Save
            </Button>
          </Tooltip>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
